<template>
  <cso-error-handler>
    <cso-initializer>
      <cso-marketing />
      <cso-meta />
      <div class="cso-app-wrapper">
        <img class="logo-print"
            alt="Vattenfall"
            loading="lazy"
            src="/static/images/logo.svg" />
        <cso-header :key="'header-' + viewIndex" />
        <div v-if="diOverriddenDate"
              class="cso-message-box">
          {{ $t('general.diOverriddenDate') }}
        </div>
        <div v-if="CsoRouter.isOpenArea === true"
              class="cso-app-container">
          <router-view name="openarea"
                        :key="'openView-' + viewIndex" />
        </div>
        <div v-if="CsoRouter.isOpenArea === false"
              class="cso-app-container">
          <div class="page-wrapper">
            <div>
              <div class="container-app no-padding-mobile">
                <div class="intro-container"
                    :class="{'hidden': !showContractSelector && (!userName || !showUsername)}">
                  <div v-if="userName && showUsername" class="user-name-container" >
                    <p class="paragraph-logged">{{ $t('general.loggedInAs')}}:</p>
                    <p class="paragraph-user-name" >{{userName}}</p>
                  </div>
                  <div v-show="showContractSelector" class="contract-selector-container">
                            <cso-contract-selector :disableContractSelector="disableContractSelector"
                                      ref="contractSelector"
                                      @loaded="contractLoaded"
                                      @rerender="forceRerender"
                                      @failedLoadingContracts="failedLoadingContracts"
                                      @loadedContracts="loadedContracts"></cso-contract-selector>
                  </div>
                </div>
                <cso-banner :title="bannerTitle"
                            :salutation="bannerSalutation"
                            :button="bannerButton"
                            v-if="showBanner">
                </cso-banner>
              </div>
              <div class="cso-page">
                <cso-component-state :componentState="state">
                  <template v-slot:ready>
                    <cso-navigation :key="viewIndexNav"
                                    v-if="showNavigation">
                    </cso-navigation>
                  </template>
                </cso-component-state>
                <div class="container-app">
                  <cso-component-state :componentState="state">
                    <template v-slot:ready>
                      <router-view @showContractSelector="updateShowContractSelector"
                                    @showNavigation="updateShowNavigation"
                                    @showUsername="updateShowUsername"
                                    @reloadContractSelector="reloadContractSelector"
                                    @reloadNavigation="reloadNavigation"
                                    @hideBanner="hideBanner"
                                    @disableContractSelector="updateDisableContractSelector"
                                    @banner="updateBanner"
                                    @rerender="forceRerender"
                                    :key="'closedView-' + viewIndex"
                                    :contractSelectorOptions="contractSelectorOptions"></router-view>
                    </template>
                  </cso-component-state>
                  <section class="cso-page-structure">
                    <cso-go-to-top />
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <cso-footer />
      </div>
    </cso-initializer>
  </cso-error-handler>
</template>

<script lang="ts">
import di from '@/api/digital-interface'
import CsoRouter from '@/api/CsoRouter'
import LoggingService from '@/common/LoggingService'
import ComponentsStates from '@/common/ComponentsStates'
import store from '@/store'
import CsoBanner from '@/components/core/business/CsoBanner.vue'
import CsoMarketing from '@/components/core/business/CsoMarketing.vue'
import CsoContractSelector from '@/components/core/business/CsoContractSelector.vue'
import CsoHeader from '@/components/core/composition/CsoHeader.vue'
import CsoFooter from '@/components/core/composition/CsoFooter.vue'
import CsoComponentState from '@/components/core/composition/CsoComponentState.vue'
import CsoGoToTop from '@/components/core/composition/CsoGoToTop.vue'
import CsoErrorHandler from '@/components/core/technical/CsoErrorHandler.vue'
import CsoInitializer from '@/components/core/technical/CsoInitializer.vue'
import CsoNavigation from '@/components/core/composition/CsoNavigation.vue'
import CsoMeta from '@/components/core/technical/CsoMeta.vue'
import type { Subscription } from 'rxjs'

let subscription: Subscription

export default {
  name: 'CsoApp',

  components: {
    CsoErrorHandler,
    CsoInitializer,
    CsoMarketing,
    CsoMeta,
    CsoHeader,
    CsoFooter,
    CsoBanner,
    CsoContractSelector,
    CsoGoToTop,
    CsoComponentState,
    CsoNavigation
  },

  data () {
    return {
      router: this.$router,
      state: ComponentsStates.loading,
      contractSelectorOptions: {},
      disableContractSelector: false,
      viewIndex: 0,
      viewIndexNav: 0,
      bannerTitle: '',
      bannerSalutation: '',
      bannerButton: undefined,
      showContractSelector: true,
      showNavigation: true,
      showUsername: true,
      showBanner: false,
      preventViewRerender: false,
      CsoRouter,
      userName: ''
    }
  },

  computed: {
    diOverriddenDate () {
      return !!di.data.stateSince
    }
  },

  watch: {
    $route (to, from) {
      LoggingService.log(LoggingService.eventTypes.navigation, 'from: ' + from.name + ', to: ' + to.name)
      this.resetSettings()
      this.forceRerender()
    }
  },

  methods: {
    forceRerender () {
      if (this.preventViewRerender === false) {
        this.viewIndex += 1
      }
      this.viewIndexNav += 1
    },

    contractLoaded () {
      LoggingService.log(LoggingService.eventTypes.app, 'Contract loaded')
      this.$emit('loaded')
    },

    async reloadContractSelector () {
      LoggingService.log(LoggingService.eventTypes.app, 'Reloading contract selector')
      this.preventViewRerender = true
      await (this.$refs.contractSelector as any).reload()
      this.preventViewRerender = false
    },

    async reloadNavigation () {
      LoggingService.log(LoggingService.eventTypes.app, 'Reloading navigation')
      this.viewIndexNav += 1
    },

    resetSettings () {
      this.bannerTitle = ''
      this.bannerSalutation = ''
      this.bannerButton = undefined
      this.disableContractSelector = false
      this.showContractSelector = true
      this.showNavigation = true
      this.showUsername = true
    },

    updateDisableContractSelector (disableContractSelector) {
      this.disableContractSelector = disableContractSelector
    },

    updateShowContractSelector (showContractSelector) {
      this.showContractSelector = showContractSelector
    },

    updateShowNavigation (showNavigation) {
      this.showNavigation = showNavigation
    },

    updateShowUsername (showUsername) {
      this.showUsername = showUsername
    },

    hideBanner () {
      this.showBanner = false
    },

    updateBanner (banner) {
      this.showBanner = Object.entries(banner).length > 0
      if (this.showBanner) {
        this.bannerTitle = banner.title || ''
        this.bannerButton = banner.button || undefined
      }
    },

    loadedContracts (contractSelectorOptions) {
      LoggingService.log(LoggingService.eventTypes.app, 'Loaded contracts')
      this.state = ComponentsStates.ready
      this.contractSelectorOptions = contractSelectorOptions
      this.forceRerender()
    },

    failedLoadingContracts () {
      LoggingService.log(LoggingService.eventTypes.app, 'Failed loading contracts')
      this.state = ComponentsStates.error
    },
    async observe (state) {
      if (state.account && state.account.Vorname && state.account.Nachname) {
        this.userName = `${state.account.Vorname} ${state.account.Nachname}`
      }
    }
  },

  created () {
    subscription = store.subscribe(this.observe)
  },
  unmounted () {
    if (subscription) {
      subscription.unsubscribe()
    }
  }
}
</script>

<style lang="scss">

.cso-app-wrapper {

  .cso-message-box {
    text-align: center;
    margin: 12px;
  }

.container-app {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1320px;

  @include xxl {
    padding-left: 290px;
    padding-right: 30px;
    max-width: 1600px;
  }

  @include lg {
    padding-left: 75px;
    margin-right: auto;
    margin-left: auto;
  }

  @include sm {
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.intro-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 36px;

  @include lg {
    flex-direction: row;
    align-items: center;
  }

  @include sm {
    flex-direction: column;
    align-items: stretch;
  }

  p.paragraph-user-name {
    font-size: 14px;
    font-family: $font-family-bold;
    margin-bottom: 0;

  }

  p.paragraph-logged {
    font-size: 12px;
    color: $gray-dark;
    margin-bottom: 4px;

    @include sm {
      color: $black;
      font-size: 14px;
    }
  }

  .user-name-container {
    flex: 2;
    font-family: $font-family-regular;

    @include md {
      align-self: stretch;
      flex: 1;
    }

    @include sm {
      display: flex;
      padding-left: 1.5rem;
      gap: .5rem;
    }
  }

  .contract-selector-container {

    flex: 7;

    @media only screen and (max-width: 1150px) {
      flex: 9.5;
    }

    @include md {
      flex: 6;
    }

  }
}
}
</style>
