<template>
  <aside />
</template>

<script lang="ts">
import di from '@/api/digital-interface'
import * as DI from '@/api/digital-interface/client'

export default {
  name: 'CsoMeta',

  data () {
    return {
      inhalt: {} as DI.V13.Inhalt
    }
  },

  async created () {
    await this.getInhalt()
    this.$router.afterEach(() => this.updateMeta())
  },

  methods: {
    updateMeta () {
      const defaultMeta = {
        title: 'Mein Vattenfall - Online Service',
        description: 'Verwalten Sie Ihre Verträge online',
        keywords: 'Vattenfall Kundenservice, Vattenfall Online Service'
      }
      const route = this.$route.meta as any
      const meta =  this.inhalt?.getMetaTags(route.seoKey) || this.inhalt?.getMetaTags('DEFAULT') || defaultMeta
      this.updateTitle(meta.title)
      this.updateMetaTag('description', meta.description)
      this.updateMetaTag('keywords', meta.keywords)
      this.updateMetaTag('robots', route.robotsFollow ? 'index, follow' : '')
    },
    updateTitle (value: string) {
      const title = document.createElement('title')
      title.append(document.createTextNode(value))
      document.head.querySelector('title')?.remove()
      document.head.append(title)
    },
    updateMetaTag (name: string, content: string) {
      const metaTag = document.createElement('meta')
      metaTag.name = name
      metaTag.content = content
      document.head.querySelector(`meta[name=${name}]`)?.remove()
      document.head.append(metaTag)
    },
    async getInhalt () {
      this.inhalt = await di.v13.getInhalt()
    }
  }
}
</script>
