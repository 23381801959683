import Request from '../Request'
import Response from '../Response'

/**
 * Http error.
 */
export default class ErrorHttp extends Error {
  /**
   * Request that threw this error.
   */
  public request: Request

  /**
   * Response that threw this error if any.
   */
  public response: Response | undefined

  /**
   * Instantiates a new http error.
   */
  public constructor (message: string, request: Request, response?: Response) {
    super(`Digital Interface HTTP error: ${message}`)
    this.request = request
    this.response = response
  }

  /**
   * Http status code of the response if any.
   */
  public get status (): number | undefined {
    return this.response && this.response.status
  }
}
