import Context from '../Context'
import type Interceptor from '../Interceptor'
import { delay } from '../../utility'

/**
 * Delay interceptor.
 *
 * Handles request delay.
 */
export default class Delay implements Interceptor {
  /**
   * @inheritdoc
   */
  public async intercept (context: Context): Promise<void> {
    await this.delay(context)
    await context.proceed()
  }

  /**
   * Delays a request.
   */
  protected async delay (context: Context): Promise<void> {
    const time = context.request.delayTime
    await delay(time)
  }
}
