<template>
  <div class="full-height">
    <cso-box v-if="error"
             class="cso-error-handler">
      <p>{{ $t('general.errors.unexpected') }}</p>
      <cso-link :link="StaticUrls.vtflMain"
                modifier="custom"
                :label="$t('general.back')"
                :is-button="true">
      </cso-link>
    </cso-box>
    <slot v-else />
  </div>
</template>

<script>
import ai from '@/api/application-insights'
import LoggingService from '@/common/LoggingService'
import StaticUrls from '@/common/StaticUrls'
import Session from '@/common/Session'
import { getCurrentInstance } from 'vue'

export default {
  name: 'CsoErrorHandler',

  data () {
    return {
      StaticUrls,
      handleAsynchronousErrorListener: this.handleAsynchronousError.bind(this),
      error: false
    }
  },

  created () {
    this.register()
  },

  unmounted () {
    this.unregister()
  },

  methods: {
    register () {
      const app = getCurrentInstance().appContext
      app.config.errorHandler = this.handleVueError.bind(this)
      window.onerror = this.handleGlobalError.bind(this)
      addEventListener('unhandledrejection', this.handleAsynchronousErrorListener)
    },
    unregister () {
      const app = getCurrentInstance().appContext
      app.config.errorHandler = undefined
      window.onerror = undefined
      removeEventListener('unhandledrejection', this.handleAsynchronousErrorListener)
    },
    handleVueError (error, vm, info) {
      LoggingService.error(LoggingService.eventTypes.app, 'Error inside Vue component:', { error, vm, info })
      ai.trackError(error)
      Session.stop()
      this.error = true
    },
    handleGlobalError (message, url, line, column, error) {
      if (message === 'Script Error.' || // some external CORS issue - https://stackoverflow.com/questions/5913978/cryptic-script-error-reported-in-javascript-in-chrome-and-firefox
        message === 'not_specified' || // duplication of error handling from require.js from old repo
        message.includes('Object Not Found Matching Id:')) { // outlook crawler scanning page - https://github.com/getsentry/sentry-javascript/issues/3440 and https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/20
        return
      }
      LoggingService.error(LoggingService.eventTypes.app, 'General JavaScript error:', { message, url, line, column, error })
      if (!error) {
        message = '[KSO] ' + message || 'Unknown JavaScript error'
        if (url) {
          message += ' \nURL: ' + url
        }
        if (line) {
          message += ' \nLINE: ' + line
        }
        error = new Error(message)
      }
      ai.trackError(error)
    },
    handleAsynchronousError (event) {
      LoggingService.error(LoggingService.eventTypes.app, 'Unhandled promise rejection:', event)
      ai.trackPromiseRejection(event)
      Session.stop()
      this.error = true
    }
  }
}
</script>

<style lang="scss">
.cso-error-handler {
  text-align: center;
}
</style>
