import LoggingService from '@/common/LoggingService'
import * as DI from '@/api/digital-interface/client'

export default new DI.DigitalInterface({
  subscriptionKey: '',
  client: 'KSO',
  principal: 'VESALES',
  language: 'DE',
  host: '',
  stateSince: ''
}, LoggingService)
