import * as DI from '@/api/digital-interface/client'
import Session from '@/common/Session'
import Utils from '@/common/Utils'

/**
 * Session lost interceptor.
 *
 * Handles session lost.
 */
export default class SessionLost implements DI.Interceptor {
  /**
   * Creates a new session lost interceptor.
   */
  public constructor (di: DI.DigitalInterface) {
    this.di = di
  }

  /**
   * @inheritdoc
   */
  public async intercept (context: DI.InterceptorContext): Promise<void> {
    try {
      await context.proceed()
    } catch (error) {
      this.handleError(error as Error)
    }
  }

  /**
   * Handles error.
   */
  protected handleError (error: Error) {
    if (this.isSessionLost(error)) {
      Session.stop()
      if (this.di.token?.isAnonymous()) {
        window.location.reload()
      } else {
        Utils.redirectToSessionLostLoginPage()
      }
    }
    throw error
  }

  /**
   * Returns whether session has been lost. Can be many reasons:
   * - token expired,
   * - session broken (missing session data).
   */
  protected isSessionLost (error: Error) {
    if (error instanceof DI.ErrorTokenExpired) {
      return true
    }
    if (
      error instanceof DI.ErrorResponse &&
      error.response.request.endpoint === '/Inhalte/GetKundendokumente' &&
      error.response.body.StatusCode === 'CLIENT_ERROR' &&
      !(error.response.request.body.Request as any).Werte.VK
    ) {
      return true
    }
    return false
  }

  /**
   * Digital interface client.
   */
  protected di: DI.DigitalInterface
}
