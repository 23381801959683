import * as DI from '@/api/digital-interface/client'
import store from '@/store'

/**
 * Maintanance interceptor.
 *
 * Handles Maintanance responses from DI
 */
export default class Maintanance implements DI.Interceptor {
  /**
   * @inheritdoc
   */
  public async intercept (context: DI.InterceptorContext): Promise<void> {
    await context.proceed()

    if (context.response?.body?.StatusCode?.valueOf() === 'IN_MAINTENANCE') {
      const message = context.response.body.Meldungen.length > 0 && context.response.body.Meldungen[0].FachlicheBeschreibung
        ? context.response.body.Meldungen[0].FachlicheBeschreibung
        : ''
      store.setMaintanceMode({
        enabled: true,
        message: message
      })
    }
  }
}
