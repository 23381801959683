import type Interceptor from './Interceptor'
import Request from '../Request'
import Response from '../Response'

/**
 * Execution context.
 */
export default class Context {
  /**
   * Intercepted request.
   */
  public request: Request

  /**
   * Intercepted response.
   */
  public response: Response

  /**
   * Instantiates a new execution context.
   */
  public constructor (request: Request, interceptors: Interceptor[]) {
    this.request = request
    this.response = this.createInitialResponse()
    this.interceptors = interceptors
    this.current = interceptors.length
  }

  /**
   * Continues the flow of execution.
   */
  public async proceed (): Promise<void> {
    this.current--
    try {
      await this.interceptor.intercept(this)
    } catch (error) {
      throw error
    } finally {
      this.current++
    }
  }

  /**
   * Creates an initial (empty) response to
   * be later populated during execution.
   */
  protected createInitialResponse (): Response {
    return new Response(0, {}, undefined as any, this.request)
  }

  /**
   * Current interceptor.
   */
  protected get interceptor (): Interceptor {
    return this.interceptors[this.current]
  }

  /**
   * List of interceptors executed from end to begin.
   */
  protected interceptors: Interceptor[]

  /**
   * Current interceptor index.
   */
  protected current: number
}
