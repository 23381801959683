import * as DI from '@/api/digital-interface/client'
import Utils from '@/common/Utils'
import Session from '@/common/Session'

/**
 * Api blocked interceptor.
 *
 * Handles api blocked.
 */
export default class IpBlocked implements DI.Interceptor {
  /**
   * @inheritdoc
   */
  public async intercept (context: DI.InterceptorContext): Promise<void> {
    try {
      await context.proceed()
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Handles error.
   */
  protected handleError (error: unknown) {
    if (error instanceof DI.ErrorHttp && error.response!.status === 403) {
      Session.stop()
      Utils.redirectToIpBlockedLoginPage()
    }
    throw error
  }
}
