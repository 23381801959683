import Config from './Config'
import Token from './authentication/Token'
import Storage from './storage/Storage'
import StorageLocal from './storage/StorageLocal'
import { createUuid } from './utility'
import { type Language } from './core/model-shared'

/**
 * Data source.
 *
 * Contains variables and parameters shared across client modules.
 */
export default class Data {
  /**
   * Storage key for token.
   */
  public static readonly TOKEN_STORAGE_KEY = 'token'

  /**
   * Storage key for parent log id.
   */
  public static readonly PARENT_LOG_ID_STORAGE_KEY = 'parent_log_id'

  /**
   * Query key for reference id.
   */
  public static readonly REFERENCE_ID_QUERY_KEY = 'idNachricht'

  /**
   * Storage for anonymous token.
   */

  static currentAnonToken: any

  /**
   * Creates data from initial client configuration.
   */
  public static createFromConfig (config: Config): Data {
    const stateSince = config.stateSince ? new Date(config.stateSince) : undefined
    return new Data(
      config.subscriptionKey,
      config.client,
      config.principal,
      config.language,
      config.host,
      stateSince
    )
  }

  /**
   * Creates random transaction id.
   */
  public static createTransactionId (): string {
    return createUuid()
  }

  /**
   * Creates random parent log id.
   */
  public static createParentLogId (): string {
    return createUuid()
  }

  /**
   * Default subscription key used for request.
   */
  public subscriptionKey: string

  /**
   * Default client/tenant/system used for request.
   */
  public client: string

  /**
   * Default principal used for request.
   */
  public principal: string

  /**
   * Default user language used for request.
   */
  public language: Language

  /**
   * Default host used for request.
   */
  public host: string

  /**
   * Default reference id used for request.
   */
  public referenceId?: string

  /**
   * Default state since date to be used for request.
   */
  public stateSince?: Date

  /**
   * Instantiates a new data source.
   */
  public constructor (subscriptionKey: string, client: string, principal: string, language: Language, host: string, stateSince?: Date) {
    this.subscriptionKey = subscriptionKey
    this.client = client
    this.principal = principal
    this.language = language
    this.host = host
    this.stateSince = stateSince
    this.storage = new StorageLocal()
    this.initializeParentLogId()
  }

  /**
   * Default token used for request.
   */
  public get token (): Token | undefined {
    const token = this.storage.get<Token>(Data.TOKEN_STORAGE_KEY)
    return token ? Token.create(token) : undefined
  }

  public set token (token: Token | undefined) {
    this.storage.set(Data.TOKEN_STORAGE_KEY, token)
  }

  /**
   * Default parent log id used for request.
   */
  public get parentLogId (): string | undefined {
    return this.storage.get(Data.PARENT_LOG_ID_STORAGE_KEY)
  }

  public set parentLogId (parentLogId: string | undefined) {
    this.storage.set(Data.PARENT_LOG_ID_STORAGE_KEY, parentLogId)
  }

  /**
   * Default presenter used for request.
   */
  public get presenter (): string {
    return `${this.client}_PORTAL`.toUpperCase()
  }

  /**
   * Default username used for request.
   */
  public get username (): string {
    return `de.vattenfall.${this.client}`.toLowerCase()
  }

  /**
   * Manually reset parent log id.
   */
  public resetParentLogId (): void {
    this.parentLogId = Data.createParentLogId()
  }

  /**
   * Initializes parent log id.
   */
  protected initializeParentLogId (): void {
    this.parentLogId = this.parentLogId || Data.createParentLogId()
  }

  /**
   * Storage used for permanent values.
   */
  protected storage: Storage
}
