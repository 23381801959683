import Response from '../Response'
import type Strategy from './Strategy'
import { type StrategyType } from './StrategyType'

/**
 * Status code handler.
 */
export default class Handler {
  /**
  * Instantiates a new status code handler.
  */
  public constructor (strategies: Strategy[]) {
    this.strategies = strategies
  }

  /**
   * Handles response status code with correct strategy.
   */
  public async handleStatusCode (response: Response): Promise<Response> {
    const strategy = this.matchStrategy(response)
    return strategy.handle(response)
  }

  /**
   * Matches strategy to response.
   */
  protected matchStrategy (response: Response): Strategy {
    const type = this.matchStrategyType(response)
    return this.getStrategy(type)
  }

  /**
   * Matches strategy type to response.
   */
  protected matchStrategyType (response: Response): StrategyType {
    if (response.body.StatusCode === 'ACCESS_DENIED' && response.getMessage('ERROR_TOKEN_EXPIRED')) {
      return 'REFRESH'
    }
    const statusCode = response.body.StatusCode
    const statusCodeMap = response.request.statusCodeMap!
    const type = statusCodeMap[statusCode]
    if (!type) {
      throw new Error(`No strategy for status code "${statusCode}"`)
    }
    return type
  }

  /**
   * Returns strategy of given type.
   */
  protected getStrategy (type: StrategyType): Strategy {
    const strategy = this.strategies.find(strategy => strategy.type === type)
    if (!strategy) {
      throw new Error(`No strategy for type "${type}"`)
    }
    return strategy
  }

  /**
   * List of strategies.
   */
  protected strategies: Strategy[]
}
