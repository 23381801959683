<template>
 <div class="cso-mobile-navigation">
   <ul class="menu">
     <div v-if="isUserOrDemo" class="mobile-nav-auth">
      <div class="logo--wrapper">
        <div class="logo--main-vattenfall"></div>
        <span class="logo--title">{{ $t('general.meinVattenfall') }}</span>
      </div>
       <li v-for="(item, index) in items"
              :key="index"
              class="item-auth"
              @click="toggleMenu()">
         <cso-link :link="item.url"
                    class="link-auth"
                    :label="$t('navigation.labels.' + item.title)">
         </cso-link>
        </li>
     </div>
     <div class="mobile-nav-noauth">
      <li class="item-noauth">
        <a :href="StaticUrls.vtflContact"
              class="icon-header-wrapper link-noauth">
              <i class="icon-header help"/>
              <span class="span-noauth">{{ $t('general.help') }}</span>
          </a>
        </li>
        <li class="item-noauth">
          <a :href="StaticUrls.vtflMain"
              class="icon-header-wrapper link-noauth">
              <i class="icon-header house"/>
              <span class="span-noauth">{{ $t('general.vattenfallHomePage') }}</span>
           </a>
        </li>
        <li class="item-noauth">
          <a @click.prevent="logout()"
            class="icon-header-wrapper link-noauth"
            :href="StaticUrls.logout">
            <i class="icon-header door-exit"/>
            <span class="span-noauth">{{ $t('general.logout') }}</span>
          </a>
        </li>
     </div>
    </ul>
  </div>
</template>

<script lang="ts">
import { Subscription } from 'rxjs'

import store from '@/store'

import di from '@/api/digital-interface'
import CsoRouter from '@/api/CsoRouter'
import StaticUrls from '@/common/StaticUrls'
import LoggingService from '@/common/LoggingService'

import { type Type, type Mobileitem, mobileItems, menuEmpty, menuNoContract, menuNoncomm, menuDefault } from './Model'

let subscription: Subscription

function filterItems (titles: string[]) {
  return mobileItems.filter(mobileItem => titles.includes(mobileItem.title))
}

export default {
  name: 'CsoMobileNavigation',

  data () {
    return {
      StaticUrls,
      expanded: false,
      type: '' as Type
    }
  },

  computed: {
    isUserOrDemo (): boolean {
      return !!di.token?.isUserOrDemo()
    },
    isClosedArea (): boolean {
      return !CsoRouter.isOpenArea
    },
    isSimple (): boolean {
      return this.$route.meta.simpleHeaderAndFooter as boolean
    },
    items (): Mobileitem[] {
      if (this.type === 'EMPTY') {
        return filterItems(menuEmpty)
      } else if (this.type === 'NO_CONTRACT') {
        return filterItems(menuNoContract)
      } else if (this.type === 'NONCOMM') {
        return filterItems(menuNoncomm)
      } else if (this.type === 'DEFAULT') {
        return filterItems(menuDefault)
      } else {
        throw new Error(`Unhandled menu type '${this.type}'`)
      }
    }
  },
  async created () {
    subscription = store.subscribe(() => this.observeStore())
  },

  unmounted () {
    subscription.unsubscribe()
  },

  methods: {
    observeStore () {
      this.setType()
    },
    toggleMenu () {
      LoggingService.log(LoggingService.eventTypes.userEvent, 'Toggle hamburger menu')
      this.$emit('toggle', !this.expanded)
    },
    login () {
      LoggingService.log(LoggingService.eventTypes.userEvent, 'Click login link')
      if (this.$route.name !== 'login') {
        this.$router.push({ name: 'login' })
      }
    },
    logout () {
      LoggingService.log(LoggingService.eventTypes.userEvent, 'Click logout link')
      this.$router.push({ name: 'logout' })
    },
    setType () {
      if (!store.hasGP) {
        this.type = 'EMPTY'
      } else if (!store.hasVK || !store.VK) {
        this.type = 'NO_CONTRACT'
      } else if (store.VK.Sparte.Code === 'NONCOMM') {
        this.type = 'NONCOMM'
      } else {
        this.type = 'DEFAULT'
      }
    }
  }
}
</script>

<style lang="scss">
.cso-mobile-navigation {
  color: $black;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    display: block;
    padding-bottom: 2px;
  }

  a.link.link-auth:hover,
  a.icon-header-wrapper.link-noauth:hover {
    text-decoration: none;
  }

  li.item-auth {
    height: 30px;

    a {
      &:hover {
        border-bottom: 4px solid $blue;
      }

      &.active {
        border-bottom: 4px solid $yellow;
      }
    }
  }

  li.item-noauth {
    height: 27px;

    a {
      &:hover {
        border-bottom: 2px solid $blue;
      }

      &.active {
        border-bottom: 2px solid $yellow;
      }
    }
  }

  .menu {
    padding: 0 38px;
  }

  .mobile-nav-auth {

    li {
      margin-bottom: 18px;
      width: fit-content;
    }
  }

  .mobile-nav-noauth {
    margin-top: 36px;

    li {
      margin-bottom: 17px;
      height: 30px;
      width: fit-content;
    }
  }

  .link-auth span {
     font-size: 24px;
     font-family: $font-family-bold;
     display: block;
  }

  .link-noauth span {
    font-size: 18px;
    font-family: $font-family-regular;
  }

  .icon-header-wrapper {
    display: flex;

    .icon-header {
      display: inline-block;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    .door-exit {
      background-image: url("/static/images/door_exit.svg");
    }

    .house {
      background-image: url("/static/images/house.svg");
    }

    .help {
      background-image: url("/static/images/help.svg");
    }
  }
  .logo {
    display: none;

    &--wrapper {
      display: flex;
      flex-wrap: nowrap;
      gap: 12px;
      align-items: center;
      height: 89px;
      padding-bottom: 15px;
    }

    &--main-vattenfall {
      background-image: url("/static/images/user.svg");
      background-position: center;
      background-size: 24px;
      background-repeat: no-repeat;
      background-color: #FFDA00;
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }

    &--title {
      font-family: $font-family-regular;
      font-size: 18px;
      color: $black;
    }
  }
}
</style>
