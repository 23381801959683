<template>
  <cso-page-wrapper class="ip-blocked-wrapper"
                    :show-banner="false"
                    :show-contract-selector="false"
                    :show-go-to-top-button="false"
                    :show-navigation="false">
    <div class="ip-blocked-container">
      <cso-box class="ip-blocked-box">
        <h1>{{ $t('general.ipBlocked') }}</h1>
        <div>
          <cso-link :link="StaticUrls.vtflMain"
                    modifier="primary"
                    :label="$t('general.toTheHomepage')"
                    :is-button="true" />
        </div>
      </cso-box>
    </div>
  </cso-page-wrapper>
</template>
<script>
import StaticUrls from '@/common/StaticUrls'

import CsoPageWrapper from '@/components/core/technical/CsoPageWrapper.vue'

export default {
  name: 'CsoIpBlockedPage',
  components: {
    CsoPageWrapper
  },
  data () {
    return {
      StaticUrls
    }
  }
}
</script>
<style lang="scss">
.ip-blocked-wrapper {
  .page-wrapper {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    min-height: fit-content;
  }
  .ip-blocked-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 606px);
    .cso-box.ip-blocked-box {
      display: flex;
      flex-direction: column;

      h1 {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
