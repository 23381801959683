import { v4 as uuidv4 } from 'uuid'
import { type DateString } from '@/api/digital-interface/client-generator/model-shared'
import { format } from 'date-fns'
import UAParser from 'ua-parser-js'

/**
 * A string-to-string dictionary.
 */
export interface Dictionary extends Record<string, string> { }

/**
 * Returns whether value is an array.
 */
export function isArray (value: any): value is unknown[] {
  return Object.prototype.toString.call(value) === '[object Array]'
}

/**
 * Returns whether value is an object.
 */
export function isObject (value: any): value is object {
  return Object.prototype.toString.call(value) === '[object Object]'
}

/**
 * Returns whether value is a string.
 */
export function isString (value: any): value is string {
  return Object.prototype.toString.call(value) === '[object String]'
}

/**
 * Inverts object's values with keys.
 */
export function invert (object: Dictionary): Dictionary {
  const inverted: Dictionary = {}
  for (const [key, value] of Object.entries(object)) {
    inverted[value] = key
  }
  return inverted
}

/**
 * Returns text with first letter in lower case.
 */
export function lowerCaseFirst (text: string): string {
  return text.substring(0, 1).toLowerCase() + text.substring(1)
}

/**
 * Delays execution of a script for given time in seconds.
 */
export async function delay (time: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, time * 1000))
}

/**
 * Generates a universally unique identifier in upper case.
 */
export function createUuid (): string {
  return uuidv4().toUpperCase()
}

/**
 * Converts base 64 encoded string to file.
 */
export async function convertBase64ToFile (name: string, content64: string, mime: string): Promise<File> {
  const contentUrl = `data:${mime};base64,${content64}`
  const contentBuffer = await fetch(contentUrl).then(content => content.arrayBuffer())
  return new File([contentBuffer], name, { type: mime })
}

/**
 * Converts file to base 64 encoded string.
 */
export async function convertFileToBase64 (file: File): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = () => resolve((reader.result as string).split(',')[1])
    reader.readAsDataURL(file)
  })
}

/**
 * Compiles date to format YYYY-MM-DD in Germany timezone.
 */
export function compileDate (date: Date | null): DateString {
  return date ? format(date, 'yyyy-MM-dd') : ''
}

/**
 * Gets user agent information.
 */
export function getUserAgent () {
  const { browser, os, device, ua } = UAParser()
  return {
    browser: `${browser.name} ${browser.version}`,
    os: `${os.name} ${os.version}`,
    device: (device.type) ? `${device.type} ${device.vendor} ${device.model} ` : 'Desktop',
    userAgent: ua
  }
}
