<template>
  <div :class="[...sizeClasses, customClass]">
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'CsoGridColumn',
  props: {
    customClass: {
      type: String,
      required: false
    },
    xs: {
      type: [String, Number],
      required: false
    },
    s: {
      type: [String, Number],
      required: false
    },
    m: {
      type: [String, Number],
      required: false
    },
    l: {
      type: [String, Number],
      required: false
    }
  },
  computed: {
    sizeClasses () {
      let classArray = []
      if (this.xs) {
        classArray.push(`col-xs-${this.xs}`)
      }
      if (this.s) {
        classArray.push(`col-sm-${this.s}`)
      }
      if (this.m) {
        classArray.push(`col-md-${this.m}`)
      }
      if (this.l) {
        classArray.push(`col-lg-${this.l}`)
      }
      return classArray
    }
  }
}
</script>
