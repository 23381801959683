import Context from '../Context'
import ErrorHttp from '../../error/ErrorHttp'
import type Interceptor from '../Interceptor'

/**
 * Retry interceptor.
 *
 * Handles retries.
 */
export default class Retry implements Interceptor {
  /**
   * @inheritdoc
   */
  public async intercept (context: Context): Promise<void> {
    try {
      await context.proceed()
    } catch (error) {
      await this.handleError(context, error)
    }
  }

  /**
   * Handles error.
   */
  protected async handleError (context: Context, error: unknown): Promise<void> {
    if (!this.isErrorHttp(error)) {
      throw error
    }
    if (!error.request.canRetry) {
      throw error
    }
    await this.retry(context)
  }

  /**
   * Returns whether the error is an http error.
   */
  protected isErrorHttp (error: unknown): error is ErrorHttp {
    return error instanceof ErrorHttp
  }

  /**
   * Retries a request.
   */
  protected async retry (context: Context): Promise<void> {
    context.request.doRetry()
    await this.intercept(context)
  }
}
