import Context from '../Context'
import HttpClient from '../../http/Client'
import type Interceptor from '../Interceptor'
import Request from '../../Request'
import Response from '../../Response'

/**
 * Base interceptor.
 *
 * Sends request and returns the response.
 */
export default class Base implements Interceptor {
  /**
   * Instantiates a new base interceptor.
   */
  public constructor (httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  /**
   * @inheritdoc
   */
  public async intercept (context: Context): Promise<void> {
    context.response = await this.send(context.request)
  }

  /**
   * Sends request and returns the response.
   */
  protected async send (request: Request): Promise<Response> {
    const { status, headers, body } = await this.httpClient.send<Request['body'], Response['body']>(request)
    return new Response(status, headers, body, request)
  }

  /**
   * Underlying http client.
   */
  protected httpClient: HttpClient
}
