import ErrorDelayedProcessing from '../error/ErrorDelayedProcessing'
import Response from '../Response'
import type Strategy from './Strategy'
import { type StrategyType } from './StrategyType'

/**
 * Strategy delayed processing.
 *
 * Handles response by throwing a delayed processing error.
 */
export default class StrategyDelayedProcessing implements Strategy {
  /**
   * @inheritdoc
   */
  public type: StrategyType = 'DELAYED_PROCESSING'

  /**
   * @inheritdoc
   */
  public async handle (response: Response): Promise<Response> {
    throw new ErrorDelayedProcessing(response)
  }
}
