import CacheService from '../../cache/Cache'
import Context from '../Context'
import type Interceptor from '../Interceptor'
import Response from '../../Response'

/**
 * Cache interceptor.
 *
 * Handles cache.
 */
export default class Cache implements Interceptor {
  /**
   * Instantiates a new cache interceptor.
   */
  public constructor (cache: CacheService) {
    this.cache = cache
  }

  /**
   * @inheritdoc
   */
  public async intercept (context: Context): Promise<void> {
    if (this.skipCache(context)) {
      await context.proceed()
    } else {
      await this.handleCache(context)
    }
  }

  /**
   * Returns whether cache should be skipped for this request.
   */
  protected skipCache (context: Context): boolean {
    return !context.request.canCache
  }

  /**
   * Handles cache.
   */
  protected async handleCache (context: Context): Promise<void> {
    if (this.hasResponseInCache(context)) {
      context.response = this.getResponseFromCache(context)
    } else {
      await context.proceed()
      if (this.shouldSetResponseInCache(context)) {
        this.setResponseInCache(context)
      }
    }
  }

  /**
   * Returns whether response is in the cache.
   */
  protected hasResponseInCache (context: Context): boolean {
    return this.cache.has(context.response)
  }

  /**
   * Gets response from the cache.
   */
  protected getResponseFromCache (context: Context): Response {
    const { status, headers, body } = this.cache.get(context.response)!
    return new Response(status, headers, body, context.request)
  }

  /**
   * Whether response should be set in cache.
   */
  protected shouldSetResponseInCache (context: Context): boolean {
    return context.response.body.StatusCode === 'OK'
  }

  /**
   * Sets response in the cache.
   */
  protected setResponseInCache (context: Context): void {
    this.cache.set(context.response)
  }

  /**
   * Cache service.
   */
  protected cache: CacheService
}
