import axios, { AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios'
import ClientRequest from '../Request'
import ClientResponse from '../Response'
import Error from '../error/ErrorHttp'
import type Request from './Request'
import type Response from './Response'
import type Headers from './Headers'

/**
 * Http client.
 */
export default class Client {
  /**
   * Instantiates a new http client.
   */
  public constructor () {
    this.axios = axios.create()
  }

  /**
   * Sends an http request and returns the http response.
   */
  public async send<X, Y> (request: Request<X>): Promise<Response<Y>> {
    try {
      return await this.trySend(request)
    } catch (axiosError) {
      // Axios throws on:
      // - http status code != 2xx,
      // - other network errors.
      // Wrap axios error and let it bubble up.
      throw this.getThrownError(axiosError as AxiosError, request)
    }
  }

  /**
   * Sends an http request and returns the http response or throws on error.
   */
  protected async trySend<X, Y> (request: Request<X>): Promise<Response<Y>> {
    const axiosRequest = this.compileRequest(request)
    const axiosResposne = await this.axios.request(axiosRequest)
    return this.parseResponse(axiosResposne)
  }

  /**
   * Compiles request.
   */
  protected compileRequest<X> (request: Request<X>): AxiosRequestConfig {
    return {
      method: request.method,
      url: request.url,
      headers: request.headers,
      data: request.body,
      timeout: request.timeout
    }
  }

  /**
   * Parses response.
   */
  protected parseResponse<Y> (axiosResponse: AxiosResponse): Response<Y> {
    return {
      status: axiosResponse.status,
      headers: axiosResponse.headers as Headers,
      body: axiosResponse.data
    }
  }

  /**
   * Returns thrown error.
   */
  protected getThrownError (axiosError: AxiosError, request: Request): Error {
    const response = axiosError.response ? this.parseResponse(axiosError.response) : undefined
    return new Error(axiosError.message, request as ClientRequest, response as ClientResponse)
  }

  /**
   * Underlying axios instance.
   */
  protected axios: AxiosInstance
}
