import Request from '../Request'

/**
 * Token null error.
 */
export default class ErrorTokenNull extends Error {
  /**
   * Request that threw this error.
   */
  public request: Request

  /**
   * Instantiates a new token expired error.
   */
  public constructor (request: Request) {
    super('Digital Interface token null')
    this.request = request
  }
}
