import type Config from '../Config'
import { Abschlag, Account, Adressen, Arbeitsauftraege, Auftraege, Dienstleister, Inhalte, Kontakt, Logging, Netzentgelte, Opts, Produkt, Prozesse, Validierung, Vertrag, Vorversorger, Zaehlerstand, Zahlung } from './model'

export const DELETE_ACCOUNT_GP_ZUORDNUNG: Config<Account.DeleteAccountGPZuordnung.Request> = {
  url: '/Account/DeleteAccountGPZuordnung',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_UMZUGS_PRODUKTE: Config<Produkt.GetUmzugsprodukte.Request, Produkt.GetUmzugsprodukte.Response> = {
  url: '/Produkt/GetUmzugsprodukte',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_ARBEITSAUFTRAG: Config<Arbeitsauftraege.GetArbeitsauftrag.Request, Arbeitsauftraege.GetArbeitsauftrag.Response> = {
  url: '/Arbeitsauftraege/GetArbeitsauftrag',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const DELETE_ARBEITSAUFTRAG: Config<Arbeitsauftraege.CreateArbeitsauftragStatus.Request> = {
  url: '/Arbeitsauftraege/CreateArbeitsauftragStatus',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_ARBEITSAUFTRAG: Config<Arbeitsauftraege.CreateArbeitsauftrag.Request, Arbeitsauftraege.CreateArbeitsauftrag.Response> = {
  url: '/Arbeitsauftraege/CreateArbeitsauftrag',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_ZAEHLERSTAND: Config<Zaehlerstand.GetZaehlerstaende.Request, Zaehlerstand.GetZaehlerstaende.Response> = {
  url: '/Zaehlerstand/GetZaehlerstaende',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_LASTGANG: Config<Vertrag.GetLastgang.Request, Vertrag.GetLastgang.Response> = {
  url: '/Vertrag/GetLastgang',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_LASTGANG_KOSTEN: Config<Vertrag.GetLastgangKosten.Request, Vertrag.GetLastgangKosten.Response> = {
  url: '/Vertrag/GetLastgangKosten',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_ZAEHLERSTAND: Config<Zaehlerstand.CreateZaehlerstand.Request, Zaehlerstand.CreateZaehlerstand.Response> = {
  url: '/Zaehlerstand/CreateZaehlerstand',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const DETECT_ZAEHLERSTAND: Config<Zaehlerstand.DetectZaehlerstand.Request, Zaehlerstand.DetectZaehlerstand.Response> = {
  url: '/Zaehlerstand/DetectZaehlerstand',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_VERTRAGSLISTE: Config<Vertrag.GetVertragsliste.Request, Vertrag.GetVertragsliste.Response> = {
  url: '/Vertrag/GetVertragsliste',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_WIDERRUF: Config<Vertrag.CreateWiderruf.Request, Vertrag.CreateWiderruf.Response> = {
  url: '/Vertrag/CreateWiderruf',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_FORDERUNGEN: Config<Vertrag.GetForderungen.Request, Vertrag.GetForderungen.Response> = {
  url: '/Vertrag/GetForderungen',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_ZAHLUNGSINFORMATION: Config<Zahlung.GetZahlungsinformation.Request, Zahlung.GetZahlungsinformation.Response> = {
  url: '/Zahlung/GetZahlungsinformation',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const UPDATE_ZAHLUNGSINFORMATION: Config<Zahlung.UpdateZahlungsinformation.Request> = {
  url: '/Zahlung/UpdateZahlungsinformation',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_PROZESSE: Config<Prozesse.GetProzesse.Request, Prozesse.GetProzesse.Response> = {
  url: '/Prozesse/GetProzesse',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_VERTRAEGE: Config<Vertrag.GetVertraege.Request, Vertrag.GetVertraege.Response> = {
  url: '/Vertrag/GetVertraege',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_DATEIEN: Config<Inhalte.GetDateien.Request, Inhalte.GetDateien.Response> = {
  url: '/Inhalte/GetDateien',
  timeout: 120,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_PROZESSINFORMATION: Config<Prozesse.GetProzessinformation.Request, Prozesse.GetProzessinformation.Response> = {
  url: '/Prozesse/GetProzessinformation',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_INHALT: Config<Inhalte.GetInhalt.Request, Inhalte.GetInhalt.Response> = {
  url: '/Inhalte/GetInhalt',
  cache: true,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_KUNDENDOKUMENTE: Config<Inhalte.GetKundendokumente.Request, Inhalte.GetKundendokumente.Response> = {
  url: '/Inhalte/GetKundendokumente',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_TEMP_PASSWORT: Config<Account.GetTempPasswort.Request> = {
  url: '/Account/GetTempPasswort',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const SET_PASSWORT: Config<Account.SetPasswort.Request> = {
  url: '/Account/SetPasswort',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_LOG_ITEMS: Config<Logging.CreateLogItems.Request> = {
  url: '/Logging/CreateLogItems',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_FELDER: Config<Inhalte.GetFelder.Request, Inhalte.GetFelder.Response> = {
  url: '/Inhalte/GetFelder',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_ACCOUNT: Config<Account.UpdateAccount.Request, void> = {
  url: '/Account/CreateAccount',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_ACCOUNT_AKTIVIERUNG: Config<Account.CreateAccountAktivierung.Request, void> = {
  url: '/Account/CreateAccountAktivierung',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const UPDATE_ACCOUNT: Config<Account.UpdateAccount.Request, void> = {
  url: '/Account/UpdateAccount',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const DELETE_ACCOUNT: Config<Account.DeleteAccount.Request, void> = {
  url: '/Account/DeleteAccount',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CHECK_FELDER: Config<Validierung.CheckFelder.Request, Validierung.CheckFelder.Response> = {
  url: '/Validierung/CheckFelder',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'CONTINUE',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CHECK_DATEI: Config<Validierung.CheckDatei.Request> = {
  url: '/Validierung/CheckDatei',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_KONTAKTNACHRICHT: Config<Kontakt.CreateKontaktnachricht.Request> = {
  url: '/Kontakt/CreateKontaktnachricht',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_ACCOUNT: Config<Account.GetAccount.Request, Account.GetAccount.Response> = {
  url: '/Account/GetAccount',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_PRODUKTE: Config<Produkt.GetProdukte.Request, Produkt.GetProdukte.Response> = {
  url: '/Produkt/GetProdukte',
  stateSince: true,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_NEUAUFTRAG: Config<Auftraege.CreateNeuauftrag.Request, Auftraege.CreateNeuauftrag.Response> = {
  url: '/Auftraege/CreateNeuauftrag',
  diTimeout: 20000,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_UMZUG: Config<Vertrag.CreateUmzug.Request, Vertrag.CreateUmzug.Response> = {
  url: '/Vertrag/CreateUmzug',
  diTimeout: 20000,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_ORTE: Config<Adressen.GetOrte.Request, Adressen.GetOrte.Response> = {
  url: '/Adressen/GetOrte',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_LIEFERBEGINN: Config<Produkt.GetLieferbeginn.Request, Produkt.GetLieferbeginn.Response> = {
  url: '/Produkt/GetLieferbeginn',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_ADDRESSE: Config<Adressen.GetAdresse.Request, Adressen.GetAdresse.Response> = {
  url: '/Adressen/GetAdresse',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_KUNDENKAMPAGNEN: Config<Produkt.GetKundenkampagnen.Request, Produkt.GetKundenkampagnen.Response> = {
  url: '/Produkt/GetKundenkampagnen',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_VORVERSORGER: Config<Vorversorger.GetVorversorger.Request, Vorversorger.GetVorversorger.Response> = {
  url: '/Vorversorger/GetVorversorger',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_ABSCHLAEGE: Config<Abschlag.GetAbschlaege.Request, Abschlag.GetAbschlaege.Response> = {
  url: '/Abschlag/GetAbschlaege',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const UPDATE_ABSCHLAG: Config<Abschlag.UpdateAbschlag.Request, Abschlag.UpdateAbschlag.Response> = {
  url: '/Abschlag/UpdateAbschlag',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_WECHSELPRODUKTE: Config<Produkt.GetWechselprodukte.Request, Produkt.GetWechselprodukte.Response> = {
  url: '/Produkt/GetWechselprodukte',
  stateSince: true,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_PRODUKTWECHSEL: Config<Auftraege.CreateProduktwechsel.Request> = {
  url: '/Auftraege/CreateProduktwechsel',
  diTimeout: 20000,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_ZAEHLERKOSTEN: Config<Netzentgelte.GetZaehlerkosten.Request, Netzentgelte.GetZaehlerkosten.Response> = {
  url: '/Netzentgelte/GetZaehlerkosten',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_GP: Config<Account.CreateAccountGPZuordnung.Request> = {
  url: '/Account/CreateAccountGPZuordnung',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_DIENSTLEISTERINFORMATION: Config<Dienstleister.GetDienstleisterInformation.Request, Dienstleister.GetDienstleisterInformation.Response> = {
  url: '/Dienstleister/GetDienstleisterInformation',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_RATENPLAN: Config<Zahlung.GetRatenplan.Request, Zahlung.GetRatenplan.Response> = {
  url: '/Zahlung/GetRatenplan',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const CREATE_RATENPLAN: Config<Zahlung.CreateRatenplan.Request, Zahlung.CreateRatenplan.Response> = {
  url: '/Zahlung/CreateRatenplan',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const UPDATE_STAMMDATEN: Config<Vertrag.UpdateStammdaten.Request, Vertrag.UpdateStammdaten.Response> = {
  url: '/Vertrag/UpdateStammdaten',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_OPTS: Config<Opts.GetOpts.Request, Opts.GetOpts.Response> = {
  url: '/Opts/GetOpts',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const UPDATE_OPTS: Config<Opts.UpdateOpts.Request, Opts.UpdateOpts.Response> = {
  url: '/Opts/UpdateOpts',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_TOKEN_ANONYM: Config<Account.GetTokenAnonym.Request, Account.GetTokenAnonym.Response> = {
  url: '/Account/GetTokenAnonym',
  anonymous: true,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_TOKEN_USER: Config<Account.GetTokenUser.Request, Account.GetTokenUser.Response> = {
  url: '/Account/GetTokenUser',
  anonymous: true,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_TOKEN_GENERAL: Config<Account.GetTokenAllgemein.Request, Account.GetTokenAllgemein.Response> = {
  url: '/Account/GetTokenAllgemein',
  anonymous: true,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const GET_TOKEN_REFRESH: Config<Account.GetTokenRefresh.Request, Account.GetTokenRefresh.Response> = {
  url: '/Account/GetTokenRefresh',
  anonymous: true,
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'RETRY',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}

export const DELETE_TOKEN: Config = {
  url: '/Account/DeleteToken',
  statusCodeMap: {
    ACCESS_DENIED: 'CAPTCHA',
    BUSINESS_ERROR: 'ERROR',
    CLIENT_ERROR: 'ERROR',
    LOGIN_ERROR: 'ERROR',
    NOT_IMPLEMENTED: 'ERROR',
    OK: 'CONTINUE',
    PROCESSING: 'CONTINUE',
    SYSTEM_ERROR: 'ERROR',
    USER_ERROR: 'ERROR',
    IN_MAINTENANCE: 'CONTINUE',
    TOKEN_EXPIRED: 'REFRESH'
  }
}
