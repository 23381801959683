import { type TokenResponse } from '../core/model-shared'

/**
 * Authentication token.
 */
export default class Token {
  /**
   * Creates a new token from response.
   */
  public static createFromResponse (token: TokenResponse, username: string, role: Token['role']): Token | undefined {
    if (token) {
      return new Token(token.AccessToken, token.RefreshToken, username, token.InterneZeit, role)
    }
  }

  /**
   * Creates a new token.
   */
  public static create (token: Token): Token {
    return new Token(token.accessToken, token.refreshToken, token.username, token.time, token.role)
  }

  /**
   * Creates a new empty token.
   */
  public static empty () {
    return new Token('', '', '', '', 'EMPTY')
  }

  /**
   * Token used for accessing the api.
   */
  public accessToken: string

  /**
   * Token used for refreshing the current access token.
   */
  public refreshToken: string

  /**
   * Token role.
   *
   * - USER             (level 4)
   * - DEMO             (level 3)
   * - BUSINESS_PARTNER (level 2)
   * - CONTRACT_ACCOUNT (level 1)
   * - ANONYMOUS        (level 0)
   * - EMPTY            (level -)
   */
  public role:
    | 'USER'
    | 'DEMO'
    | 'BUSINESS_PARTNER'
    | 'CONTRACT_ACCOUNT'
    | 'ANONYMOUS'
    | 'EMPTY'

  /**
   * Username associated with this token.
   */
  public username: string

  /**
   * Internet time delivered from digital interface.
   */
  public time: string

  /**
   * Instantiates a new authentication token.
   */
  public constructor (accessToken: string, refreshToken: string, username: string, time: string, role: Token['role']) {
    this.accessToken = accessToken
    this.refreshToken = refreshToken
    this.username = username
    this.time = time
    this.role = role
  }

  /**
   * Return whether it is user or demo token (level 4 or 3).
   */
  public isUserOrDemo (): boolean {
    return this.role === 'USER' || this.role === 'DEMO'
  }

  /**
   * Returns whether it is user token (level 4).
   */
  public isUser (): boolean {
    return this.role === 'USER'
  }

  /**
   * Returns whether it is demo token (level 3).
   */
  public isDemo (): boolean {
    return this.role === 'DEMO'
  }

  /**
   * Returns whether it is business partner token (level 2).
   */
  public isBusinessPartner (): boolean {
    return this.role === 'BUSINESS_PARTNER'
  }

  /**
   * Returns whether it is contract account token (level 1).
   */
  public isContractAccount (): boolean {
    return this.role === 'CONTRACT_ACCOUNT'
  }

  /**
   * Returns whether it is anonymous token (level 0).
   */
  public isAnonymous (): boolean {
    return this.role === 'ANONYMOUS'
  }
}
