import * as Model from '../../core/1.3/model'

class VertragslisteResponse extends Model.Vertrag.GetVertragsliste.Response { }

/**
 * Contract list helper.
 */
export default class Vertragsliste extends VertragslisteResponse {
  /**
   * Creates empty contract list.
   */
  public static createEmpty () {
    return new Vertragsliste({ GP: [] })
  }

  /**
   * Instantiates a new contract list helper.
   */
  public constructor (vertragsliste: VertragslisteResponse) {
    super()
    Object.assign(this, vertragsliste)
  }

  /**
   * Flat map of all contracts.
   */
  public get VK () {
    return this.GP.flatMap(gp => gp.Vertraege)
  }

  /**
   * Returns whether there is any business partner in this list.
   */
  public get hasGP () {
    return this.GP.length > 0
  }

  /**
   * Returns whether there is no business partner in this list.
   */
  public get noGP () {
    return this.GP.length === 0
  }

  /**
   * Returns whether there is any contract in this list.
   */
  public get hasVK () {
    return this.VK.length > 0
  }

  /**
   * Returns whether there is no contract in this list.
   */
  public get noVK () {
    return this.VK.length === 0
  }

  /**
   * Returns business partner with given id.
   */
  public getGP (id: string) {
    return this.GP.find(gp => gp.GP.ReferenzID === id || gp.GP.Anzeigetext === id)
  }

  /**
   * Returns business partner having contract with given id.
   */
  public getGPbyVK (id: string) {
    return this.GP.find(gp => gp.Vertraege.find(vk => vk.VK.ReferenzID === id || vk.VK.Anzeigetext === id))
  }

  /**
   * Returns contract with given id.
   */
  public getVK (id: string) {
    return this.VK.find(vk => vk.VK.ReferenzID === id || vk.VK.Anzeigetext === id)
  }
}
