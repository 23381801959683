import * as Model from '../../core/1.3/model'

class AbschlaegeResponse extends Model.Abschlag.GetAbschlaege.Response { }

/**
 * Part payment helper.
 */
export default class Abschlaege extends AbschlaegeResponse {
  /**
  * Instantiates a new part payment helper.
  */
  public constructor (abschlaege: AbschlaegeResponse) {
    super()
    Object.assign(this, abschlaege)
  }

  /**
   * Part payments history.
   */
  public get Abschlagsplan () {
    return this.Abschlagsplaene[0].Abschlagsplan
  }
}
