import Response from '../Response'
import type Strategy from './Strategy'
import { type StrategyType } from './StrategyType'

/**
 * Strategy continue.
 *
 * Handles response by continuing execution flow.
 */
export default class StrategyContinue implements Strategy {
  /**
   * @inheritdoc
   */
  public type: StrategyType = 'CONTINUE'

  /**
   * @inheritdoc
   */
  public async handle (response: Response): Promise<Response> {
    return response
  }
}
