import * as Model from '../../core/1.3/model'
import Response from '../../Response'

class KundendokumenteRequestBody extends Model.Inhalte.GetKundendokumente.Request { }
class KundendokumenteResponseBody extends Model.Inhalte.GetKundendokumente.Response { }
type KundendokumenteResponse = Response<KundendokumenteRequestBody, KundendokumenteResponseBody>
/**
 * Customer documents helper.
 */
export default class Kundendokumente extends KundendokumenteResponseBody {
  /**
   * Instantiates a new customer documents helper.
   */
  public constructor (response: KundendokumenteResponse) {
    super()
    Object.assign(this, response.body.Result)
    this.response = response
  }

  /**
   * Returns customer document with given name.
   */
  public get (name: string) {
    return this.Kundendokumente.find(dokument => dokument.Name === name)
  }

  /**
   * Validation messages.
   */
  public get Meldungen (): string {
    return this.response.body.Meldungen
      .map(message => message.FachlicheBeschreibung || message.TechnischeBeschreibung)
      .join('\n')
  }

  protected response: KundendokumenteResponse
}
