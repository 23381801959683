<template>
  <div :class="{'cso-footer': true, 'cso-footer-simple': isSimple}">
    <div class="container" :class="{ 'container-app': !CsoRouter.isOpenArea }">
      <div class="top" v-if="!noTop">
        <div :class="{ open: isOpen(1) }">
          <h5 @click="open(1)">
            {{ $t('footer.onlineServices') }}
            <i  :class="{ 'vf-icon-up': isOpen(1), 'vf-icon-down': !isOpen(1) }" class="vf-icon vf-icon-size--48"></i>
          </h5>
          <ul>
            <li><a :href="StaticUrls.deliveryAndContract">{{ $t('footer.reportMove') }}</a></li>
            <li><a :href="StaticUrls.meterReading">{{ $t('footer.enterMeterReading') }}</a></li>
            <li><a :href="StaticUrls.partPayment">{{ $t('footer.changePartPayment') }}</a></li>
            <li><a :href="StaticUrls.tariffChange">{{ $t('footer.changeTariff') }}</a></li>
            <li><a :href="StaticUrls.vtflReportFault">{{ $t('footer.reportFault') }}</a></li>
            <li><a :href="StaticUrls.vtflFraudsWarning">{{ $t('footer.bewareOfFrauds') }}</a></li>
          </ul>
        </div>
        <div :class="{ open: isOpen(2) }">
          <h5 @click="open(2)">
            {{ $t('footer.vattenfallOffers') }}
            <i  :class="{ 'vf-icon-up': isOpen(2), 'vf-icon-down': !isOpen(2) }" class="vf-icon vf-icon-size--48"></i>
          </h5>
          <ul>
            <li><a :href="StaticUrls.vtflInfoWorldEnergy">{{ $t('footer.infoWorldEnergy') }}</a></li>
            <li><a :href="StaticUrls.vtflSaveEnergy">{{ $t('footer.saveEnergy') }}</a></li>
            <li><a :href="StaticUrls.vtflInside">{{ $t('footer.inside') }}</a></li>
            <li><a :href="StaticUrls.vtflTellAFriend">{{ $t('footer.tellAFriend') }}</a></li>
            <li><a :href="StaticUrls.vtflLatePayment">{{ $t('footer.latePayment') }}</a></li>
          </ul>
        </div>
        <div :class="{ open: isOpen(3) }">
          <h5 @click="open(3)">
            {{ $t('footer.aboutVattenfall') }}
            <i  :class="{ 'vf-icon-up': isOpen(3), 'vf-icon-down': !isOpen(3) }" class="vf-icon vf-icon-size--48"></i>
          </h5>
          <ul>
            <li><a :href="StaticUrls.vtflAbout">{{ $t('footer.deutschland') }}</a></li>
            <li><a :href="StaticUrls.vtflGroup">{{ $t('footer.group') }}</a></li>
            <li><a :href="StaticUrls.vtflSustainability">{{ $t('footer.sustainability') }}</a></li>
          </ul>
        </div>
        <div></div>
      </div>
      <div class="bottom">
        <ul class="links">
          <li><a :href="StaticUrls.vtflContact">{{ $t('footer.contact') }}</a></li>
          <li><a :href="StaticUrls.vtflImprint">{{ $t('footer.imprint') }}</a></li>
          <li><a :href="StaticUrls.vtflTermsOfService">{{ $t('footer.termsOfService') }}</a></li>
          <li><a :href="StaticUrls.vtflPrivacy">{{ $t('footer.privacy') }}</a></li>
          <li><a :href="StaticUrls.vtflGlossary">{{ $t('footer.glossary') }}</a></li>
          <li><a class="optanon-show-settings">{{ $t('footer.cookieSettings') }}</a></li>
        </ul>
        <p class="copyright">© Vattenfall {{ year }}</p>
      </div>
      <div class="simple">
        <ul class="links">
          <li><a :href="StaticUrls.vtflImprint">{{ $t('footer.imprint') }}</a></li>
          <li><a :href="StaticUrls.vtflPrivacy">{{ $t('footer.privacy') }}</a></li>
          <li><a :href="StaticUrls.smartFAQ">{{ $t('footer.faq') }}</a></li>
          <li><a class="optanon-show-settings">{{ $t('footer.cookieSettings') }}</a></li>
        </ul>
        <p class="copyright">© Vattenfall {{ year }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CsoRouter from '@/api/CsoRouter'
import StaticUrls from '@/common/StaticUrls'

export default {
  name: 'CsoFooter',

  props: {
    simple: {
      type: Boolean,
      default: false
    },
    noTop: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      StaticUrls,
      CsoRouter,
      opened: null as null | number
    }
  },

  computed: {
    isSimple (): boolean {
        return this.$route.meta!.simpleHeaderAndFooter as boolean || this.simple
    },
    year (): number {
      const date = new Date()
      return date.getFullYear()
    }
  },

  methods: {
    isOpen (id: number) {
      return this.opened === id
    },

    open (id: number) {
      this.opened = this.isOpen(id) ? null : id
    }
  }
}
</script>

<style lang="scss">
.cso-footer {
  font-family: $font-family-regular;
  padding: 36px 0;
  color: $gray-dark;
  @include hidden-print();

  h5 {
    margin: 0;
    font-size: 20px;
    color: $black;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    margin: 0;
    padding: 0;
  }

  a:hover,
  a:active {
    color: $black;
  }

  .top {
    display: flex;
    gap: 32px;
    border-top: 1px solid $gray-light;
    padding-top: 24px;

    @include md {
      gap: 16px;
    }

    @include sm {
      gap: 0px;
      padding-top: 0;
      flex-direction: column;
    }
  }

  .top h5 {
    padding: 10px 0;
    position: relative;

    @include md {
      height: 53px;
    }

    @include sm {
      height: initial;
    }

    i {
      position: absolute;
      top: 10px;
      right: 0;
      display: none;

      @include sm {
        display: block;
      }
    }
  }

  .top > div {
    flex: 1;

    @include sm {
      h5 {
        cursor: pointer;
        border-top: 1px solid $gray-light;
        padding: 24px 0;
      }

      &:first-child h5 {
        border: 0;
      }

      ul {
        display: none;
        padding-bottom: 24px;
      }

      &.open ul {
        display: block;
      }
    }
  }

  .top li {
    margin: 8px 0 0 0;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin: 28px 0 0 0;
    padding: 8px 0 0 0;
    border-top: 1px solid $gray-light;

    @include md {
      flex-direction: column;
      padding: 0;
    }

    @include sm {
      margin: 0;
    }
  }

  .copyright {
    margin: auto 0;

    @include md {
      text-align: center;
    }
  }

  .bottom .links,
  .simple .links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include md {
      margin: 32px 0 16px;
    }
  }

  .bottom .links li,
  .simple .links li {
    line-height: 16px;
    padding: 8px 16px 8px 0;
  }

  .simple {
    display: none;
  }

  .optanon-show-settings {
    cursor: pointer;
  }
}

.cso-footer-simple {
  @include hidden-print();

  .top,
  .bottom {
    display: none;
  }

  .simple {
    padding: 8px 0 0 0;
    border-top: 1px solid $gray-light;
    display: flex;
    justify-content: space-between;

    @include md {
      flex-direction: column;
      padding: 0;
    }
  }
}
</style>
