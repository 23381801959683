import type Config from '../Config'

export const BASE: Config = {
  id: 'BASE',
  priority: 10
}

export const CACHE: Config = {
  id: 'CACHE',
  priority: 20
}

export const DELAY: Config = {
  id: 'DELAY',
  priority: 30
}

export const RETRY: Config = {
  id: 'RETRY',
  priority: 40
}

export const AUTHENTICATOR: Config = {
  id: 'AUTHENTICATOR',
  priority: 60
}

export const STATUS_CODE: Config = {
  id: 'STATUS_CODE',
  priority: 70
}
