import Context from '../Context'
import Handler from '../../status-code/Handler'
import type Interceptor from '../Interceptor'

/**
 * Status code interceptor.
 *
 * Handles response status code with correct strategy.
 */
export default class StatusCode implements Interceptor {
  /**
   * Instantiates a new status code interceptor.
   */
  public constructor (handler: Handler) {
    this.handler = handler
  }

  /**
   * @inheritdoc
   */
  public async intercept (context: Context): Promise<void> {
    await context.proceed()
    if (this.shouldHandleStatusCode(context)) {
      await this.handleStatusCode(context)
    }
  }

  /**
   * Returns whether status code of the response should be handled.
   */
  protected shouldHandleStatusCode (context: Context): boolean {
    return context.request.statusCodeMap !== undefined
  }

  /**
   * Handles response status code.
   */
  protected async handleStatusCode (context: Context): Promise<void> {
    context.response = await this.handler.handleStatusCode(context.response)
  }

  /**
   * Status code handler.
   */
  protected handler: Handler
}
