import * as Model from '../../core/1.3/model'

class OptsResponse extends Model.Opts.GetOpts.Response { }

/**
 * Optional agreements.
 */
export default class Opts extends OptsResponse {
  /**
   * Creates empty optional agreements helper.
   */
  public static createEmpty () {
    return new Opts({ Result: [] })
  }

  /**
   * Instantiates a new optional agreements helper.
   */
  public constructor (opts: OptsResponse) {
    super()
    Object.assign(this, opts)
  }

  /**
   * Returns status of given option.
   */
  public getStatus (name: string) {
    const [sparte, kommunikationsweg] = name.split('|')
    const opt = this.Result[0].Einwilligungen.find(einwilligung =>
      einwilligung.Sparte === sparte && einwilligung.Kommunikationsweg === kommunikationsweg
    )
    if (opt?.Status === 'OPTIN') {
      return true
    }
    if (opt?.Status === 'OPTOUT') {
      return false
    }
    return undefined
  }
}
