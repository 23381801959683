import * as AI from '@microsoft/applicationinsights-web'
import * as DI from '@/api/digital-interface/client'
import * as VR from 'vue-router'

/**
 * Application insights.
 */
export default class ApplicationInsights extends AI.ApplicationInsights {
  /**
   * Name of digital interface error in application insights.
   */
  public static readonly EVENT_ERROR_DI = 'CSO_ERROR_DI'

  /**
   * Name of router navigation event in application insights.
   */
  public static readonly EVENT_ROUTER_NAVIGATION = 'CSO_ROUTER_NAVIGATION'

  /**
   * Logs an error that you have caught.
   */
  public trackError (error: Error) {
    this.trackException({
      exception: error,
      properties: this.data
    })
  }

  /**
   * Adds custom telemetry filtering.
   */
  public addTelemetryFiltering () {
    const blacklist = [
      'm-pathy.com',
      'google',
      'stats.g.doubleclick.net',
      'www.medialead.de',
      'translate.googleapis.com'
    ]
    this.addTelemetryInitializer(function (envelope) {
      // filter out blacklisted urls
      if (envelope.baseType === 'RemoteDependencyData' && envelope.baseData && envelope.baseData.name &&
        blacklist.some(blacklistItem => envelope.baseData!.name.includes(blacklistItem))) {
        return false
      }
      // remove digital interface token from collected headers
      if (envelope.baseData?.properties?.requestHeaders?.['davis-token']) {
        envelope.baseData.properties.requestHeaders['davis-token'] = '******'
      }
    })
    this.addTelemetryInitializer(function (envelope) {
      if (envelope.baseType === 'RemoteDependencyData' && envelope.baseData && envelope.baseData.message &&
        envelope.baseData.message.indexOf('Digital Interface token expired') >= 0) {
        return false
      }
    })
  }

  /**
   * Logs a promise rejection that you have caught.
   */
  public trackPromiseRejection (event: PromiseRejectionEvent) {
    const message = event.reason.message
    const context = JSON.stringify(event.reason)
    const hasContext = context !== '{}'
    this.trackError({
      name: event.type,
      message: hasContext ? `${message} ${context}` : message,
      stack: event.reason.stack
    })
  }

  /**
   * Logs a digital interface error that you have caught.
   */
  public trackDigitalInterfaceError (error: Error, request: DI.Request, response: DI.Response): void {
    this.trackEvent({
      name: ApplicationInsights.EVENT_ERROR_DI,
      properties: {
        message: error.message,
        endpoint: request.endpoint,
        request,
        response
      }
    })
  }

  /**
   * Logs a router navigation event.
   */
  public trackRouterNavigation (to: VR.RouteLocation, from: VR.RouteLocation) {
    this.trackEvent({
      name: ApplicationInsights.EVENT_ROUTER_NAVIGATION,
      properties: {
        to: to.fullPath,
        from: from.fullPath
      }
    })
  }

  /**
   * Logs some event.
   */
  public trackEvent (event: AI.IEventTelemetry) {
    super.trackEvent({
      name: event.name,
      properties: {
        ...event.properties,
        ...this.data
      }
    })
  }

  /**
   * Common data for logs.
   */
  protected get data () {
    return {
      url: window.location.href,
      parentLogId: JSON.parse(localStorage.getItem('vfdi.parent_log_id') || ''),
      sessionEnd: localStorage.getItem('cso.session_end')
    }
  }
}
