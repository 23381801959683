<template>
  <cso-component-state :componentState="state">
    <template v-slot:ready>
      <section id="faq-wrapper"
              class="faq-wrapper">
        <h2> {{ $t('login.title.faq') }}</h2>
        <cso-grid-row v-if="!imageSectionContent && !textContent">
          <cso-collapsable :columns="columns"
                           :data="faq">
          </cso-collapsable>
      </cso-grid-row>
      <cso-grid-row v-if="imageSectionContent && textContent">
        <cso-grid-column m="9"
                         xs="12">
          <cso-collapsable :columns="columns"
                           :data="faq">
          </cso-collapsable>
        </cso-grid-column>

        <cso-grid-column m="3"
                         xs="12"
                         v-if="imageExists">
          <img class="hippo-img"
               alt="Bild"
               loading="lazy"
               :src="imageSectionContent" />
          <div v-html="textContent"></div>
        </cso-grid-column>
      </cso-grid-row>
    </section>
   </template>
  </cso-component-state>
</template>

<script>
import di from '@/api/digital-interface'
import ComponentsStates from '@/common/ComponentsStates'

import CsoCollapsable from '@/components/core/composition/CsoCollapsable.vue'
import CsoComponentState from '@/components/core/composition/CsoComponentState.vue'

export default {
  name: 'cso-faq',
  components: {
    CsoCollapsable,
    CsoComponentState
  },
  props: {
    url: {
      type: String,
      required: true
    },
    columns: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      state: ComponentsStates.loading,
      header: '',
      faq: [],
      imageSectionContent: '',
      textContent: ''
    }
  },
  computed: {
    imageExists: function () {
      return Boolean(this.imageSectionContent && this.textContent)
    }
  },
  methods: {
    async fetchData () {
      try {
        const response = await di.v13.getHippo(this.url)
        const data = JSON.parse(response.get('INHALT'))

        const faqElements = data.faq.items['cascata:faqElements']
        this.faq = this.retrieveTitlesAndContentFromFaq(faqElements)
        
        if (data['image']) {
          this.imageSectionContent = data['image'].items['cascata:imagelink'].items['cascata:imagelink'].link.url
          this.textContent = data['image'].items['cascata:htmlText'].content
        }

        this.state = ComponentsStates.ready
      } catch (someError) {
        this.state = ComponentsStates.simpleError
      }
    },

    retrieveTitlesAndContentFromFaq (faqData) {
      return faqData.map(item => {
        const obj = {}

        obj.title = item.items['cascata:question']
        obj.content = item.items['cascata:answer'].content

        return obj
      })
    }
  },

  created: function () {
    this.fetchData()
  }
}
</script>

<style lang="scss">
.faq-wrapper {
  @include hidden-print();
  margin: auto;
  padding: 66px 16px 42px 16px;
  max-width: 1062px;

  @include sm {
    padding: 32px 16px 26px;
  }

  .hippo-img {
    margin-left: 35%;
  }

  h2 {
    font-size: 36px;
    font-family: $font-family-bold;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;

    @include sm {
      font-size: 24px;
    }
  }

  header {
    text-align: center;
    border-bottom: 1px solid $gray-light;
    margin-bottom: 10px;
  }
}
</style>
