import StaticUrls from '@/common/StaticUrls'
import UAParser from 'ua-parser-js'
import { marked } from 'marked'
import * as DI from '@/api/digital-interface/client'
import { format, parseISO, parse } from 'date-fns'

declare global {
  interface Window {
    dataLayer: Object[]
  }
}

const hiddenClassName = 'hidden'
const loadingClassName = 'cso-fetching'

export type Maybe<T> = T | undefined
export type RecordOf<T> = Record<string, T>
export type DateString = string
export type Radio = { label: string, value: string }

export default class Utils {
  static getUTCTime (date: Date, day = '', start = true) {
    let time = format(date, 'yyyy') + '-' + format(date, 'MM') + '-'
    if (day !== '') {
      time += day
    } else {
      time += format(date, 'dd')
    }
    if (start === true) {
      time += 'T00:00:00.000Z'
    } else {
      time += 'T23:59:59.999Z'
    }
    return new Date(time)
  }

  static capitalizeOnlyFirstLetter (parString: string) {
    return parString.charAt(0).toUpperCase() + parString.slice(1).toLowerCase()
  }

  static copyObject (parObject: Object) {
    if (parObject) {
      return JSON.parse(JSON.stringify(parObject))
    }
  }

  static localeToDate (parDate: string) {
    return parse(parDate, 'dd.MM.yyyy', new Date())
  }

  static dateToLocale (parDate: string | DateString | Date) {
    const aDate = new Date(parDate)
    return format(aDate, 'dd.MM.yyyy')
  }

  static openInNewTabOrDownload = (url: string, file: File | undefined) => {
    const link = document.createElement('a')
    link.href = url
    const browser = Utils.getUserAgent()
    if (browser.browser.includes('Safari') && file) {
      link.setAttribute('download', file.name)
    } else {
      link.target = '_blank'
    }
    document.body.appendChild(link)
    link.click()
  }

  static stringToDate (parDate: DateString) {
    return parseISO(parDate)
  }

  static formatStringDate (parDate: DateString | Date) {
    let aDate
    if (parDate instanceof Date) {
      aDate = new Date(parDate)
    } else {
      aDate = parseISO(parDate)
    }
    return format(aDate, 'dd.MM.yyyy')
  }

  static dotToComma = (value: string) => {
    return ('' + value).replace('.', ',')
  }

  static removeEuro (value: string): string | undefined {
    return Utils.removeSpaces(('' + value).replace('€', ''))
  }

  static commaToDot = (value: string | undefined) => {
    return ('' + value).replace(',', '.')
  }

  static removeDot = (value: string | undefined) => {
    return ('' + value).replace('.', '')
  }

  static priceToDataLayer (value: any) {
    return Utils.commaToDot(Utils.removeEuro(('' + value)))
  }

  static hashEmail (email: string) {
    if (!email || !email.length) {
      return
    }
    let lastDot = email.lastIndexOf('.')
    if (lastDot < 0) {
      lastDot = email.length
    }
    let newEmail = ''
    for (let c = 0, eLen = email.length; c < eLen; c++) {
      newEmail += (c <= 2 || c >= (lastDot - 3) || email[c] === '@') ? email[c] : '*'
    }
    return newEmail
  }

  static hideElement (uiSelector: string) {
    const element = document.querySelector(uiSelector)
    if (element) {
      element.classList.add(hiddenClassName)
    }
  }

  static showElement = (uiSelector: string) => {
    const element = document.querySelector(uiSelector)
    if (element) {
      element.classList.remove(hiddenClassName)
    }
  }

  static getClosestValueFromArray (num: number, arr: number[]) {
    let curr = arr[0]
    let diff = Math.abs(num - curr)
    for (let val = 0; val < arr.length; val++) {
      const newdiff = Math.abs(num - arr[val])
      if (newdiff < diff) {
        diff = newdiff
        curr = arr[val]
      }
    }
    return curr
  }

  static windowLoading (set = false) {
    if (set === false) {
      document.body.classList.remove(loadingClassName)
    } else {
      document.body.classList.add(loadingClassName)
    }
  }

  static killSpace (oldStr: string) {
    let str = oldStr
    if (str.endsWith(' ')) {
      str = oldStr.substring(0, oldStr.length - 1)
    }
    return str
  }

  static pushToDataLayer (obj: Object) {
    if (!window.dataLayer) {
      window.dataLayer = []
    }
    window.dataLayer.push(obj)
  }

  static getUrlParams (name: string): string | null {
    const url = new URLSearchParams(window.location.search.substring(1))
    return url.get(name)
  }

  static removeSpaces (value: string): string | undefined {
    if (!value) {
      return
    }
    return value.replace(/\s/g, '')
  }

  static formatIban (iban: any) {
    if (!iban) {
      return ''
    }
    iban = iban.toUpperCase()
    iban = Utils.removeSpaces(iban)
    let output = ''
    for (let c = 0, cLen = iban.length; c < cLen; c += 4) {
      output += iban.substr(c, 4) + ' '
    }
    output = Utils.killSpace(output)
    return output
  }

  static formatIbanWithSpaces (iban: string): string {
    if (!iban) {
      return ''
    }
    const ibanWithSpace = iban.match(/.{1,4}/g)
    return ibanWithSpace!.join(' ')
  }

  static isDesktop () {
    const mq = window.matchMedia('(min-width: 992px)')
    return mq.matches
  }

  static deepFind (obj: any, path: string) {
    const paths = path.split('.')
    let current = obj

    for (let i = 0; i < paths.length; ++i) {
      if (current[paths[i]] === undefined) {
        return undefined
      } else {
        current = current[paths[i]]
      }
    }
    return current
  }

  static redirectToSessionLostLoginPage () {
    window.location.assign(StaticUrls.sessionLostLoginPage)
  }

  static redirectToIpBlockedLoginPage () {
    window.location.assign(StaticUrls.ipBlockedLoginPage)
  }

  static removeDuplicates<T> (array: T[]): T[] {
    return array.filter((value, index) => {
      const _value = JSON.stringify(value)
      return index === array.findIndex(obj => {
        return JSON.stringify(obj) === _value
      })
    })
  }

  static removeEmpty<T> (array: Maybe<T>[]): T[] {
    return array.filter(item => !!item) as T[]
  }

  static capitalize (text: string): string {
    return text.charAt(0).toUpperCase() + text.substring(1)
  }

  static async readFileAsBase64 (file: File): Promise<string> {
    return new Promise(resolve => {
      const reader = new FileReader()
      // @ts-ignore
      reader.onload = () => resolve(reader!.result!.split(',')[1])
      reader.readAsDataURL(file)
    })
  }

  static async readBase64AsFile (name: string, content64: string, mime: string): Promise<File> {
    const contentUrl = `data:${mime};base64,${content64}`
    const contentBuffer = await fetch(contentUrl).then(res => res.arrayBuffer())
    return new File([contentBuffer], name, { type: mime })
  }

  static async openFile (file: File) {
    const fileUrl = await Utils.getFileUrl(file)
    window.open(fileUrl, '_blank')
  }

  static async getFileUrl (file: File) {
    return URL.createObjectURL(file)
  }

  static formatPrice (price: Number | number | string): string {
    return price ? Number.parseFloat(price.toString()).toLocaleString('de', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    }) : '0,00'
  }

  static removeEmptyStrings = (form: any) => {
    const variableType = Object.prototype.toString.call(form)
    if (variableType === '[object Array]') {
      return form.filter((element: string) => {
        return element !== ''
      })
    } else if (variableType === '[object Object]') {
      return Object.fromEntries(Object.entries(form).filter(([_, value]) => value !== '')) as RecordOf<string>
    } else { // a primitive (string, number, etc)
      return form
    }
  }

  static getUserAgent () {
    const { browser, os, device, ua } = UAParser()
    return {
      browser: `${browser.name} ${browser.version}`,
      os: `${os.name} ${os.version}`,
      device: (device.type) ? `${device.type} ${device.vendor} ${device.model} ` : 'Desktop',
      userAgent: ua
    }
  }

  static isAzureAgent () {
    for (const value of Object.values(Utils.getUserAgent())) {
      if (value.includes('UNKNOWN') || value.includes('AlwaysOn') || value.includes('Appinsights')) {
        return true
      }
    }
    return false
  }

  static scrollToElement (element: HTMLElement) {
    element && element.scrollIntoView && element.scrollIntoView()
  }

  static joinNotEmpty (array: string[], separator: string) {
    return array.filter(item => item).join(separator)
  }

  static setHtml (html: any, element = document.body) {
    const node = document.createRange().createContextualFragment(html)
    element.innerHTML = ''
    element.appendChild(node)
  }

  static getApisLinksFromText (text: string) {
    const regex = /\[[^()]*?\]\([^)]*?:[^)]*?\|.*?\)/g
    // regex explanation: "[" + {anything except "(" and ")" - as LINK TEXT, lazy} + "]" + "(" + {anything except ")" - as SYSTEM ID, lazy} + ":" {anything except ")" - as LINK TYPE, lazy} + "|"  + {anything - as LINK ID, lazy} + ")"
    const matches = text.match(regex)
    if (!matches || matches.length === 0) {
      return []
    }
    const response = matches.map(match => {
      const regex2 = /\[(.*)\]\((.*):(.*)\|(.*)\)/
      const info = match.match(regex2)
      if (info) {
        return {
          replace: info[0],
          link: info[1],
          system: info[2],
          type: info[3],
          id: info[4]
        }
      }
    })
    return response.filter(x => x)
  }

  static replaceLinksInText (text: string) {
    const regex = /\[.*\]\(.*\)/g
    const matches = text.match(regex)
    if (!matches || matches.length === 0) {
      return text
    }
    matches.map(match => {
      const regex2 = /\[(.*)\]\((.*)\)/
      const info = match.match(regex2)
      if (!info) {
        return
      }
      const link = `<a href="${info[2]}" target="_blank">${info[1]}</a>`
      text = text.replace(info[0], link)
    })
    return text
  }

  static async replaceMarkdown (text: string, references: DI.V13.WithReferenzen, di: DI.DigitalInterface) {
    text = await Utils.replaceMarkdownDocuments(text, references, di)
    text = marked(text, { breaks: true, mangle: false, headerIds: false })
    return Utils.replaceHtmlLink(text)
  }

  static async replaceMarkdownDocuments (text: string, references: DI.V13.WithReferenzen, di: DI.DigitalInterface) {
    const getFile = (link: any) => {
      const id = references.Referenzen.Dokumente.find(({ Dokument }) =>
        (!link.type || Dokument.Hauptkategorie === link.type) &&
        (!link.id || Dokument.Unterkategorie === link.id)
      )?.Dokument.ID
      return id && di.v13.getDatei(id.SystemId, id.DateiId)
    }
    const getUrl = (file: File | undefined) => {
      return file ? Utils.getFileUrl(file) : '/404'
    }
    const links = Utils.getApisLinksFromText(text)
    const files = await Promise.all(links.map(getFile))
    const urls = await Promise.all(files.map(getUrl))
    for (let i = 0; i < links.length; i++) {
      text = text.replace(links[i]!.replace, `[${links[i]!.link}](${urls[i]})`)
    }
    return text
  }

  static replaceHtmlLink (text: string) {
    return text.replace(/<a href=/g, '<a target="_blank" href=')
  }

  static compareIgnoringCase (text: string, compareText: string): boolean {
    return text.localeCompare(compareText, undefined, { sensitivity: 'base' }) === 0
  }

  static getFirst (arr: Array<any>) {
    return arr[0]
  }

  static arraysAreEqual (arr1: Array<unknown>, arr2: Array<unknown>) {
    if (arr1.length !== arr2.length) {
      return false
    } else {
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false
        }
      }
      return true
    }
  }

  static isFormChanged (form1: Object, form2: Object) {
    return JSON.stringify(form1) === JSON.stringify(form2)
  }
  static assign<T> (object1: T, object2: Partial<T>): T {
    const neObj = {}
    return Object.assign(neObj, object1, object2)
  }
  static deepClone<T> (object: T): T {
    return JSON.parse(JSON.stringify(object))
  }

  static adjustCode (code: string) {
    let adjusted = code.toUpperCase()
    if (code.length >= 4 && !code.includes('-')) {
      const a = code.slice(0, 4)
      const b = code.slice(4, 8)
      adjusted = `${a}-${b}`
    }
    return adjusted
  }

  static nl2br (value: string) {
    return value.replace(/\n/g, '<br />')
  }

  static timeDiff (otherTime: string) {
    const date1 = new Date(otherTime)
    const date2 = new Date()
    return date1.getTime() - date2.getTime()
  }

  static getScrambledContract (id: string) {
    const [_, middle] = /\d{3}(\d{6})\d{3}/.exec(id)!
    return id.replace(middle, "X".repeat(6))
  }

  static downloadFile (file: File) {
    const a = document.createElement('a')
    a.href = URL.createObjectURL(file)
    a.download = file.name
    a.click()
  }
}
