import Cache from './cache/Cache'
import type Config from './interceptors/Config'
import Context from './interceptors/Context'
import DigitalInterface from './DigitalInterface'
import HttpClient from './http/Client'
import type Interceptor from './interceptors/Interceptor'
import InterceptorAuthenticator from './interceptors/technical/Authenticator'
import InterceptorBase from './interceptors/technical/Base'
import InterceptorCache from './interceptors/technical/Cache'
import InterceptorDelay from './interceptors/technical/Delay'
import InterceptorRetry from './interceptors/technical/Retry'
import InterceptorStatusCode from './interceptors/technical/StatusCode'
import Request from './Request'
import Response from './Response'
import StatusCodeHandler from './status-code/Handler'
import StatusCodeStrategyContinue from './status-code/StrategyContinue'
import StatusCodeStrategyDelayedProcessing from './status-code/StrategyDelayedProcessing'
import StatusCodeStrategyRetry from './status-code/StrategyRetry'
import StatusCodeStrategyCaptcha from './status-code/StrategyCaptcha'
import StatusCodeStrategyError from './status-code/StrategyError'
import StatusCodeStrategyRefresh from './status-code/StrategyRefresh'
import StorageSession from './storage/StorageSession'
import * as config from './interceptors/technical/config'

/**
 * Client.
 */
export default class Client {
  /**
   * Creates a new client.
   */
  public static create (di: DigitalInterface): Client {
    const client = new Client()
    const httpClient = new HttpClient()
    const cache = new Cache(new StorageSession())
    const statusCodeHandler = new StatusCodeHandler([
      new StatusCodeStrategyContinue(),
      new StatusCodeStrategyDelayedProcessing(),
      new StatusCodeStrategyError(),
      new StatusCodeStrategyRetry(client),
      new StatusCodeStrategyCaptcha(),
      new StatusCodeStrategyRefresh(di)
    ])
    client.attach(new InterceptorBase(httpClient), config.BASE)
    client.attach(new InterceptorCache(cache), config.CACHE)
    client.attach(new InterceptorDelay(), config.DELAY)
    client.attach(new InterceptorRetry(), config.RETRY)
    client.attach(new InterceptorAuthenticator(), config.AUTHENTICATOR)
    client.attach(new InterceptorStatusCode(statusCodeHandler), config.STATUS_CODE)
    return client
  }

  /**
   * Instantiates a new client.
   */
  public constructor () {
    this.interceptors = []
  }

  /**
   * Sends a request and returns the response.
   */
  public async send (request: Request): Promise<Response> {
    const context = this.createContext(request)
    await context.proceed()
    return context.response
  }

  /**
   * Attaches an interceptor.
   */
  public attach (interceptor: Interceptor, config: Config): void {
    this.interceptors.push([config, interceptor])
  }

  /**
   * Detaches an interceptor.
   */
  public detach (id: string): void {
    const index = this.findIndex(id)
    delete this.interceptors[index]
  }

  /**
   * Creates execution context for request.
   */
  protected createContext (request: Request): Context {
    return new Context(request, this.interceptorsExecutable)
  }

  /**
   * Finds interceptor index.
   */
  protected findIndex (id: string): number {
    return this.interceptors.findIndex(item => item !== undefined && item[0].id === id)
  }

  /**
   * List of interceptors to be executed.
   */
  protected get interceptorsExecutable (): Interceptor[] {
    /* eslint-disable standard/array-bracket-even-spacing, no-multi-spaces, spaced-comment */
    return this.interceptors
      .filter(item => item !== undefined)          // skip detached interceptors
      .sort(([a], [b]) => a.priority - b.priority) // sort by priority ascending
      .map(([_, interceptor]) => interceptor)      // return list of interceptors
  }

  /**
   * List of interceptors with configurations.
   */
  protected interceptors: [Config, Interceptor][]
}
