import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'

import VueTrendChart from 'vue-trend-chart'

import CsoApp from '@/components/CsoApp.vue'
import CsoFaq from '@/components/core/business/CsoFaq.vue'
import CsoGridColumn from '@/components/core/composition/CsoGridColumn.vue'
import CsoGridRow from '@/components/core/composition/CsoGridRow.vue'
import CsoGlobalStyles from '@/components/core/composition/CsoGlobalStyles.vue'
import CsoButton from '@/components/core/composition/CsoButton.vue'
import CsoLink from '@/components/core/composition/CsoLink.vue'
import CsoBox from '@/components/core/composition/CsoBox.vue'

import CsoRouter from '@/api/CsoRouter'
import PropertyService from '@/common/PropertyService'
import LoggingService from '@/common/LoggingService'
import messages from '@/i18n'
import ai from '@/api/application-insights'
import di from '@/api/digital-interface'

PropertyService.getProperties(async () => {
  const app = createApp(CsoApp)

  const i18n = createI18n({
    locale: 'de',
    messages,
    warnHtmlInMessage: 'off'
  })

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: CsoRouter.routes as RouteRecordRaw[],
    scrollBehavior () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    linkActiveClass: 'active'
  })

  app.component('cso-faq', CsoFaq)
  app.component('cso-grid-column', CsoGridColumn)
  app.component('cso-grid-row', CsoGridRow)
  app.component('cso-global-styles', CsoGlobalStyles)
  app.component('cso-button', CsoButton)
  app.component('cso-link', CsoLink)
  app.component('cso-box', CsoBox)

  app.use(i18n)
  app.use(router)
  app.use(VueTrendChart)

  ai.config.instrumentationKey = PropertyService.aiConnectionString
  di.data.subscriptionKey = PropertyService.diSubscriptionKey
  di.data.host = PropertyService.diHost
  di.data.stateSince = PropertyService.diStateSince ? new Date(PropertyService.diStateSince) : undefined

  CsoRouter.setRouterObject(router)
  CsoRouter.setDiObject(di)

  router.beforeResolve((to) => CsoRouter.checkIfLoginTemporary())
  router.beforeResolve((to) => CsoRouter.checkIfLoginRequired(to))
  router.beforeResolve((to) => CsoRouter.checkIfLoginForbidden(to))

  LoggingService.init(ai, PropertyService)
  LoggingService.log(LoggingService.eventTypes.app, 'start')

  app.mount('#cso-app')
})
