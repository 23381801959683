import DigitalInterface from '../DigitalInterface'
import { type HttpMethod } from '../http/Method'
import { type Send, type Version } from './model-shared'

/**
 * Adapter between generic api class and digital interface class.
 */
export default class Adapter {
  /**
   * Instantiates a new adapter between generic api class and digital interface class.
   */
  public constructor (di: DigitalInterface, version: Version) {
    this.di = di
    this.version = version
  }

  /**
   * Returns send function to be called by api.
   */
  public getSendFunction (): Send {
    return this.send.bind(this)
  }

  /**
   * Adapts api call to digital interface call.
   */
  protected async send<X, Y> (method: HttpMethod, endpoint: string, params?: X): Promise<Y> {
    const response = await this.di.send<X, Y>({
      version: this.version,
      method,
      endpoint,
      body: {
        Request: params
      }
    })
    return response.body && response.body.Result
  }

  /**
   * Adaptee.
   */
  protected di: DigitalInterface

  /**
   * Api version.
   */
  protected version: Version
}
