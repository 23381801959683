import Context from '../../client/interceptors/Context'
import type Interceptor from '../../client/interceptors/Interceptor'
import Store from '@/store'
// eslint-disable-next-line no-unused-vars
import { Subscription } from 'rxjs'

/**
 * Captcha interceptor.
 *
 * Handles Captcha.
 */
export default class Captcha implements Interceptor {
  /**
   * Instantiates a new Captcha interceptor.
   */
  public constructor (store: typeof Store) {
    this.store = store
  }
  /**
   * @inheritdoc
   */
  public async intercept (context: Context): Promise<void> {
    await context.proceed()
    if (context.response?.body?.Meldungen[0]?.Code === 'ERROR_CAPTCHA_BENOETIGT') { // there's captcha needed
      const newPromise = new Promise((resolve: Function, reject: Function) => {
        this.originalResolve = resolve
        this.originalReject = reject
      }) as Promise<void>

      this.originalContext = context
      if (this.captchaToken) { // we already have token, let's retry the request
        this.retry(this.captchaToken)
      } else { // there's no token -> put to the store information that the dialog needs to open
        this.store.setCaptcha(true, context.response?.body?.Meldungen[0]?.FachlicheBeschreibung, context.response?.body?.Meldungen[0]?.TechnischeBeschreibung)
        this.subscription = this.store.subscribe(state => {
          if (state.captchaToken !== '') {
            this.handleCaptchaResponse(state.captchaToken)
          }
        })
      }
      return newPromise
    }
  }

  private async handleCaptchaResponse (token: string) {
    this.captchaToken = token
    this.subscription.unsubscribe()
    this.store.setCaptchaToken('')
    this.retry(token)
  }

  /**
   * Retries a request and resolves/rejects as the original request
   */
  protected async retry (token: string): Promise<void> {
    const context = this.originalContext
    // if we retry the call which already have recaptcha token -> reject it
    if (context.request.body?.RequestKeyValues?.FriendlyCaptchaResponse && this.originalReject) {
      this.originalReject()
      return
    }
    try {
      context.request.body.RequestKeyValues = context.request.body.RequestKeyValues || {}
      context.request.body.RequestKeyValues.FriendlyCaptchaResponse = token
      context.request.doRetry()
      await this.intercept(context)
      this.originalResolve()
    } catch (error) {
      this.originalReject(error)
    }
  }

  private captchaToken!: string

  private originalResolve!: Function

  private originalReject!: Function

  private originalContext!: Context

  private subscription!: Subscription

  protected store: typeof Store
}
