import ErrorResponse from '../error/ErrorResponse'
import Response from '../Response'
import type Strategy from './Strategy'
import { type StrategyType } from './StrategyType'

/**
 * Strategy captcha.
 *
 * Handles response by checking if there's captcha needed in response, if so - passes to interceptor. Otherwise, throws an error.
 */
export default class StrategyCaptcha implements Strategy {
  /**
   * @inheritdoc
   */
  public type: StrategyType = 'CAPTCHA'

  /**
   * @inheritdoc
   */
  public async handle (response: Response): Promise<Response> {
    if (response?.body?.Meldungen[0]?.Code === 'ERROR_CAPTCHA_BENOETIGT') {
      return response
    } else {
      throw new ErrorResponse(response)
    }
  }
}
