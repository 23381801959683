import LoggingService from '@/common/LoggingService'
export default class Session {
  // Session duration since last user activity in minutes.
  static duration = 30

  // Function to be executed when session ends.
  static onEnd

  static isStarted () {
    return Session.endDate !== null
  }

  static isValid () {
    return new Date() <= Session.endDate
  }

  static isActive () {
    return Session.isStarted() && Session.isValid()
  }

  static isStale () {
    return Session.isStarted() && !Session.isValid()
  }

  static start () {
    LoggingService.log(LoggingService.eventTypes.session, 'Start')
    Session.refreshMarker()
  }

  static prolong () {
    Session.refreshMarker()
  }

  static stop () {
    LoggingService.log(LoggingService.eventTypes.session, 'Stop')
    Session.clearMarker()
  }

  static refreshMarker () {
    Session.endDate = new Date(Date.now() + Session.duration * 60 * 1000)
  }

  static clearMarker () {
    Session.endDate = null
  }

  static periodicallyCheckValidity () {
    setInterval(() => {
      if (Session.isStale()) {
        LoggingService.log(LoggingService.eventTypes.session, 'Is staled')
        Session.onEnd()
      }
    }, 1000)
  }

  static prolongOnUserActivity () {
    const activityEvents = ['mousedown', 'keydown', 'touchstart']
    for (const event of activityEvents) {
      document.addEventListener(event, () => {
        if (Session.isActive()) {
          Session.prolong()
        }
      }, true)
    }
  }

  static setupGenericServices (onEnd) {
    Session.onEnd = onEnd
    Session.periodicallyCheckValidity()
    Session.prolongOnUserActivity()
  }

  static get endDate () {
    const date = window.localStorage.getItem('cso.session_end')
    return date === null ? null : new Date(date)
  }

  static set endDate (date) {
    if (date === null) {
      window.localStorage.removeItem('cso.session_end')
    } else {
      window.localStorage.setItem('cso.session_end', date.toISOString())
    }
  }
}
