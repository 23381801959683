import type Args from './Args'
import Adapter from './core/Adapter'
import Api13 from './core/1.3/Api'
import Config from './Config'
import Client from './Client'
import Data from './Data'
import type EndpointConfig from './core/Config'
import Helper13 from './helpers/1.3/Api'
import type Interceptor from './interceptors/Interceptor'
import type InterceptorConfig from './interceptors/Config'
import Request from './Request'
import Response from './Response'
import Token from './authentication/Token'
import * as config13 from './core/1.3/config'
import { type Version } from './core/model-shared'
import { compileDate } from './utility'

/**
 * Main class.
 */
export default class DigitalInterface {
  /**
   * Data source.
   */
  public data: Data

  /**
   * Logging Service.
   */
  public loggingService: any

  /**
   * Convenience methods for calling digital interface version 1.3.
   *
   * @example
   * // Get user token.
   * const token = await di.v13.getTokenUser('{username}', '{password}')
   */
  public v13: Helper13

  /**
   * Api for digital interface version 1.3.
   */
  public api13: Api13

  /**
   * Instantiates a new digital interface client.
   */
  public constructor (config: Config, loggingService: any) {
    Config.validate(config)
    const adapter13 = new Adapter(this, '1.3')
    this.data = Data.createFromConfig(config)
    this.v13 = new Helper13(this)
    this.api13 = new Api13(adapter13.getSendFunction())
    this.client = Client.create(this)
    this.loggingService = loggingService
  }

  /**
   * Default token used for request.
   */
  public get token (): Token | undefined {
    return this.data.token
  }

  public set token (token: Token | undefined) {
    if (token) {
      this.loggingService.log(this.loggingService.eventTypes.token, 'Setting new Token', {
        role: token.role,
        username: token.username
      })
    } else {
      this.loggingService.log(this.loggingService.eventTypes.token, 'Setting new udefined Token')
    }
    this.data.token = token
  }

  /**
   * Sends a request to digital interface and returns the response.
   */
  public async send<X, Y> (args: Args<X>): Promise<Response<X, Y>> {
    const request = await this.createRequest<X, Y>(args)
    return this.sendRequest(request)
  }

  /**
   * Creates a request to digital interface.
   */
  public async createRequest<X, Y> (args: Args<X>): Promise<Request<X, Y>> {
    const configuration: Record<Version, EndpointConfig[]> = {
      '1.3': Object.values(config13)
    }
    const config = configuration[args.version].find(c => c.url === args.endpoint)
    if (!config) {
      throw new Error(`Missing configuration for endpoint ${args.endpoint}`)
    }
    const tokenType = 'token' in args ? 'ARGS' : !config.anonymous ? 'DEFAULT' : 'NONE'
    const useStateSince = this.data.stateSince && config.stateSince
    return new Request(
      args.version,
      args.method,
      args.endpoint,
      {
        TransaktionsId: Data.createTransactionId(),
        Client: this.data.client,
        Sprache: this.data.language,
        Mandant: this.data.principal,
        ParentLogId: this.data.parentLogId || '',
        ReferenzId: this.data.referenceId || '',
        Timeout: config.diTimeout,
        StandVom: useStateSince ? compileDate(this.data.stateSince!) : undefined,
        ...(args.body || {})
      },
      {},
      this.data.host,
      0,
      this.data.subscriptionKey,
      tokenType === 'ARGS' ? args.token : tokenType === 'DEFAULT' ? this.token : undefined,
      config.statusCodeMap,
      config.cache || false,
      config.timeout ? config.timeout * 1000 : 0
    )
  }

  /**
   * Sends a request to digital interface.
   */
  public async sendRequest<X, Y> (request: Request<X, Y>): Promise<Response<X, Y>> {
    this.loggingService.log(this.loggingService.eventTypes.di, 'Send Request', {
      endpoint: request.endpoint,
      version: request.version,
      method: request.method,
      parentLogId: request.body.ParentLogId
    })
    return this.client.send(request) as Promise<Response<X, Y>>
  }

  /**
   * Attaches an interceptor.
   */
  public attachInterceptor (interceptor: Interceptor, config: InterceptorConfig): void {
    this.client.attach(interceptor, config)
  }

  /**
   * Detaches an interceptor.
   */
  public detachInterceptor (id: string): void {
    this.client.detach(id)
  }

  /**
   * Manually reset parent log id.
   */
  public resetParentLogId (): void {
    this.data.resetParentLogId()
  }

  /**
   * Underlying client that sends a request and returns the response.
   */
  protected client: Client
}
