import { type Send } from '../model-shared'

import {
  Abschlag,
  Account,
  Adressen,
  Arbeitsauftraege,
  Auftraege,
  Chat,
  Dienstleister,
  Filterwerte,
  Geschaeftspartner,
  Gutschein,
  Inhalte,
  Kontakt,
  Logging,
  Nachricht,
  Netzentgelte,
  Opts,
  Produkt,
  Prozesse,
  Report,
  Validierung,
  Vertrag,
  Vorversorger,
  Zaehlerstand,
  Zahlung
} from './model'

export default class Api {
  constructor (send: Send) {
    this.send = send
  }
  /** @de Abschlag */
  get abschlag () {
    const send = this.send
    return {
      /** @de GetAbschlaege */
      async getAbschlaege (
        request: Abschlag.GetAbschlaege.Request
      ): Promise<Abschlag.GetAbschlaege.Response> {
        return send('POST', '/Abschlag/GetAbschlaege', request) as any
      },
      /** @de UpdateAbschlag */
      async updateAbschlag (
        request: Abschlag.UpdateAbschlag.Request
      ): Promise<Abschlag.UpdateAbschlag.Response> {
        return send('POST', '/Abschlag/UpdateAbschlag', request) as any
      }
    }
  }
  /** @de Account */
  get account () {
    const send = this.send
    return {
      /** @de Create2FAKommunikation */
      async create2FAKommunikation (
        request: Account.Create2FAKommunikation.Request
      ): Promise<Account.Create2FAKommunikation.Response> {
        return send('POST', '/Account/Create2FAKommunikation', request) as any
      },
      /** @de CreateAccount */
      async createAccount (
        request: Account.CreateAccount.Request
      ): Promise<Account.CreateAccount.Response> {
        return send('POST', '/Account/CreateAccount', request) as any
      },
      /** @de CreateAccountAktivierung */
      async createAccountAktivierung (
        request: Account.CreateAccountAktivierung.Request
      ): Promise<Account.CreateAccountAktivierung.Response> {
        return send('POST', '/Account/CreateAccountAktivierung', request) as any
      },
      /** @de CreateAccountGPZuordnung */
      async createAccountGPZuordnung (
        request: Account.CreateAccountGPZuordnung.Request
      ): Promise<void> {
        return send('POST', '/Account/CreateAccountGPZuordnung', request) as any
      },
      /** @de CreateTokenUebergabe */
      async createTokenUebergabe (
        request: Account.CreateTokenUebergabe.Request
      ): Promise<Account.CreateTokenUebergabe.Response> {
        return send('POST', '/Account/CreateTokenUebergabe', request) as any
      },
      /** @de DeleteAccount */
      async deleteAccount (
        request: Account.DeleteAccount.Request
      ): Promise<Account.DeleteAccount.Response> {
        return send('POST', '/Account/DeleteAccount', request) as any
      },
      /** @de DeleteAccountGPZuordnung */
      async deleteAccountGPZuordnung (
        request: Account.DeleteAccountGPZuordnung.Request
      ): Promise<void> {
        return send('POST', '/Account/DeleteAccountGPZuordnung', request) as any
      },
      /** @de DeleteToken */
      async deleteToken (request: Account.DeleteToken.Request): Promise<void> {
        return send('POST', '/Account/DeleteToken', request) as any
      },
      /** @de Get2FAKommunikation */
      async get2FAKommunikation (
        request: Account.Get2FAKommunikation.Request
      ): Promise<Account.Get2FAKommunikation.Response> {
        return send('POST', '/Account/Get2FAKommunikation', request) as any
      },
      /** @de GetAccount */
      async getAccount (
        request: Account.GetAccount.Request
      ): Promise<Account.GetAccount.Response> {
        return send('POST', '/Account/GetAccount', request) as any
      },
      /** @de GetAccountliste */
      async getAccountliste (
        request: Account.GetAccountliste.Request
      ): Promise<Account.GetAccountliste.Response> {
        return send('POST', '/Account/GetAccountliste', request) as any
      },
      /** @de GetTempPasswort */
      async getTempPasswort (
        request: Account.GetTempPasswort.Request
      ): Promise<Account.GetTempPasswort.Response> {
        return send('POST', '/Account/GetTempPasswort', request) as any
      },
      /** @de GetTokenAgent */
      async getTokenAgent (
        request: Account.GetTokenAgent.Request
      ): Promise<Account.GetTokenAgent.Response> {
        return send('POST', '/Account/GetTokenAgent', request) as any
      },
      /** @de GetTokenAllgemein */
      async getTokenAllgemein (
        request: Account.GetTokenAllgemein.Request
      ): Promise<Account.GetTokenAllgemein.Response> {
        return send('POST', '/Account/GetTokenAllgemein', request) as any
      },
      /** @de GetTokenAnonym */
      async getTokenAnonym (
        request: Account.GetTokenAnonym.Request
      ): Promise<Account.GetTokenAnonym.Response> {
        return send('POST', '/Account/GetTokenAnonym', request) as any
      },
      /** @de GetTokenBusiness */
      async getTokenBusiness (
        request: Account.GetTokenBusiness.Request
      ): Promise<Account.GetTokenBusiness.Response> {
        return send('POST', '/Account/GetTokenBusiness', request) as any
      },
      /** @de GetTokenKerberos */
      async getTokenKerberos (
        request: Account.GetTokenKerberos.Request
      ): Promise<Account.GetTokenKerberos.Response> {
        return send('POST', '/Account/GetTokenKerberos', request) as any
      },
      /** @de GetTokenRefresh */
      async getTokenRefresh (
        request: Account.GetTokenRefresh.Request
      ): Promise<Account.GetTokenRefresh.Response> {
        return send('POST', '/Account/GetTokenRefresh', request) as any
      },
      /** @de GetTokenSystem */
      async getTokenSystem (
        request: Account.GetTokenSystem.Request
      ): Promise<Account.GetTokenSystem.Response> {
        return send('POST', '/Account/GetTokenSystem', request) as any
      },
      /** @de GetTokenUser */
      async getTokenUser (
        request: Account.GetTokenUser.Request
      ): Promise<Account.GetTokenUser.Response> {
        return send('POST', '/Account/GetTokenUser', request) as any
      },
      /** @de SetPasswort */
      async setPasswort (request: Account.SetPasswort.Request): Promise<void> {
        return send('POST', '/Account/SetPasswort', request) as any
      },
      /** @de Update2FAKommunikation */
      async update2FAKommunikation (
        request: Account.Update2FAKommunikation.Request
      ): Promise<void> {
        return send('POST', '/Account/Update2FAKommunikation', request) as any
      },
      /** @de UpdateAccount */
      async updateAccount (
        request: Account.UpdateAccount.Request
      ): Promise<void> {
        return send('POST', '/Account/UpdateAccount', request) as any
      },
      /** @de UpdatePasswort */
      async updatePasswort (
        request: Account.UpdatePasswort.Request
      ): Promise<void> {
        return send('POST', '/Account/UpdatePasswort', request) as any
      }
    }
  }
  /** @de Adressen */
  get adressen () {
    const send = this.send
    return {
      /** @de GetAdresse */
      async getAdresse (
        request: Adressen.GetAdresse.Request
      ): Promise<Adressen.GetAdresse.Response> {
        return send('POST', '/Adressen/GetAdresse', request) as any
      },
      /** @de GetOrte */
      async getOrte (
        request: Adressen.GetOrte.Request
      ): Promise<Adressen.GetOrte.Response> {
        return send('POST', '/Adressen/GetOrte', request) as any
      }
    }
  }
  /** @de Arbeitsauftraege */
  get arbeitsauftraege () {
    const send = this.send
    return {
      /** @de CreateAngebot */
      async createAngebot (
        request: Arbeitsauftraege.CreateAngebot.Request
      ): Promise<Arbeitsauftraege.CreateAngebot.Response> {
        return send('POST', '/Arbeitsauftraege/CreateAngebot', request) as any
      },
      /** @de CreateArbeitsauftrag */
      async createArbeitsauftrag (
        request: Arbeitsauftraege.CreateArbeitsauftrag.Request
      ): Promise<Arbeitsauftraege.CreateArbeitsauftrag.Response> {
        return send(
          'POST',
          '/Arbeitsauftraege/CreateArbeitsauftrag',
          request
        ) as any
      },
      /** @de CreateArbeitsauftragStatus */
      async createArbeitsauftragStatus (
        request: Arbeitsauftraege.CreateArbeitsauftragStatus.Request
      ): Promise<void> {
        return send(
          'POST',
          '/Arbeitsauftraege/CreateArbeitsauftragStatus',
          request
        ) as any
      },
      /** @de GetAngebot */
      async getAngebot (
        request: Arbeitsauftraege.GetAngebot.Request
      ): Promise<Arbeitsauftraege.GetAngebot.Response> {
        return send('POST', '/Arbeitsauftraege/GetAngebot', request) as any
      },
      /** @de GetArbeitsauftrag */
      async getArbeitsauftrag (
        request: Arbeitsauftraege.GetArbeitsauftrag.Request
      ): Promise<Arbeitsauftraege.GetArbeitsauftrag.Response> {
        return send(
          'POST',
          '/Arbeitsauftraege/GetArbeitsauftrag',
          request
        ) as any
      },
      /** @de UpdateAngebot */
      async updateAngebot (
        request: Arbeitsauftraege.UpdateAngebot.Request
      ): Promise<void> {
        return send('POST', '/Arbeitsauftraege/UpdateAngebot', request) as any
      }
    }
  }
  /** @de Auftraege */
  get auftraege () {
    const send = this.send
    return {
      /** @de CreateNeuauftrag */
      async createNeuauftrag (
        request: Auftraege.CreateNeuauftrag.Request
      ): Promise<Auftraege.CreateNeuauftrag.Response> {
        return send('POST', '/Auftraege/CreateNeuauftrag', request) as any
      },
      /** @de CreateProduktwechsel */
      async createProduktwechsel (
        request: Auftraege.CreateProduktwechsel.Request
      ): Promise<Auftraege.CreateProduktwechsel.Response> {
        return send('POST', '/Auftraege/CreateProduktwechsel', request) as any
      },
      /** @de UpdateEinzug */
      async updateEinzug (
        request: Auftraege.UpdateEinzug.Request
      ): Promise<void> {
        return send('POST', '/Auftraege/UpdateEinzug', request) as any
      }
    }
  }
  /** @de Chat */
  get chat () {
    const send = this.send
    return {
      /** @de AddChatUser */
      async addChatUser (request: Chat.AddChatUser.Request): Promise<void> {
        return send('POST', '/Chat/AddChatUser', request) as any
      },
      /** @de CreateChat */
      async createChat (
        request: Chat.CreateChat.Request
      ): Promise<Chat.CreateChat.Response> {
        return send('POST', '/Chat/CreateChat', request) as any
      },
      /** @de GetChatNachrichten */
      async getChatNachrichten (
        request: Chat.GetChatNachrichten.Request
      ): Promise<Chat.GetChatNachrichten.Response> {
        return send('POST', '/Chat/GetChatNachrichten', request) as any
      },
      /** @de GetChatUpdates */
      async getChatUpdates (
        request: Chat.GetChatUpdates.Request
      ): Promise<Chat.GetChatUpdates.Response> {
        return send('POST', '/Chat/GetChatUpdates', request) as any
      },
      /** @de GetChats */
      async getChats (
        request: Chat.GetChats.Request
      ): Promise<Chat.GetChats.Response> {
        return send('POST', '/Chat/GetChats', request) as any
      },
      /** @de JoinChat */
      async joinChat (
        request: Chat.JoinChat.Request
      ): Promise<Chat.JoinChat.Response> {
        return send('POST', '/Chat/JoinChat', request) as any
      },
      /** @de LeaveChat */
      async leaveChat (request: Chat.LeaveChat.Request): Promise<void> {
        return send('POST', '/Chat/LeaveChat', request) as any
      },
      /** @de SendChatNachricht */
      async sendChatNachricht (
        request: Chat.SendChatNachricht.Request
      ): Promise<Chat.SendChatNachricht.Response> {
        return send('POST', '/Chat/SendChatNachricht', request) as any
      },
      /** @de SetChatNachrichtGelesen */
      async setChatNachrichtGelesen (
        request: Chat.SetChatNachrichtGelesen.Request
      ): Promise<void> {
        return send('POST', '/Chat/SetChatNachrichtGelesen', request) as any
      },
      /** @de UpdateChatName */
      async updateChatName (
        request: Chat.UpdateChatName.Request
      ): Promise<void> {
        return send('POST', '/Chat/UpdateChatName', request) as any
      }
    }
  }
  /** @de Dienstleister */
  get dienstleister () {
    const send = this.send
    return {
      /** @de GetDienstleisterInformation */
      async getDienstleisterInformation (
        request: Dienstleister.GetDienstleisterInformation.Request
      ): Promise<Dienstleister.GetDienstleisterInformation.Response> {
        return send(
          'POST',
          '/Dienstleister/GetDienstleisterInformation',
          request
        ) as any
      },
      /** @de GetDienstleistung */
      async getDienstleistung (
        request: Dienstleister.GetDienstleistung.Request
      ): Promise<Dienstleister.GetDienstleistung.Response> {
        return send('POST', '/Dienstleister/GetDienstleistung', request) as any
      },
      /** @de UpdateDienstleistung */
      async updateDienstleistung (
        request: Dienstleister.UpdateDienstleistung.Request
      ): Promise<Dienstleister.UpdateDienstleistung.Response> {
        return send(
          'POST',
          '/Dienstleister/UpdateDienstleistung',
          request
        ) as any
      }
    }
  }
  /** @de Filterwerte */
  get filterwerte () {
    const send = this.send
    return {
      /** @de CheckFilterwert */
      async checkFilterwert (
        request: Filterwerte.CheckFilterwert.Request
      ): Promise<Filterwerte.CheckFilterwert.Response> {
        return send('POST', '/Filterwerte/CheckFilterwert', request) as any
      },
      /** @de CreateFilterwert */
      async createFilterwert (
        request: Filterwerte.CreateFilterwert.Request
      ): Promise<void> {
        return send('POST', '/Filterwerte/CreateFilterwert', request) as any
      },
      /** @de DeleteFilterwert */
      async deleteFilterwert (
        request: Filterwerte.DeleteFilterwert.Request
      ): Promise<void> {
        return send('POST', '/Filterwerte/DeleteFilterwert', request) as any
      }
    }
  }
  /** @de Geschaeftspartner */
  get geschaeftspartner () {
    const send = this.send
    return {
      /** @de CreateGP */
      async createGP (
        request: Geschaeftspartner.CreateGP.Request
      ): Promise<Geschaeftspartner.CreateGP.Response> {
        return send('POST', '/Geschaeftspartner/CreateGP', request) as any
      }
    }
  }
  /** @de Gutschein */
  get gutschein () {
    const send = this.send
    return {
      /** @de CheckGutscheinCode */
      async checkGutscheinCode (
        request: Gutschein.CheckGutscheinCode.Request
      ): Promise<Gutschein.CheckGutscheinCode.Response> {
        return send('POST', '/Gutschein/CheckGutscheinCode', request) as any
      },
      /** @de GetGutscheinCode */
      async getGutscheinCode (
        request: Gutschein.GetGutscheinCode.Request
      ): Promise<Gutschein.GetGutscheinCode.Response> {
        return send('POST', '/Gutschein/GetGutscheinCode', request) as any
      }
    }
  }
  /** @de Inhalte */
  get inhalte () {
    const send = this.send
    return {
      /** @de CheckDokument */
      async checkDokument (
        request: Inhalte.CheckDokument.Request
      ): Promise<Inhalte.CheckDokument.Response> {
        return send('POST', '/Inhalte/CheckDokument', request) as any
      },
      /** @de CreateDateiArchivierung */
      async createDateiArchivierung (
        request: Inhalte.CreateDateiArchivierung.Request
      ): Promise<Inhalte.CreateDateiArchivierung.Response> {
        return send('POST', '/Inhalte/CreateDateiArchivierung', request) as any
      },
      /** @de CreateDokument */
      async createDokument (
        request: Inhalte.CreateDokument.Request
      ): Promise<Inhalte.CreateDokument.Response> {
        return send('POST', '/Inhalte/CreateDokument', request) as any
      },
      /** @de CreateKundendokument */
      async createKundendokument (
        request: Inhalte.CreateKundendokument.Request
      ): Promise<Inhalte.CreateKundendokument.Response> {
        return send('POST', '/Inhalte/CreateKundendokument', request) as any
      },
      /** @de GetDateien */
      async getDateien (
        request: Inhalte.GetDateien.Request
      ): Promise<Inhalte.GetDateien.Response> {
        return send('POST', '/Inhalte/GetDateien', request) as any
      },
      /** @de GetFelder */
      async getFelder (
        request: Inhalte.GetFelder.Request
      ): Promise<Inhalte.GetFelder.Response> {
        return send('POST', '/Inhalte/GetFelder', request) as any
      },
      /** @de GetInhalt */
      async getInhalt (
        request: Inhalte.GetInhalt.Request
      ): Promise<Inhalte.GetInhalt.Response> {
        return send('POST', '/Inhalte/GetInhalt', request) as any
      },
      /** @de GetKundendokumente */
      async getKundendokumente (
        request: Inhalte.GetKundendokumente.Request
      ): Promise<Inhalte.GetKundendokumente.Response> {
        return send('POST', '/Inhalte/GetKundendokumente', request) as any
      },
      /** @de GetMarktpartnerdaten */
      async getMarktpartnerdaten (
        request: Inhalte.GetMarktpartnerdaten.Request
      ): Promise<Inhalte.GetMarktpartnerdaten.Response> {
        return send('POST', '/Inhalte/GetMarktpartnerdaten', request) as any
      }
    }
  }
  /** @de Kontakt */
  get kontakt () {
    const send = this.send
    return {
      /** @de CreateKontaktnachricht */
      async createKontaktnachricht (
        request: Kontakt.CreateKontaktnachricht.Request
      ): Promise<void> {
        return send('POST', '/Kontakt/CreateKontaktnachricht', request) as any
      }
    }
  }
  /** @de Logging */
  get logging () {
    const send = this.send
    return {
      /** @de CreateLogItems */
      async createLogItems (
        request: Logging.CreateLogItems.Request
      ): Promise<void> {
        return send('POST', '/Logging/CreateLogItems', request) as any
      },
      /** @de CreateRequestLogItems */
      async createRequestLogItems (
        request: Logging.CreateRequestLogItems.Request
      ): Promise<void> {
        return send('POST', '/Logging/CreateRequestLogItems', request) as any
      }
    }
  }
  /** @de Nachricht */
  get nachricht () {
    const send = this.send
    return {
      /** @de ClearNachrichtenArchivQueue */
      async clearNachrichtenArchivQueue (
        request: Nachricht.ClearNachrichtenArchivQueue.Request
      ): Promise<Nachricht.ClearNachrichtenArchivQueue.Response> {
        return send(
          'POST',
          '/Nachricht/ClearNachrichtenArchivQueue',
          request
        ) as any
      },
      /** @de ClearNachrichtenVersandQueue */
      async clearNachrichtenVersandQueue (
        request: Nachricht.ClearNachrichtenVersandQueue.Request
      ): Promise<Nachricht.ClearNachrichtenVersandQueue.Response> {
        return send(
          'POST',
          '/Nachricht/ClearNachrichtenVersandQueue',
          request
        ) as any
      },
      /** @de CreateKommunikation */
      async createKommunikation (
        request: Nachricht.CreateKommunikation.Request
      ): Promise<void> {
        return send('POST', '/Nachricht/CreateKommunikation', request) as any
      },
      /** @de CreateNachricht */
      async createNachricht (
        request: Nachricht.CreateNachricht.Request
      ): Promise<Nachricht.CreateNachricht.Response> {
        return send('POST', '/Nachricht/CreateNachricht', request) as any
      },
      /** @de GetArchivNachrichten */
      async getArchivNachrichten (
        request: Nachricht.GetArchivNachrichten.Request
      ): Promise<Nachricht.GetArchivNachrichten.Response> {
        return send('POST', '/Nachricht/GetArchivNachrichten', request) as any
      },
      /** @de GetNachricht */
      async getNachricht (
        request: Nachricht.GetNachricht.Request
      ): Promise<Nachricht.GetNachricht.Response> {
        return send('POST', '/Nachricht/GetNachricht', request) as any
      },
      /** @de SetNachrichtenToVersandQueue */
      async setNachrichtenToVersandQueue (
        request: Nachricht.SetNachrichtenToVersandQueue.Request
      ): Promise<Nachricht.SetNachrichtenToVersandQueue.Response> {
        return send(
          'POST',
          '/Nachricht/SetNachrichtenToVersandQueue',
          request
        ) as any
      },
      /** @de UpdateNachricht */
      async updateNachricht (
        request: Nachricht.UpdateNachricht.Request
      ): Promise<Nachricht.UpdateNachricht.Response> {
        return send('POST', '/Nachricht/UpdateNachricht', request) as any
      },
      /** @de UpdateNachrichtenStatus */
      async updateNachrichtenStatus (
        request: Nachricht.UpdateNachrichtenStatus.Request
      ): Promise<Nachricht.UpdateNachrichtenStatus.Response> {
        return send(
          'POST',
          '/Nachricht/UpdateNachrichtenStatus',
          request
        ) as any
      },
      /** @de UpdatePushregistrierung */
      async updatePushregistrierung (
        request: Nachricht.UpdatePushregistrierung.Request
      ): Promise<void> {
        return send(
          'POST',
          '/Nachricht/UpdatePushregistrierung',
          request
        ) as any
      }
    }
  }
  /** @de Netzentgelte */
  get netzentgelte () {
    const send = this.send
    return {
      /** @de GetNNEGas */
      async getNNEGas (
        request: Netzentgelte.GetNNEGas.Request
      ): Promise<Netzentgelte.GetNNEGas.Response> {
        return send('POST', '/Netzentgelte/GetNNEGas', request) as any
      },
      /** @de GetNNEGasDetails */
      async getNNEGasDetails (
        request: Netzentgelte.GetNNEGasDetails.Request
      ): Promise<Netzentgelte.GetNNEGasDetails.Response> {
        return send('POST', '/Netzentgelte/GetNNEGasDetails', request) as any
      },
      /** @de GetNNEStrom */
      async getNNEStrom (
        request: Netzentgelte.GetNNEStrom.Request
      ): Promise<Netzentgelte.GetNNEStrom.Response> {
        return send('POST', '/Netzentgelte/GetNNEStrom', request) as any
      },
      /** @de GetNNEStromDetails */
      async getNNEStromDetails (
        request: Netzentgelte.GetNNEStromDetails.Request
      ): Promise<Netzentgelte.GetNNEStromDetails.Response> {
        return send('POST', '/Netzentgelte/GetNNEStromDetails', request) as any
      },
      /** @de GetNetze */
      async getNetze (
        request: Netzentgelte.GetNetze.Request
      ): Promise<Netzentgelte.GetNetze.Response> {
        return send('POST', '/Netzentgelte/GetNetze', request) as any
      },
      /** @de GetZaehlerkosten */
      async getZaehlerkosten (
        request: Netzentgelte.GetZaehlerkosten.Request
      ): Promise<Netzentgelte.GetZaehlerkosten.Response> {
        return send('POST', '/Netzentgelte/GetZaehlerkosten', request) as any
      }
    }
  }
  /** @de Opts */
  get opts () {
    const send = this.send
    return {
      /** @de GetOpts */
      async getOpts (
        request: Opts.GetOpts.Request
      ): Promise<Opts.GetOpts.Response> {
        return send('POST', '/Opts/GetOpts', request) as any
      },
      /** @de UpdateOpts */
      async updateOpts (
        request: Opts.UpdateOpts.Request
      ): Promise<Opts.UpdateOpts.Response> {
        return send('POST', '/Opts/UpdateOpts', request) as any
      }
    }
  }
  /** @de Produkt */
  get produkt () {
    const send = this.send
    return {
      /** @de CheckProdukt */
      async checkProdukt (
        request: Produkt.CheckProdukt.Request
      ): Promise<Produkt.CheckProdukt.Response> {
        return send('POST', '/Produkt/CheckProdukt', request) as any
      },
      /** @de GetBestandsprodukt */
      async getBestandsprodukt (
        request: Produkt.GetBestandsprodukt.Request
      ): Promise<Produkt.GetBestandsprodukt.Response> {
        return send('POST', '/Produkt/GetBestandsprodukt', request) as any
      },
      /** @de GetFremdtarife */
      async getFremdtarife (
        request: Produkt.GetFremdtarife.Request
      ): Promise<Produkt.GetFremdtarife.Response> {
        return send('POST', '/Produkt/GetFremdtarife', request) as any
      },
      /** @de GetKundenkampagnen */
      async getKundenkampagnen (
        request: Produkt.GetKundenkampagnen.Request
      ): Promise<Produkt.GetKundenkampagnen.Response> {
        return send('POST', '/Produkt/GetKundenkampagnen', request) as any
      },
      /** @de GetLieferbeginn */
      async getLieferbeginn (
        request: Produkt.GetLieferbeginn.Request
      ): Promise<Produkt.GetLieferbeginn.Response> {
        return send('POST', '/Produkt/GetLieferbeginn', request) as any
      },
      /** @de GetPreisstellung */
      async getPreisstellung (
        request: Produkt.GetPreisstellung.Request
      ): Promise<Produkt.GetPreisstellung.Response> {
        return send('POST', '/Produkt/GetPreisstellung', request) as any
      },
      /** @de GetProduktdatenByCode */
      async getProduktdatenByCode (
        request: Produkt.GetProduktdatenByCode.Request
      ): Promise<Produkt.GetProduktdatenByCode.Response> {
        return send('POST', '/Produkt/GetProduktdatenByCode', request) as any
      },
      /** @de GetProduktdatenByCodeKosten */
      async getProduktdatenByCodeKosten (
        request: Produkt.GetProduktdatenByCodeKosten.Request
      ): Promise<Produkt.GetProduktdatenByCodeKosten.Response> {
        return send(
          'POST',
          '/Produkt/GetProduktdatenByCodeKosten',
          request
        ) as any
      },
      /** @de GetProdukte */
      async getProdukte (
        request: Produkt.GetProdukte.Request
      ): Promise<Produkt.GetProdukte.Response> {
        return send('POST', '/Produkt/GetProdukte', request) as any
      },
      /** @de GetProduktinformation */
      async getProduktinformation (
        request: Produkt.GetProduktinformation.Request
      ): Promise<Produkt.GetProduktinformation.Response> {
        return send('POST', '/Produkt/GetProduktinformation', request) as any
      },
      /** @de GetProduktparameter */
      async getProduktparameter (
        request: Produkt.GetProduktparameter.Request
      ): Promise<Produkt.GetProduktparameter.Response> {
        return send('POST', '/Produkt/GetProduktparameter', request) as any
      },
      /** @de GetUmzugsprodukte */
      async getUmzugsprodukte (
        request: Produkt.GetUmzugsprodukte.Request
      ): Promise<Produkt.GetUmzugsprodukte.Response> {
        return send('POST', '/Produkt/GetUmzugsprodukte', request) as any
      },
      /** @de GetWechselprodukte */
      async getWechselprodukte (
        request: Produkt.GetWechselprodukte.Request
      ): Promise<Produkt.GetWechselprodukte.Response> {
        return send('POST', '/Produkt/GetWechselprodukte', request) as any
      }
    }
  }
  /** @de Prozesse */
  get prozesse () {
    const send = this.send
    return {
      /** @de CreateProzessinformation */
      async createProzessinformation (
        request: Prozesse.CreateProzessinformation.Request
      ): Promise<void> {
        return send(
          'POST',
          '/Prozesse/CreateProzessinformation',
          request
        ) as any
      },
      /** @de DeleteProzessinformation */
      async deleteProzessinformation (
        request: Prozesse.DeleteProzessinformation.Request
      ): Promise<void> {
        return send(
          'POST',
          '/Prozesse/DeleteProzessinformation',
          request
        ) as any
      },
      /** @de GetProzesse */
      async getProzesse (
        request: Prozesse.GetProzesse.Request
      ): Promise<Prozesse.GetProzesse.Response> {
        return send('POST', '/Prozesse/GetProzesse', request) as any
      },
      /** @de GetProzessinformation */
      async getProzessinformation (
        request: Prozesse.GetProzessinformation.Request
      ): Promise<Prozesse.GetProzessinformation.Response> {
        return send('POST', '/Prozesse/GetProzessinformation', request) as any
      },
      /** @de GetProzessparameter */
      async getProzessparameter (
        request: Prozesse.GetProzessparameter.Request
      ): Promise<Prozesse.GetProzessparameter.Response> {
        return send('POST', '/Prozesse/GetProzessparameter', request) as any
      }
    }
  }
  /** @de Report */
  get report () {
    const send = this.send
    return {
      /** @de GetReport */
      async getReport (
        request: Report.GetReport.Request
      ): Promise<Report.GetReport.Response> {
        return send('POST', '/Report/GetReport', request) as any
      },
      /** @de GetReportliste */
      async getReportliste (
        request: Report.GetReportliste.Request
      ): Promise<Report.GetReportliste.Response> {
        return send('POST', '/Report/GetReportliste', request) as any
      }
    }
  }
  /** @de Validierung */
  get validierung () {
    const send = this.send
    return {
      /** @de CheckAdresse */
      async checkAdresse (
        request: Validierung.CheckAdresse.Request
      ): Promise<Validierung.CheckAdresse.Response> {
        return send('POST', '/Validierung/CheckAdresse', request) as any
      },
      /** @de CheckDatei */
      async checkDatei (request: Validierung.CheckDatei.Request): Promise<void> {
        return send('POST', '/Validierung/CheckDatei', request) as any
      },
      /** @de CheckFelder */
      async checkFelder (
        request: Validierung.CheckFelder.Request
      ): Promise<Validierung.CheckFelder.Response> {
        return send('POST', '/Validierung/CheckFelder', request) as any
      },
      /** @de CheckJobStatus */
      async checkJobStatus (
        request: Validierung.CheckJobStatus.Request
      ): Promise<Validierung.CheckJobStatus.Response> {
        return send('POST', '/Validierung/CheckJobStatus', request) as any
      }
    }
  }
  /** @de Vertrag */
  get vertrag () {
    const send = this.send
    return {
      /** @de CreateKuendigung */
      async createKuendigung (
        request: Vertrag.CreateKuendigung.Request
      ): Promise<void> {
        return send('POST', '/Vertrag/CreateKuendigung', request) as any
      },
      /** @de CreateUmzug */
      async createUmzug (
        request: Vertrag.CreateUmzug.Request
      ): Promise<Vertrag.CreateUmzug.Response> {
        return send('POST', '/Vertrag/CreateUmzug', request) as any
      },
      /** @de CreateVollmacht */
      async createVollmacht (
        request: Vertrag.CreateVollmacht.Request
      ): Promise<Vertrag.CreateVollmacht.Response> {
        return send('POST', '/Vertrag/CreateVollmacht', request) as any
      },
      /** @de CreateWiderruf */
      async createWiderruf (
        request: Vertrag.CreateWiderruf.Request
      ): Promise<void> {
        return send('POST', '/Vertrag/CreateWiderruf', request) as any
      },
      /** @de GetForderungen */
      async getForderungen (
        request: Vertrag.GetForderungen.Request
      ): Promise<Vertrag.GetForderungen.Response> {
        return send('POST', '/Vertrag/GetForderungen', request) as any
      },
      /** @de GetLastgang */
      async getLastgang (
        request: Vertrag.GetLastgang.Request
      ): Promise<Vertrag.GetLastgang.Response> {
        return send('POST', '/Vertrag/GetLastgang', request) as any
      },
      /** @de GetLastgangKosten */
      async getLastgangKosten (
        request: Vertrag.GetLastgangKosten.Request
      ): Promise<Vertrag.GetLastgangKosten.Response> {
        return send('POST', '/Vertrag/GetLastgangKosten', request) as any
      },
      /** @de GetMoeglichesVertragsende */
      async getMoeglichesVertragsende (
        request: Vertrag.GetMoeglichesVertragsende.Request
      ): Promise<Vertrag.GetMoeglichesVertragsende.Response> {
        return send(
          'POST',
          '/Vertrag/GetMoeglichesVertragsende',
          request
        ) as any
      },
      /** @de GetVertraege */
      async getVertraege (
        request: Vertrag.GetVertraege.Request
      ): Promise<Vertrag.GetVertraege.Response> {
        return send('POST', '/Vertrag/GetVertraege', request) as any
      },
      /** @de GetVertragsliste */
      async getVertragsliste (
        request: Vertrag.GetVertragsliste.Request
      ): Promise<Vertrag.GetVertragsliste.Response> {
        return send('POST', '/Vertrag/GetVertragsliste', request) as any
      },
      /** @de UpdateStammdaten */
      async updateStammdaten (
        request: Vertrag.UpdateStammdaten.Request
      ): Promise<void> {
        return send('POST', '/Vertrag/UpdateStammdaten', request) as any
      }
    }
  }
  /** @de Vorversorger */
  get vorversorger () {
    const send = this.send
    return {
      /** @de GetVorversorger */
      async getVorversorger (
        request: Vorversorger.GetVorversorger.Request
      ): Promise<Vorversorger.GetVorversorger.Response> {
        return send('POST', '/Vorversorger/GetVorversorger', request) as any
      }
    }
  }
  /** @de Zaehlerstand */
  get zaehlerstand () {
    const send = this.send
    return {
      /** @de CreateZaehlerstand */
      async createZaehlerstand (
        request: Zaehlerstand.CreateZaehlerstand.Request
      ): Promise<Zaehlerstand.CreateZaehlerstand.Response> {
        return send('POST', '/Zaehlerstand/CreateZaehlerstand', request) as any
      },
      /** @de DetectZaehlerstand */
      async detectZaehlerstand (
        request: Zaehlerstand.DetectZaehlerstand.Request
      ): Promise<Zaehlerstand.DetectZaehlerstand.Response> {
        return send('POST', '/Zaehlerstand/DetectZaehlerstand', request) as any
      },
      /** @de GetZaehlerstaende */
      async getZaehlerstaende (
        request: Zaehlerstand.GetZaehlerstaende.Request
      ): Promise<Zaehlerstand.GetZaehlerstaende.Response> {
        return send('POST', '/Zaehlerstand/GetZaehlerstaende', request) as any
      }
    }
  }
  /** @de Zahlung */
  get zahlung () {
    const send = this.send
    return {
      /** @de CreateRatenplan */
      async createRatenplan (
        request: Zahlung.CreateRatenplan.Request
      ): Promise<Zahlung.CreateRatenplan.Response> {
        return send('POST', '/Zahlung/CreateRatenplan', request) as any
      },
      /** @de GetRatenplan */
      async getRatenplan (
        request: Zahlung.GetRatenplan.Request
      ): Promise<Zahlung.GetRatenplan.Response> {
        return send('POST', '/Zahlung/GetRatenplan', request) as any
      },
      /** @de GetZahlungsinformation */
      async getZahlungsinformation (
        request: Zahlung.GetZahlungsinformation.Request
      ): Promise<Zahlung.GetZahlungsinformation.Response> {
        return send('POST', '/Zahlung/GetZahlungsinformation', request) as any
      },
      /** @de UpdateZahlungsinformation */
      async updateZahlungsinformation (
        request: Zahlung.UpdateZahlungsinformation.Request
      ): Promise<void> {
        return send(
          'POST',
          '/Zahlung/UpdateZahlungsinformation',
          request
        ) as any
      }
    }
  }
  protected send: Send
}
