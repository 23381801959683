import * as Model from '../../core/1.3/model'

class DienstleisterResponse extends Model.Dienstleister.GetDienstleisterInformation.Response { }

/**
 * External partner.
 */
export default class Dienstleister extends DienstleisterResponse {
  /**
   * Creates empty external partner helper.
   */
  public static createEmpty () {
    return new Dienstleister({ Ergebnisse: [] })
  }

  /**
   * Instantiates a new external partner helper.
   */
  public constructor (dienstleister: DienstleisterResponse) {
    super()
    Object.assign(this, dienstleister)
  }

  /**
   * Returns value by name.
   */
  public get (name: string) {
    const feld = this.Ergebnisse.find(feld => feld.Feld === name)
    return feld ? feld.Wert : ''
  }
}
