import * as Model from '../../core/1.3/model'

class ProzesseResponse extends Model.Prozesse.GetProzesse.Response { }

/**
 * Processes helper.
 */
export default class Prozesse extends ProzesseResponse {
  /**
   * Creates empty processes.
   */
  public static createEmpty () {
    return new Prozesse({
      GP: [],
      VK: [],
      Username: { Username: '', Prozesse: [] },
      InterneZeit: new Date()
    })
  }

  /**
   * Instantiates a new processes helper.
   */
  public constructor (prozesse: ProzesseResponse) {
    super()
    Object.assign(this, prozesse)
  }

  /**
   * Returns whether given process is enabled.
   */
  public isEnabled (name: string, vk?: string) {
    const prozess = this.get(name, vk)
    return prozess ? prozess.Status : false
  }

  /**
   * Returns whether given process is disabled.
   */
  public isDisabled (name: string) {
    return !this.isEnabled(name)
  }

  /**
   * Returns error message why given process is not available.
   */
  public getError (name: string) {
    const prozess = this.get(name)
    return prozess ? prozess.TechnischeBeschreibung || prozess.FachlicheBeschreibung : ''
  }

  /**
   * Returns functional error message why given process is not available.
   */
  public getFunctionalError (name: string) {
    const prozess = this.get(name)
    return prozess ? prozess.FachlicheBeschreibung : ''
  }

  /**
   * Returns code from given process.
   */
  public getCode (name: string) {
    const prozess = this.get(name)
    return prozess ? prozess.Code : ''
  }

  /**
   * Returns process with given name.
   */
  public get (name: string, vk?: string) {
    return this.filter(vk).find(prozess => prozess.Prozess === name)
  }

  /**
   * Returns string from process values.
   */
  public getString (name: string, key: string) {
    return this.getWerte(name, key)
  }

  /**
   * Returns number from process values.
   */
  public getNumber (name: string, key: string) {
    return parseFloat(this.getWerte(name, key))
  }

  /**
   * Returns boolean from process values.
   */
  public getBoolean (name: string, key: string) {
    return this.getWerte(name, key) === 'true'
  }

  /**
   * Returns date from process values.
   */
  public getDate (name: string, key: string) {
    return new Date(this.getWerte(name, key))
  }

  /**
   * Returns whether process value exists.
   */
  public hasValue (name: string, key: string) {
    return this.getWerte(name, key) !== ''
  }

  /**
   * Returns unparsed process value.
   */
  public getWerte (name: string, key: string) {
    const prozess = this.get(name)
    const werte = prozess ? prozess.Werte as Partial<Record<string, string>> : {}
    return werte[key] || ''
  }

  /**
   * List of processes filtered by given criteria.
   */
  protected filter (vk?: string) {
    if (vk) {
      return this.VK.find(item => item.VK.ReferenzID === vk)?.Prozesse || []
    }
    if (this.VK?.length > 0) {
      return this.VK[0].Prozesse
    } else if (this.Username) {
      return this.Username.Prozesse
    } else if (this.GP?.length > 0) {
      return this.GP[0].Prozesse
    } else {
      return []
    }
  }
}
