<template>
  <router-link v-if="linkRoute"
               :to="{name: linkRoute.name, query: linkRoute.query}"
               class="link"
               :class="{['link--' + modifier]: modifier, 'link--full-width': fullWidth, 'link--button': isButton, 'link--disabled': disabled}">
        <span class="vf-icon"
              :class="{[icon]: !!icon}"
              v-if="icon">
        </span>
        <div class="icon-url"
              v-if="iconClass"
              :class="iconClass"></div>
        <span class="label-url"
              v-html="label"></span>
  </router-link>
  <a v-else
     :href="link"
     class="link"
     @click="$emit('click')"
     :class="{['link--' + modifier]: modifier, 'link--full-width': fullWidth, 'link--button': isButton, 'link--disabled': disabled}"
     :target="newTab ? '_blank' : undefined">
    <span class="vf-icon"
          :class="{[icon]: !!icon}"
          v-if="icon">
    </span>
    <div class="icon-url"
         v-if="iconClass"
         :class="iconClass"></div>
    <span class="label-url"
          v-html="label"></span>
  </a>
</template>
<script>
import CsoRouter from '@/api/CsoRouter'

export default {
  name: 'CsoLink',
  props: {
    label: {
      type: String,
      required: false
    },
    link: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    iconClass: {
      type: String,
      required: false
    },
    modifier: {
      type: String,
      required: false
    },
    fullWidth: {
      type: Boolean,
      required: false
    },
    newTab: {
      type: Boolean,
      required: false,
      default: false
    },
    isButton: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['click'],

  computed: {
    linkRoute () {
      return CsoRouter.findInRoutes(this.link)
    }
  },
  data () {
    return {
      CsoRouter
    }
  }
}
</script>

<style lang="scss">
.link {
  outline: none;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &--full-width {
    display: block;
    width: 100%;
  }

  &--navigation {
    display: grid;
    grid-template-columns: 1fr 5fr;
    width: 100%;
  }

  &--button {
    display: inline-block;
    border: 2px solid $black;
    color: $black;
    font-size: 14px;
    min-height: 32px;
    padding: 0px 24px;
    line-height: 32px;
    text-align: center;
    border-radius: 100px;
    text-decoration: none;

    &:hover {
      color: $black;
      background-color: $yellow;
      border-color: $yellow;
      text-decoration: none;
    }

    .vf-icon {
      line-height: 12px;
    }
  }

  &--primary {
    background-color: $blue;
    border: 2px solid $blue;
    color: $white;
  }

  &--secondary {
    background-color: $yellow;
    border: 2px solid $yellow;
    color: $black;

    &:hover {
      text-decoration: none;
      background-color: $yellow-pale;
      border-color: $yellow-pale;
      color: $black;
    }
  }

  &--disabled {
    color: $gray-light;

    &:hover {
      cursor: not-allowed;
      text-decoration: none;
    }
  }

  &--custom {
    background-color: $white;
    border: 2px solid $blue;
    color: $blue;
  }

  .vf-icon {
    font-size: 20px;
    position: relative;
    top: 5px;
    left: -5px;
  }

  .icon-url {
    display: inline-block;
    height: 24px;
    width: 24px;
    margin-right: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: transparent;
  }

  .home {
    background-image: url('/static/icons/home.svg');
  }

  .user {
    background-image: url('/static/icons/user.svg');
  }

  .account {
    background-image: url('/static/icons/euro.svg');
  }

  .consumption {
    background-image: url('/static/icons/chart.svg');
  }

  .contract {
    background-image: url('/static/icons/contract.svg');
  }

  .tell-a-friend {
    background-image: url('/static/icons/thumbs.svg');
  }

  .help {
    background-image: url('/static/icons/help.svg');
  }

  .faq {
    background-image: url('/static/icons/faq.svg');
  }

  span.label-url {
    font-family: $font-family-regular;
    margin: 0;
  }
}
</style>
