import Token from '../authentication/Token'

/**
 * Token expired error.
 */
export default class ErrorTokenExpired extends Error {
  /**
   * Token that threw this error.
   */
  public token: Token

  /**
   * Instantiates a new token expired error.
   */
  public constructor (token: Token) {
    super('Digital Interface token expired')
    this.token = token
  }
}
