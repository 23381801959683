import type HttpHeaders from './http/Headers'
import type HttpRequest from './http/Request'
import type StatusCodeMap from './status-code/Map'
import Token from './authentication/Token'
import { type HttpMethod } from './http/Method'
import { type Version, RequestBody } from './core/model-shared'

/**
 * Request.
 */
export default class Request<X = unknown, Y = unknown> implements HttpRequest<RequestBody<X>> {
  /**
  * Header name for subscription key.
  */
  public static readonly HEADER_SUBSCRIPTION_KEY = 'Ocp-Apim-Subscription-Key'

  /**
   * Header name for access token.
   */
  public static readonly HEADER_TOKEN = 'davis-token'

  /**
   * Max retries for a request.
   */
  public static readonly RETRY_MAX = 3

  /**
   * Delay time for a request.
   */
  public static readonly DELAY_TIME = 0.1

  /**
   * The api version number of the request.
   */
  public version: Version

  /**
   * The http method of the request.
   */
  public method: HttpMethod

  /**
   * The api endpoint of the request.
   */
  public endpoint: string

  /**
   * The body of the request.
   */
  public body: RequestBody<X>

  /**
   * The custom headers of the request.
   */
  public headersCustom: HttpHeaders

  /**
   * The api host of the request.
   */
  public host: string

  /**
   * The current retry number of the request.
   */
  public retry: number

  /**
   * The subscription key of the request.
   */
  public subscriptionKey: string

  /**
   * The authentication token of the request.
   */
  public token: Token | undefined

  /**
   * The status code map of the response if any.
   */
  public statusCodeMap: StatusCodeMap | undefined

  /**
   * Whether response can be cached.
   */
  public cache: boolean

  /**
   * The number of seconds after which the request timeouts.
   */
  public timeout: number

  /**
   * Instantiates a new request.
   */
  public constructor (
    version: Version,
    method: HttpMethod,
    endpoint: string,
    body: RequestBody<X>,
    headersCustom: HttpHeaders,
    host: string,
    retry: number,
    subscriptionKey: string,
    token: Token | undefined,
    statusCodeMap: StatusCodeMap | undefined,
    cache: boolean,
    timeout: number
  ) {
    this.version = version
    this.method = method
    this.endpoint = endpoint
    this.body = body
    this.headersCustom = headersCustom
    this.host = host
    this.retry = retry
    this.subscriptionKey = subscriptionKey
    this.token = token
    this.statusCodeMap = statusCodeMap
    this.cache = cache
    this.timeout = timeout
  }

  /**
   * The url of the request.
   */
  public get url (): string {
    return `${this.host}/${this.version}${this.endpoint}`
  }

  /**
   * The headers of the request.
   */
  public get headers (): HttpHeaders {
    return {
      ...this.headersDefault,
      ...this.headersCustom
    }
  }

  /**
   * Retries request.
   */
  public doRetry (): void {
    this.retry++
  }

  /**
   * Whether request can be retried.
   */
  public get canRetry (): boolean {
    return this.retry < Request.RETRY_MAX
  }

  /**
   * Whether request can use cache.
   */
  public get canCache (): boolean {
    return this.cache && this.retry === 0
  }

  /**
   * Delay time.
   */
  public get delayTime (): number {
    return Request.DELAY_TIME * this.retry
  }

  /**
   * The default headers of the request.
   */
  protected get headersDefault (): HttpHeaders {
    const headersDefault: HttpHeaders = {}
    headersDefault[Request.HEADER_SUBSCRIPTION_KEY] = this.subscriptionKey
    if (this.token) {
      headersDefault[Request.HEADER_TOKEN] = this.token.accessToken
    }
    return headersDefault
  }
}
