const messages = {
  en: {
    general: {
      hello: 'Hello',
      ok: 'Ok',
      yes: 'Yes',
      no: 'No',
      cancel: 'Cancel',
      back: 'Back',
      scrollToTop: 'Scroll to top',
      save: 'Save',
      print: 'Print',
      loading: 'Loading...',
      homepage: 'Homepage',
      toTheHomepage: 'To the Homepage',
      toTheStartsite: 'To the Homepage',
      clear: 'Clear',
      diOverriddenDate: 'DI StandVom date is overridden. This may lead to unexpected results.',
      errors: {
        post: 'Sorry, an error occurred while sending your data',
        generic: 'Sorry, an error occurred'
      },
      textareaCharLeft: {
        of: 'of',
        characters: 'characters',
        still: 'Still',
        charsLeft: 'characters left'
      }
    },
    validation: {
      minLength: '---{attribute} is too short. You need at least {min} characters',
      numeric: '---{attribute} needs to be a numeric value.',
      email: 'Field has to contain a valid email address.',
      regex: 'Please enter a valid value',
      dataProcessing: {
        checked: 'Please confirm that you have read and accepted the privacy policy.'
      },
      phoneNumber: {
        required: 'Phone number is required',
        length: 'Phone number has to have at least 6 digits.',
        phoneNumberFormat: 'Format of phone number is not valid.'
      },
      accountNumber: {
        required: 'Account number is required',
        length: 'Account number has to have 12 digits.',
        numeric: 'Account number can contain only digits.'
      },
      timeslot: {
        required: 'You have to select a timeslot.'
      },
      readingValue: {
        minLength: '---Input value has to be larger than provided. Please correct.',
        required: '---Input value is required',
        validatorNumericWithComma: '---Input value has to be a numeric value with maximum {beforeComma} digits before the comma and {afterComma} digits after the comma',
        validatorNumericWithoutComma: '---Input value has to be a numeric value with maximum {beforeComma} digits',
        numeric: '---Input value has to be a numeric value.'
      }
    },
    components: {
      table: {
        date: '---Datum',
        salesType: '---Umsatzart',
        amount: '---Betrag',
        status: '---Status',
        goTo: '---Abschlag ändern',
        noData: '---Keine Daten zum Anzeigen vorhanden'
      }
    },
    contractSelection: {
      headers: {
        contractSelectionHeader: 'Select Contract:'
      },
      labels: {
        selectedContract: 'Selected Contract:',
        selectContract: 'Select Contract',
        contractAccount: 'Contract Account',
        addAccount: '+ Add account',
        electricity: 'Electricity',
        gas: 'Gas',
        noncomm: 'Others'
      },
      contractStatuses: {
        ORDER_RECEIVED: 'Order received',
        ORDER_RECEIVING: 'Order receiving',
        ORDER_IN_PROGRESS: 'Order in progress',
        ORDER_COMPLETED: 'Order completed',
        INACTIVE: 'Contract inactive',
        CUSTOMER_FEEDBACK_EXPECTED: 'Customer feedback expected',
        ORDER_REJECTED: 'Order rejected',
        ORDER_CANCELED: 'Order canceled',
        MOVE_OUT: 'Move out recorded',
        ACTIVE: 'Contract active',
        OTHER: 'unknown status code',
        undefined: ''
      }
    },
    contactForm: {
      header: 'Contact us',
      subheader: 'Send us your request. Our service staff will contact you as soon as possible. A copy of the form you submitted will be send to your e-mail address.',
      contactReason: 'Subject',
      message: 'Your message',
      attachDocument: 'Your documents',
      attachDocumentHint: 'You may add a file attachment up to 10 MB to your message. You may upload in any of the following file formats: pdf, doc, docx, xls, xlsx, jpg, gif, png, zip.',
      areYouACustomer: 'Haben Sie ein Anliegen zu einem bereits abgeschlossenen Vertrag?',
      personalData: 'Personal data',
      salutation: 'Form of address',
      firstname: 'First name',
      lastname: 'Family name',
      contractAccountNumber: 'Contract account number',
      email: 'E-Mail',
      phone: 'Daytime telephone',
      agreement: 'I confirm, that I have read the notes on <a target="_blank" href="//vattenfall.de/datenschutz">data protection</a> and accept them.',
      cancel: 'Cancel',
      submit: 'Send',
      attachment: 'Attachment',
      confirmation: 'Confirmation',
      requestSent: 'Following request was sent to our support service:',
      contactInfo: 'Thanks for your message. We will process your request as quickly as possible.',
      validations: {
        attachment: {
          fileSizeValidator: 'Attachments must not exceed 10 MB.',
          fileTypes: 'The format of attached file is wrong. Allowed formats: pdf, doc, docx, xls, xlsx, jpg, gif, png, zip. '
        },
        contactReason: {
          required: 'Please select.'
        },
        message: {
          required: 'Please fill in this field.'
        },
        salutation: {
          required: 'Please select.'
        },
        contractAccountNumber: {
          regex: 'The contract account number must contain 12 digits and start with 83.'
        },
        email: {
          email: 'Please enter a valid e-mail address.<br />For example: <strong>mustermann@mustermail.com</strong>',
          required: 'Please enter a valid e-mail address.<br />For example: <strong>mustermann@mustermail.com</strong>'
        },
        phone: {
          phoneNumberFormat: 'Please enter a valid phone number.'
        },
        agreement: {
          checked: ''
        }
      }
    },
    navigation: {
      labels: {
        DASHBOARD: 'Dashboard',
        USER_PROFILE: 'User profile',
        ACCOUNT_OVERVIEW: 'Account overview',
        CONSUMPTION_OVERVIEW: 'Consumption overview',
        CONTRACT_OVERVIEW: 'Contract overview',
        TELL_A_FRIEND: 'Tell a friend',
        FAQ: 'FAQ',
        ANGEBOTE: 'Offers',
        undefined: ''
      },
      mobileHome: 'Home',
      mobileMenu: 'Menu',
      mobileFaq: 'FAQ'
    }
  },
  de: {
    general: {
      loggedInAs: 'Angemeldet als',
      ok: 'Weiter',
      next: 'Weiter',
      cancel: 'Abbrechen',
      submitOrder: 'Abbrechen',
      yes: 'Ja',
      no: 'Nein',
      back: 'Zurück',
      send: 'Senden',
      here: 'hier',
      scrollToTop: 'Nach oben',
      save: 'Speichern',
      print: 'Drucken',
      printButton: 'Zusammenfassung drucken',
      required: 'Pflichtfeld',
      loading: '---Loading...',
      homepage: 'Homepage',
      toTheHomepage: 'Zur Übersicht',
      toTheStartsite: 'Zur Startseite',
      toTheLogin: 'Zum Login',
      clickToShowPassword: 'Klicken Sie, um das Passwort anzuzeigen',
      noData: 'keine Rückmeldung',
      showMoreRows: 'Archiv',
      edit: 'Ändern',
      sentData: 'Gesendete Daten',
      clear: 'Löschen',
      ipBlocked: 'Ihr Zugang wurde aus Sicherheitsgründen vorübergehend gesperrt. Bitte versuchen Sie es nach 24 Stunden erneut.',
      help: 'Kontakt',
      vattenfallHomePage: 'Vattenfall Homepage',
      meinVattenfall: 'Mein Vattenfall',
      login: 'Anmelden',
      logout: 'Abmelden',
      diOverriddenDate: 'DI StandVom date is overridden. This may lead to unexpected results.',
      dearVisitor: 'Sehr geehrter Besucher',
      wrongDate: 'Die Zeit auf Ihrem Gerät weicht von der Zeitzone in Deutschland ab. Damit Datums- und Zeitangaben richtig dargestellt werden, passen Sie bitte die Systemzeit in den Einstellungen Ihres Gerätes an.',
      errors: {
        post: 'Beim Senden Ihrer Daten ist ein Fehler aufgetreten',
        generic: 'Entschuldigung, ein Fehler ist aufgetreten',
        unexpected: 'Leider ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.'
      },
      textareaCharLeft: {
        still: 'Noch',
        of: 'von',
        characters: 'Zeichen',
        charsLeft: 'Zeichen verfügbar'
      },
      labels: {
        zipCode: 'Postleitzahl',
        city: 'Ort',
        street: 'Straße',
        houseNo: 'Hausnummer',
        co: 'c/o'
      },
      salutation: {
        herr: 'Sehr geehrter Herr {surname}',
        herrn: 'Sehr geehrter Herr {surname}',
        frau: 'Sehr geehrte Frau {surname}',
        damen: 'Sehr geehrte Damen und Herren',
        herren: 'Sehr geehrte Damen und Herren',
        kundin: 'Sehr geehrte Kundin / Sehr geehrter Kunde',
        firma: 'Sehr geehrte Damen und Herren',
        ohne: 'Sehr geehrte Damen und Herren '
      },
      postBoxHint: 'Sollte Ihr Name nicht am Briefkasten stehen, können Sie hier den Namen auf dem Briefkasten eintragen.',
      addressNotFound: 'Die Straße wurde unter der eingegebenen Postleitzahl nicht gefunden. Bitte überprüfen Sie Ihre Eingabe und bestätigen Sie mit "Weiter".',
      cityNotFound: 'Die Stadt wurde unter der eingegebenen Postleitzahl nicht gefunden.',
      captcha: {
        title: 'Ihre Daten werden geprüft',
        subtitle: 'Wir prüfen Ihre Eingabe und sind gleich für Sie da'
      }
    },
    validation: {
      minLength: '---{attribute} is too short. You need at least {min} characters',
      numeric: '---{attribute} needs to be a numeric value.',
      email: 'Der Wert muss eine gültige E-Mail-Adresse sein.',
      regex: '---Please enter a valid value',
      dataProcessing: {
        checked: 'Bitte bestätigen Sie, dass Sie die Informationen zum Datenschutz gelesen und akzeptiert haben.'
      },
      phoneNumber: {
        required: 'Telefonnummer ist ein Pflichtfeld.',
        length: 'Telefonnummer muss mindestens 6 Zeichen lang sein.',
        phoneNumberFormat: 'Das Format der Telefonnummer ist ungültig.'
      },
      accountNumber: {
        required: 'Vertragskontonummer ist ein Pflichtfeld',
        length: 'Vertragskontonummer muss mindestens 12 Zeichen lang sein.',
        numeric: 'Vertragskontonummer darf nur numerische Zeichen enthalten.'
      },
      timeslot: {
        required: 'Zeitraum ist ein Pflichtfeld.'
      },
      readingValue: {
        minLength: '---Input value has to be longer than provided. Please correct.',
        required: '---Input value is required',
        validatorNumericWithComma: '---Input value has to be a numeric value with maximum {beforeComma} digits before the comma and {afterComma} digits after the comma',
        validatorNumericWithoutComma: '---Input value has to be a numeric value with maximum {beforeComma} digits',
        numeric: '---Input value has to be a numeric value.'
      },
      sendData: {
        participationAgreed: {
          checked: 'Bitte bestätigen Sie, dass Sie die Informationen zum Datenschutz gelesen und akzeptiert haben.'
        },
        name: {
          required: 'Bitte geben Sie einen Vornamen ein.',
          pattern: 'Bei der Eingabe Ihres Vornamens sind nur Buchstaben erlaubt.'
        },
        surname: {
          required: 'Bitte geben Sie einen Nachnamen ein.',
          pattern: 'Bei der Eingabe Ihres Nachnamens sind nur Buchstaben erlaubt.'
        }
      }
    },
    components: {
      accountOverview: {
        header: 'Kontoübersicht',
        date: 'Datum',
        salesType: 'Umsatzart',
        amount: 'Betrag',
        status: 'Status',
        goTo: 'Zurück zur Übersicht',
        partPayment: 'Abschlag ändern',
        noData: 'Keine Daten zum Anzeigen vorhanden.',
        processUnavailable: 'Für dieses Vertragskonto stehen zur Zeit keine Kontoumsätze zur Verfügung.'
      }
    },
    tellAFriend: {
      headers: {
        title: 'Freunde werben',
        main: 'Tarif empfehlen und Prämie sichern',
        subHeader: 'Die Prämie erhalten Sie für jeden Kunden, der aufgrund Ihrer Empfehlung einen unserer Tarife abschließt.'
      },
      tables: {
        headers: {
          advertisedFriends: 'Status Ihrer geworbenen Freunde',
          sentAdvertisingMails: 'Übersicht Ihrer Empfehlungen'
        },
        labels: {
          productOrderedOn: 'Produkt bestellt am',
          friendName: 'Name',
          processStatus: 'Verarbeitungsstatus',
          wantedOnDate: 'Geworben am',
          receiverName: 'Name Empfänger',
          receiverMail: 'E-Mail Empfänger',
          noRespondRow: 'keine Rückmeldung'
        }
      },
      form: {
        headerRadio: 'Empfehlen Sie unsere Tarife',
        headerInputs: 'Daten Ihres Freundes',
        radioLabelGas: 'Gas',
        radioLabelElectricity: 'Strom',
        nameLabel: 'Vorname',
        surnameLabel: 'Name',
        checkboxDataProcessingPart1: 'Ich akzeptiere die ',
        checkboxDataProcessingPart2: ' für Freunde werben Freunde.',
        maxSubmitsMessage1: 'Sie können maximal ',
        maxSubmitsMessage2: ' Einladungen pro Tag versenden.'
      },
      thankYouPage: {
        header: 'Vielen Dank für Ihre Teilnahme am Programm Freunde werben Freunde.',
        nextSteps: 'Die nächsten Schritte',
        pointOne: 'Sie erhalten in den nächsten Minuten eine E-Mail von uns.',
        pointTwo: 'Sie können diese E-Mail direkt oder auch nur den enthaltenen Link über einen Kanal Ihrer Wahl an Ihren Freund senden.',
        pointThree: 'Ihr Freund kann die Bestellung über den Button in der E-Mail oder den Link starten.',
        pointFour: 'Nachdem die Bestätigung des Liefertermins bei Ihrem Freund eingegangen ist bekommen Sie Ihre Prämie gutgeschrieben.',
        overviewYourActivities1: 'Eine Übersicht Ihrer Aktivitäten finden Sie',
        overviewYourActivities2: 'hier',
        participateInLottery: 'Ich nehme am Gewinnspiel teil.',
        product: 'Empfohlene Tarife für',
        productGas: 'Gas',
        productElectricity: 'Strom'
      },
      circle: {
        bonus: 'Prämie'
      },
      listCheck: {
        checkOne: 'Auswählen, ob Sie einen Strom- oder Gasvertrag empfehlen möchten',
        checkTwo: 'Den Namen der Freunde eintragen',
        checkThree: 'Und die Teilnahmebedingungen akzeptieren'
      },
      btns: {
        conditionsOfParticipation: 'Teilnahmebedingungen',
        advertiseFriend: 'Freunde werben'
      }
    },
    consumptionOverview: {
      header: 'Verbrauchsübersicht',
      errors: {
        noData: ' Für das ausgewählte Vertragskonto ist derzeit keine Verbrauchsübersicht verfügbar.',
        noPermission: 'Für das ausgewählte Vertragskonto ist derzeit keine Verbrauchsübersicht verfügbar.',
        disabled: 'Für das ausgewählte Vertragskonto ist derzeit keine Verbrauchsübersicht verfügbar.',
        general: 'Entweder haben Sie keine Berechtigung zur Anzeige der Verbrauchsübersicht oder es liegt eine technische Störung vor.'
      },
      tables: {
        headers: {
          yourCounts: 'Verbrauchsübersicht'
        },
        labels: {
          date: 'Datum',
          meterNumber: 'Zählernummer',
          meterReading: 'Zählerstand',
          consumption: 'Verbrauch',
          reason: 'Ablesegrund',
          type: 'Ableseart'
        },
        gasLegend: '* wird mittels Zustandszahl und Brennwert errechnet'
      },
      chart: {
        labels: {
          GAS: {
            lastConsumptions: 'Ihr Jahresgasverbrauch in kWh',
            averageConsumptions: 'Durchschnittlicher Jahresgasverbrauch in kWh'
          },
          STROM: {
            lastConsumptions: 'Ihr Jahresstromverbrauch',
            averageConsumptions: 'Durchschnittlicher Jahresstromverbrauch'
          },
          consumption: 'Verbrauch in kWh',
          approximateConsumption: '* gerechnet auf 365 Tage',
          invoice: 'Abrechnung'
        },
        tooltips: {
          gasConsumptionInKWH: 'Zählerstande werden bei Gas in Kubikmeter m<sup>3</sup> abgelesen. Diese werden dann in verbrauchte Arbeit kWh umgerechnet. Die nötigen Werte dazu liefert uns Ihr örtlicher Netzbetreiber.',
          approximateConsumption: 'Bitte beachten Sie, dass der Wert Ihrer Abrechnung in der Grafik von Ihrer Jahresrechnung abweichen kann. Um Ihnen eine Vergleichbarkeit zu ermöglichen rechnen wir Ihren Verbrauch auf ein ganzes Jahr um.'
        }
      }
    },
    contractSelection: {
      headers: {
        contractSelectionHeader: 'Vertragskonto wechseln',
        contractNumber: 'Vertragskontonummer:',
        product: 'Produkt',
        address: 'Lieferstelle',
        status: 'Status',
        accountNumber: 'Vertragskonto-Nr.'
      },
      labels: {
        selectContract: 'Ausgewählter Vertrag:',
        contractAccount: 'Vertragskonto',
        addAccount: 'Bestehenden Vertrag hinzufügen',
        electricity: 'Strom',
        gas: 'Gas'
      },
      contractStatuses: {
        ORDER_RECEIVED: 'Bestellung eingegangen',
        ORDER_RECEIVING: 'Bestellung erhalten',
        ORDER_IN_PROGRESS: 'Bestellung in Bearbeitung',
        ORDER_COMPLETED: 'Bestellung abgeschlossen',
        INACTIVE: 'Vertrag inaktiv',
        CUSTOMER_FEEDBACK_EXPECTED: 'Kundenrückmeldung erwartet',
        ORDER_REJECTED: 'Bestellung abgelehnt',
        ORDER_CANCELED: 'Bestellung abgebrochen',
        MOVE_OUT: 'Auszug erfasst',
        ACTIVE: 'Vertrag aktiv',
        OTHER: 'unbekannter Vertragsstatus',
        undefined: ''
      }
    },
    meterReading: {
      meterUnits: {
        KWH: 'kWh',
        STROM: 'kWh',
        GAS: 'm<sup>3</sup>',
        M3: 'm<sup>3</sup>'
      },
      headers: {
        headerForBanner: 'Zählerstandserfassung',
        formElectricityMeterNumber: 'Stromzählernummer',
        formGasMeterNumber: 'Gaszählernummer',
        modBoxLastMeterReading: 'Meine letzten Zählerstände',
        sentThankYou: 'Vielen Dank, wir haben Ihren Zählerstand erhalten.',
        TURNUSABLESUNG: 'Zählerstand für Ihre Jahresverbrauchsabrechnung',
        AUSZUGABLESUNG: 'Zählerstand für Ihre Schlussrechnung',
        EINZUGABLESUNG: 'Zählerstand für Ihren Vertragsbeginn',
        htLastReading: 'Letzte Ablesung: {value} {unit} (HT) vom {date}',
        ntLastReading: 'und {value} {unit} (NT) vom {date}',
        overview: 'Zählerstandsübersicht',
        default: 'Neuen Zählerstand erfassen'
      },
      labels: {
        reasonTableHeader: 'Ablesegrund',
        typeTableHeader: 'Ableseart',
        meterReadingTableHeader: 'Zählerstand',
        formDate: 'Ablesedatum',
        formMeterNumber: 'Zählernummer',
        contractAccountNumber: 'Vertragskontonummer',
        formReason: 'Begründung',
        historyDate: 'Datum',
        meterReading: 'Zählerstand ohne Nachkommastelle',
        meterReadingShort: 'Zählerstand ohne Nachkomma',
        consumption: 'Verbrauch',
        abbrevHT: '(HT)',
        abbrevNT: '(NT)',
        reason: 'Ablesegrund',
        photoUploadTitle: 'Foto des Zählerstands hochladen',
        photoUploadInstruction: 'Hier klicken oder Datei ablegen'
      },
      implausibleReasons: {
        ZAEHLERSTAND_KORREKT: 'Zählerstand ist korrekt',
        WASSERSCHADEN: 'Wasserschaden',
        ZAEHLERTAUSCH: 'Zähler wurde ausgetauscht'
      },
      messages: {
        sentThankYou: 'In wenigen Minuten erhalten Sie eine Bestätigung per E-Mail.',
        readingOpenSentThankYou: 'Sofern Sie bei uns eine E-Mail-Adresse hinterlegt haben, erhalten Sie in wenigen Minuten eine Bestätigung per E-Mail.',
        sentThankYouMandateNotice: 'Sollten Sie eine weitere Aufforderung zur Ablesung erhalten, betrachten Sie diese als gegenstandslos.'
      },
      tooltips: {
        STROM: 'Bitte tragen Sie hier nur die Ziffern vor dem Komma auf Ihrem Stromzähler ein und ignorieren Sie alle Nachkommastellen.',
        GAS: 'Bitte tragen Sie hier nur die Ziffern vor dem Komma auf Ihrem Gaszähler ein und ignorieren Sie alle Nachkommastellen.',
        contractAccountNumber: 'Ihre Vertragskontonummer finden Sie z.B. jeweils im grau hinterlegten Bereich Ihrer Vertragsbestätigung sowie Ihrer Rechnung.',
        formMeterNumber: 'Die Zählernummer finden Sie auf Ihrer Vertragsbestätigung oder Ableseaufforderung sowie Ihren Rechnungen. Natürlich finden Sie diese auch immer auf Ihrem Zähler, häufig in der Nähe des Barcodes.'
      },
      buttons: {
        consumptionOverview: 'Zur Verbrauchsübersicht'
      },
      validationsReadingValue: {
        required: 'Bitte geben Sie Ihren Zählerstand ein.'
      },
      validationsDate: {
        required: 'Bitte geben Sie das Datum im Format TT.MM.JJJJ ein.',
        beforeMinimum: 'Bitte geben Sie ein Datum bis einschließlich {minDateText} ein.',
        afterMaximum: 'Bitte geben Sie ein Datum ab dem {maxDateText} ein.'
      },
      validationUploadFile: {
        error: 'Dieses Bild kann nicht verarbeitet werden. Bitte versuchen Sie es mit einem anderen.'
      },
      validationsImplausibleReason: {
        required: 'Bitte füllen Sie dieses Feld aus.'
      },
      validationsMeterNumber: {
        required: 'Bitte geben Sie Ihre Zählernummer ein.',
        Format: 'Die Zählernummer dameterNumberrf keine Leerzeichen oder Fragezeichen enthalten.'
      },
      validationsAccountNumber: {
        required: 'Geben Sie eine 12-stellige Vertragskontonummer beginnend mit 83 ein.',
        contractAccountNumberFormat: 'Ihre Vertragskontonummer besteht nur aus Ziffern und beginnt mit 83.'
      }
    },
    optin: {
      title: 'Änderung Ihrer Werbeeinverständnisse',
      banner: 'Werbeeinstellungen',
      subtitle: 'Hier haben Sie die Möglichkeit, Anpassungen bezüglich Ihres Werbeeinverständnisses vorzunehmen.',
      headline: 'Benachrichtigungen per E-Mail',
      text0: '<h4>Benachrichtigungen zu Produkten und Kundenbefragungen per E-Mail</h4>',
      text1: '<h4>Benachrichtigungen zu News und Kundenvorteilen per E-Mail</h4>',
      text2: '<h4>Benachrichtigungen zu Business News und Angeboten per E-Mail</h4>',
      infoIcon: 'Bevor Sie die Werbeeinstellungen ändern können, müssen Sie rechts unten auf den gelben Button „Werbeeinverständnis ändern“ klicken. Danach können Sie Änderungen vornehmen, indem Sie den jeweiligen Schalter nach rechts zum Aktivieren (Grün) oder nach links zum Deaktivieren (Grau) schieben. Anschließen bestätigen Sie bitte Ihre Änderung mit dem gelben Button „Speichern“. Wenn Sie eine Änderung gespeichert haben, bekommen Sie umgehend eine E-Mail zur Bestätigung zugesandt. Um Sie vor Missbrauch zu schützen, wird Ihre Änderung erst wirksam, nachdem Sie in dieser E-Mail auf den Button „Bestätigen“ geklickt haben, um Ihre E-Mail-Adresse zu bestätigen.',
      textRobinson: '<h4>Keine Werbung</h4><p>Ich möchte unter dieser Geschäftspartnernummer grundsätzlich keine Werbung von Vattenfall erhalten.</p>',
      cancel: 'Zurück',
      submit: 'Speichern',
      edit: 'Werbeeinstellungen ändern',
      errorNoSelection: 'Bitte wählen Sie mindestens eine Checkbox aus.',
      thanks: 'Vielen Dank, wir haben Ihre Aktualisierung der Werbeeinstellungen erhalten.'
    },
    statusPage: {
      headers: {
        hintPage: 'Wartungsarbeiten',
        disabledPage: 'Nicht verfügbar'
      },
      messages: {
        hintMessage1: 'Der von Ihnen ausgewählte Prozess steht aufgrund von Wartungsarbeiten ',
        hintMessage2: ' leider nicht zur Verfügung. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
        disabledPage: 'Entweder haben Sie keine Berechtigung zur Ausführung dieses Prozesses oder es liegt eine technische Störung vor. Bitte entschuldigen Sie die Umstände. Vielen Dank.'
      }
    },
    login: {
      sessionLostMessage: 'Weil Sie lange nicht aktiv waren, wurden Sie aus Sicherheitsgründen ausgeloggt.',
      accountLogoutTitle: 'Sie wurden von Ihrem Onlinekonto abgemeldet',
      accountLogoutMessage: 'Da Sie in Ihrem Onlinekonto Ihre E-Mail-Adresse geändert haben, wurden Sie aus Sicherheitsgründen abgemeldet. Melden Sie sich bitte mit Ihrer neuen E-Mail-Adresse als Benutzernamen und Ihrem Passwort wieder an.',
      registration: 'Sie haben noch kein Online-Konto?',
      title: {
        main: 'Anmeldung zum Online Service',
        mainMobile: 'Anmeldung <br /> zum Online Service',
        justPassword: 'Anmeldung',
        withPassword: 'Mit Passwort',
        instructionToLoginWithPassword:'Melden Sie sich mit ihren Zugangsdaten an.',
        instructionToLoginWithoutPassword: 'Sie erhalten eine E-Mail zum Anmelden.',
        withLink: 'Ohne Passwort',
        justLink: 'Login-Link',
        sentLink: 'Login-Link erfolgreich versendet',
        termsAndConditions: 'Allgemeine Geschäftsbedingungen',
        faq: 'Fragen & Antworten',
      },
      buttons: {
        login: 'Jetzt anmelden',
        send: 'E-Mail anfordern',
        forgottenPassword: 'Passwort vergessen?',
        register: 'Hier registrieren',
        tacReject: 'Ablehnen',
        tacAccept: 'Akzeptieren',
        tacPrint: 'Dokument drucken',
        tacDownload: 'Dokument herunterladen',
        tacPdfFilename: 'Nutzungsbedingungen.pdf',
        back: 'E-Mail erneut anfordern'
      },
      labels: {
        username: 'Benutzername oder E-Mail-Adresse',
        usernameOnly: 'Benutzername',
        password: 'Passwort'
      },
      validations: {
        username: {
          required: 'Bitte geben Sie Ihre E-Mail-Adresse oder Ihren Benutzernamen an.'
        },
        password: {
          required: 'Bitte geben Sie Ihr Passwort an.'
        }
      },
      tooltips: {
        password: 'Das Auge im Eingabefeld ermöglicht die Anzeige des Passworts in Klarschrift.'
      },
      texts: {
        infoIconText: 'Geben Sie hier Ihre E-Mail-Adresse oder Ihren Benutzernamen ein. Nachdem Sie auf „E-Mail anfordern“ geklickt haben, erhalten Sie eine E-Mail, mit der Sie sich bequem einloggen können. Falls Sie mehrere Kundenkonten mit derselben E-Mail-Adresse haben, müssen Sie hier den jeweiligen Benutzernamen eintragen.',
        withLinkMoreInfo: 'Sie erhalten eine E-Mail zum Einloggen.',
        sentLinkHeader: 'Prüfen Sie bitte Ihre E-Mails.',
        sentLink: 'Wir haben einen Login-Link an folgende E-Mail-Adresse gesendet. Dieser ist nur einmalig und für die Dauer einer Stunde gültig.'
      },
      withLinkError: 'Das hat leider nicht funktioniert, der Login-Link ist ungültig.<br />Bitte loggen Sie sich mit Ihrem Benutzernamen und Ihrem Passwort ein.',
      genericError: 'Aufgrund technischer Probleme ist ein Login derzeit nicht möglich. Bitte versuchen Sie es später noch einmal.'
    },
    communicationApproval: {
      header: 'Nur noch wenige Klicks bis zur Bestellung',
      errors: {
        server: 'Entschuldigung, das hat leider nicht funktioniert. Bitte versuchen Sie es später erneut.',
        checked: 'Geben Sie uns bitte Ihre Zustimmung, ansonsten kommt der Vertrag nicht zustande.'
      },
      rejectBtn: 'Ablehnen',
      btn: 'Jetzt kostenpflichtig bestellen'
    },
    partPayment: {
      form: {
        header: 'Abschlag ändern',
        subheader: 'Passen Sie Ihren Abschlag in den Grenzen zwischen <strong>{minimum} €</strong> und <strong>{maximum} €</strong> an.',
        suggestion: 'Unsere Empfehlung',
        selectSuggestion: 'Empfehlung annehmen',
        customSelection: 'Der Wert wurde übernommen',
        suggestionFootnote: 'Bitte beachten Sie, dass der Abschlag stets nur auf einer Schätzung Ihres Verbrauchs in der laufenden Verbrauchsperiode beruhen kann. Die vorgeschlagene Anpassung der Abschlagshöhe ist ausschließlich eine Empfehlung. Unsere Empfehlung beruht auf den letzten verfügbaren Ablesedaten und berücksichtigt die tatsächlichen Verhältnisse Ihres Energiebezugs in der laufenden Verbrauchsperiode.',
        min: 'min.',
        max: 'max.',
        perMonth: '€ / Monat',
        validation: {
          withinConstraints: 'Ihre Eingabe liegt außerhalb der erlaubten Grenzen. Solche Änderungen sind nur in Ausnahmefällen und im {link} mit unserem Serviceteam möglich.',
          notTheSame: 'Der eingegebene Betrag ist identisch mit dem aktuellen Abschlag. Keine Änderung notwendig.',
          required: 'Bitte füllen Sie dieses Feld aus.'
        },
        infoIcon: 'Warum es diese Grenzen gibt und weitere Fragen beantworten wir Ihnen <a onclick="document.querySelector(\'#faq-wrapper\')?.scrollIntoView()">hier</a>.'
      },
      sentPage: {
        header: 'Vielen Dank. Wir haben den Änderungswunsch zu Ihrer Abschlagszahlung erhalten.',
        newPlan: 'Neuer Abschlag:',
        perMonth: '€ pro Monat',
        message: 'In wenigen Minuten erhalten Sie eine Bestätigung per E-Mail.'
      },
      table: {
        header: 'Abschlagsplan und Fälligkeiten',
        subheader: 'Ihre nächsten Abschlagszahlungen von monatlich {current} sind fällig am:',
        labels: {
          date: 'Datum',
          status: 'Status'
        }
      },
      errors: {
        noPlanAvailable: 'Im Moment ist kein Abschlagsplan für Ihr Vertragskonto vorhanden.',
        noOpenItems: 'Eine Änderung des Abschlagsplans für ihr Vertragskonto ist im Moment nicht möglich. ',
        blockedByRecentChange: 'Ihre Abschläge wurden vor kurzem angepasst. Deshalb ist eine Änderung zur Zeit nicht möglich.',
        badSolvency: 'Nicht verfügbar',
        general: 'Entweder haben Sie keine Berechtigung zur Anzeige der Verbrauchsübersicht oder es liegt eine technische Störung vor.'
      }
    },
    navigation: {
      labels: {
        DASHBOARD: 'Übersicht',
        USER_PROFILE: 'Persönliche Daten',
        ACCOUNT_OVERVIEW: 'Kontoübersicht',
        CONSUMPTION_OVERVIEW: 'Verbrauchsübersicht',
        CONTRACT_OVERVIEW: 'Vertragsübersicht',
        TELL_A_FRIEND: 'Freunde werben',
        FAQ: 'Fragen & Antworten',
        ANGEBOTE: 'Angebote',
        undefined: ''
      },
      mobileHome: 'Home',
      mobileMenu: 'Menü',
      mobileFaq: 'FAQ'
    },
    logoutPage: {
      header: 'Das war doch einfach!',
      subheader: 'Nutzen Sie die zahlreichen Vorteile Ihres persönlichen Kundenbereichs',
      advertBoxes: {
        leftBox: {
          header: 'Gutscheinwelt',
          message: 'Kennen Sie schon unsere Highlights? Nutzen Sie Vorteile und Rabatte bei vielen Onlineshops und Events',
          button: 'Zu den Gutscheinen'
        },
        rightBox: {
          header: 'Online Service weiter testen',
          message: 'Wenn Sie sich vor Ihrer Registrierung weiter einen Eindruck vom angebotenen Umfang verschaffen möchten, nutzen Sie einfach nochmal unsere Demoversion.',
          button: 'Zur Demoversion'
        }
      }
    },
    personalData: {
      header: 'Persönliche Daten',
      settings: 'Persönliche Einstellungen',
      advertising: 'Werbeeinstellungen',
      communication: 'Online-Kommunikation',
      deleteAccount: 'Online Account löschen',
      deleteThisAccount: 'Dieses Benutzerprofil löschen?',
      deleteAccountInfoIcon: 'Hier können Sie diesen Online-Zugang endgültig löschen. Falls Sie diesen Zugang für den Online Service neu angelegt haben und beim Hinzufügen Ihres Vertrags die Fehlermeldung „Dieser Geschäftspartner ist bereits einem Kunden-Account zugeordnet.“ erschienen ist, existiert noch ein weiteres Online-Konto von Ihnen in unserem System, dem Ihr Vertrag automatisch oder von Ihnen zugeordnet wurde. Sie sollten dann das Online-Konto hier löschen und sich in Ihrem früheren Online-Zugang anmelden. Wenn Sie Ihren früheren Benutzernamen kennen, können Sie mit der Funktion „Passwort vergessen“ Ihre Zugangsdaten wieder herstellen. Haben Sie keine Möglichkeit mehr darauf zuzugreifen, kontaktieren Sie unseren Kundenservice für weitere Hilfe.',
      password: {
        header: 'Passwort',
        subHeader: 'Wir haben eine E-Mail mit einem Code an Ihre E-Mail-Adresse geschickt. Bitte tragen Sie diesen Code hier ein und vergeben Sie ein neues Passwort.',
        change: 'Passwort anpassen',
        changed: 'Ihr Passwort wurde erfolgreich geändert.',
        labels: {
          new: 'Neues Passwort',
          code: '8-stelliger Code'
        },
        hints: {
          code: 'Der 8-stellige Code bestehend aus Großbuchstaben und Zahlen. Dieser wird Ihnen innerhalb weniger Minuten per E-Mail zugesandt. Falls Sie mehrere Codes angefordert haben, verwenden Sie immer den zuletzt zugesandten Code. Vorherige Codes werden ungültig. Wenn Sie in Ihrem E-Mail-Postfach keine E-Mail mit dem Betreff „Passwort zurücksetzen oder ändern“ finden, schauen Sie bitte in Ihrem Spam-Ordner nach.'
        },
        validations: {
          passwordNew: {
            required: 'Bitte wählen Sie ein Passwort.',
            valid: 'Das Passwort muss zwischen 10 und 35 Zeichen lang sein.'
          },
          code: {
            required: 'Code ist erforderlich.'
          }
        }
      },
      contact: {
        header: 'Kontaktdaten',
        change: 'Kontaktdaten anpassen',
        title: 'Hier können Sie ihre Kontaktdaten anpassen',
        emailHint: 'Beachten Sie bitte: Die Änderung der E-Mail-Adresse ist gleichzeitig die Änderung Ihres Benutzernamens. Wenn Sie diese Änderung durchführen, werden Sie aus Sicherheitsgründen abgemeldet. Ab dem nächsten Login ist Ihre neue E-Mail-Adresse Ihr neuer Benutzername.',
        labels: {
          salutation: 'Anrede',
          firstname: 'Vorname oder Name',
          lastname: 'Nachname oder Zusatz',
          email: 'E-Mail-Adresse',
          emailHint: 'Kontakt im Rahmen der Online Services und Vertragsabwicklung',
          code: '8-stelliger Code'
        },
        validations: {
          salutation: {
            required: 'Bitte geben Sie Ihre Anrede ein.'
          },
          code: {
            required: 'Code ist erforderlich.'
          },
          email: {
            required: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
            email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
          }
        },
        message: 'Ihre Kontaktdaten wurden erfolgreich geändert'
      },
      contactHistory: {
        header: 'Online Kontakte',
        errors: {
          contactHistoryUnavailable: 'Leider stehen diese Daten im Moment nicht zur Verfügung.',
          emptyContactHistory: 'Leider stehen diese Daten im Moment nicht zur Verfügung.'
        }
      },
      businessPartner: {
        header: 'Vertragskonto zuordnen',
        intro: 'Bitte benennen Sie uns die Vertragskontonummer eines zwischen Ihnen und Vattenfall bestehenden Vertrages und die dazugehörige Postleitzahl, damit wir Ihre Registrierung mit Ihrer Geschäftspartnernummer verknüpfen können.<br /><br />Die Registrierung im Online Service gilt für alle Energielieferverträge, die zwischen Ihnen und Vattenfall unter derselben Geschäftspartnernummer bestehen oder zukünftig abgeschlossen werden, soweit Vattenfall die Abwicklung dieser Verträge über den Online Service technisch eingerichtet hat.',
        labels: {
          contractAccountNumber: 'Vertragskontonummer',
          postCode: 'Postleitzahl',
          assignBtn: 'Zuordnen',
          backBtn: 'Zurück',
          userProfileName: 'Benutzerprofil'
        },
        messages: {
          infoForAccountNo: 'Die Vertragskontonummer finden Sie auf unseren Schreiben.',
          generalInfo: 'Als Nutzer des Online Service erhalten Sie alle vertragsbezogenen Dokumente online. Über neue Post im Portal informieren wir Sie selbstverständlich per E-Mail.',
          errorMessage: 'Eine Registrierung ist mit diesem Vertragskonto nicht möglich.'
        },
        validations: {
          contractAccountNumber: {
            required: 'Bitte geben Sie Ihre Vertragskontonummer ein.',
            regex: 'Geben Sie eine 12-stellige Vertragskontonummer beginnend mit 83 ein.',
            serverError: 'Eine Registrierung ist mit diesem Vertragskonto nicht möglich.'
          },
          postCode: {
            required: 'Bitte füllen Sie dieses Feld aus.',
            regex: 'Bitte geben Sie 5 Ziffern ein.'
          }
        },
        thankYouPage: {
          header: 'Bestätigung',
          message: 'Folgende zusätzliche Vertragskonten können Sie künftig ebenfalls über den Vattenfall Online Service verwalten'
        }
      }
    },
    bankData: {
      banner: {
        title: 'Bezahldaten verwalten',
        subtitle: 'Den folgenden Daten können Sie Ihre aktuelle Zahlweise entnehmen. Haben Sie mehrere Verträge, so können Sie diese über \'Lieferstelle\', bzw. \'Vertrag\' auswählen. <br />Über die Schaltfläche \'Ändern\' können Sie nun Ihre Zahlungsart anpassen.'
      },
      header: {
        directDebit: 'Ihre aktuelle Zahlungsart ist SEPA-Lastschrift',
        bankTransfer: 'Ihre aktuelle Zahlungsart',
        edit: 'Zahlungsart ändern'
      },
      labels: {
        directDebit: 'Bankeinzug',
        bankTransfer: 'Überweisung',
        iban: 'IBAN',
        bic: 'BIC',
        owner: 'Kontoinhaber (falls abweichend)',
        idNumber: 'Gläubiger-Identifikationsnummer',
        mandatNumber: 'Mandatsnummer',
        reference: 'Verwendungszweck',
        validFrom: 'Gültig ab',
        bankCode: 'Bankleitzahl',
        accountNumber: 'Kontonummer',
        payoutAccount: 'Bankverbindung für Auszahlungen (optional)',
        consent: 'Ich ermächtige die Vattenfall Europe Sales GmbH, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der Vattenfall Europe Sales GmbH auf mein Konto gezogenen Lastschriften einzulösen. <br /><strong>Hinweis:</strong> Ich kann innerhalb von acht Wochen, beginnend mit dem Datum der Belastung, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.'
      },
      validations: {
        iban: {
          required: 'Ihre inländische IBAN besteht aus <strong>22 Zeichen</strong>.<br />Beispiel: <strong>DE23 1000 0000 1234 5678 90</strong>.',
          pattern: 'Ihre inländische IBAN besteht aus <strong>22 Zeichen</strong>.<br />Beispiel: <strong>DE23 1000 0000 1234 5678 90</strong>.'
        },
        bic: {
          required: 'Bitte füllen Sie dieses Feld aus.',
          pattern: 'Bitte geben Sie eine gültige BIC ein.'
        },
        consent: {
          checked: 'Sie müssen dem Lastschriftverfahren zustimmen.'
        }
      },
      vattenfallAccount: {
        info: 'Bitte überweisen Sie fällige Zahlungen auf unser Bankkonto. ',
        iban: 'DE93 5005 0000 0090 0851 35',
        bankCode: '50050000',
        accountNumber: '90085135',
        bic: 'HELADEFFXXX'
      },
      errors: {
        blockedByRecentChange: 'Ihre Eingaben werden gerade verarbeitet.',
        badSolvency: '<h2>Nicht verfügbar</h2><br />Entweder haben Sie keine Berechtigung zur Erteilung einer Einzugsermächtigung oder es liegt eine technische Störung vor.<br /><br />Bitte entschuldigen Sie die Umstände. Vielen Dank.',
        general: 'Entweder haben Sie keine Berechtigung zur Erteilung einer Einzugsermächtigung oder es liegt eine technische Störung vor.'
      },
      sentPage: {
        headers: {
          iban: 'Vielen Dank für Ihre Eingabe. Wir haben Ihre Bankverbindung erhalten.',
          payout: 'Vielen Dank für Ihre Eingabe. Wir haben Ihre Bankverbindung für Auszahlungen erhalten.',
          deleteEZE: 'Vielen Dank für Ihre Eingabe. Wir haben Ihre Bankverbindung und das SEPA-Lastschriftmandat gelöscht.'
        },
        thankYouMessage: '<p>In wenigen Minuten erhalten Sie eine Bestätigung per E-Mail.</p>',
        deletedIban: 'Wir haben Ihr SEPA-Lastschriftmandat gelöscht.'
      }
    },
    nonCommProducts: {
      mail: 'werbewiderspruch@vattenfall.de',
      url: 'vattenfall.de/Datenschutz',
      showUrl: 'vattenfall.de/datenschutz',
      propperUrl: 'http://vattenfall.de/datenschutz',
      orderButton: 'Zahlungspflichtig bestellen',
      thankYouState: 'Vielen Dank. Wir haben Ihren Auftrag erhalten.',
      approval: 'Die oben angegebenen Daten dürfen für das Gymondo - Abo genutzt werden und ich möchte ein Angebot für ein Gymondo - Abo zugesendet bekommen.'
    },
    contractAndDelivery: {
      banner: 'Vertragsübersicht',
      billingAddress: 'Rechnungsadresse',
      postbox: 'Postfach',
      co: 'c/o',
      secondPartner: 'Zweiter Vertragspartner:',
      vkNumber: 'Vertragskonto-Nr.:',
      offlineCommunicationHint: 'Ihre aktuelle Kommunikation mit uns findet auf dem Postweg statt. Stellen Sie jetzt auf Online-Kommunikation um, so bekommen Sie alle wichtigen Dokumente und Informationen zu ihrem Vertrag noch schneller und wir sparen gemeinsam CO<sub>2</sub>.',
      buttons: {
        addNewBP: 'Bestehenden Vertrag hinzufügen',
        changeButton: 'Ändern',
        moveInProcessButton: 'Weiteren Vertrag abschließen',
        reportRelocation: 'Umzug melden',
        retraction: 'Zum Widerruf',
        cancellation: 'Vertrag kündigen',
        tariffDetails: 'Vertragsdetails',
        offlineCommunication: 'Auf Online Kommunikation umstellen'
      },
      contractTypes: {
        STROM: 'Strom',
        GAS: 'Gas',
        NONCOMM: 'Sonstige'
      }
    },
    retraction: {
      banner: 'Widerruf',
      message: 'Hiermit widerrufe ich folgende Vertragserklärung:',
      buttons: {
        cancel: 'Abbrechen',
        confirm: 'Jetzt widerrufen'
      },
      finished: {
        title: 'Vielen Dank, wir haben Ihren Widerruf erhalten!'
      }
    },
    postboxProcess: {
      banner: 'Postfach',
      errorNoDocuments: 'Für dieses Vertragskonto stehen zur Zeit keine Dokumente zur Verfügung.',
      table: {
        date: 'Datum',
        name: 'Name',
        documents: 'Dokument',
        showPdf: 'Dokument anzeigen'
      }
    },
    changeInvoiceAddress: {
      banner: {
        title: 'Rechnungsadresse ändern',
        subtitle: 'Bitte teilen Sie uns mit, an welche Adresse wir zukünftig Ihre Rechnung versenden sollen.'
      },
      tooltips: {
        coName: 'Sollte Ihr Name nicht am Briefkasten stehen, können Sie hier den Namen auf dem Briefkasten eintragen.'
      },
      validations: {
        addressNotFound: 'Die Straße wurde unter der eingegebenen Postleitzahl nicht gefunden. Bitte überprüfen Sie Ihre Eingabe und bestätigen Sie mit "Speichern".',
        postalCode: {
          required: 'Bitte füllen Sie dieses Feld aus.',
          length: 'Bitte geben Sie 5 Ziffern ein.',
          postalCodeError: 'Diese Postleitzahl ist nicht bekannt oder wird von uns nicht beliefert. Bitte prüfen Sie Ihre Eingabe.',
          pattern: 'Diese Postleitzahl ist nicht bekannt oder wird von uns nicht beliefert. Bitte prüfen Sie Ihre Eingabe.'
        },
        street: {
          required: 'Bitte füllen Sie dieses Feld aus.',
          warningNotOnList: 'Die Straße wurde unter der eingegebenen Postleitzahl nicht gefunden. Bitte überprüfen Sie Ihre Eingabe und bestätigen Sie mit "Weiter".'
        },
        houseNumber: {
          required: 'Bitte füllen Sie dieses Feld aus.'
        },
        city: {
          required: 'Bitte füllen Sie dieses Feld aus.'
        }
      },
      responseDI: {
        errors: {
          differentInvoiceAddress: 'Das Ändern der Rechnungsadresse ist für diesen Vertrag nicht möglich.'
        }
      },
      sentPage: {
        header: 'Bestätigung',
        message: 'Die Rechnungsadresse wurde erfolgreich geändert.'
      }
    },
    communication: {
      contractTypes: {
        STROM: 'Strom',
        GAS: 'Gas',
        NONCOMM: 'Sonstige'
      },
      banner: {
        title: 'Online-Kommunikation'
      },
      thankYouPage: {
        header: 'Vielen Dank. Ihre Änderungen wurden gespeichert',
        desc: 'Ihre Kommunikation mit Vattenfall wurde auf elektronischen Versand umgestellt. Dadurch werden Sie Ihre Dokumente in Zukunft schneller erhalten und schonen zusätzlich die Umwelt.'
      },
      header: 'Verträge auf elektronischen Versand umstellen ',
      processIsNotAvailable: 'Dieser Prozess ist nicht verfügbar',
      businessPartner: 'Geschäftspartner auswählen',
      businessPartnerDisabled: 'Geschäftspartner',
      contractAccountNumber: 'Vertragskontonummer',
      way: 'Kommunikationsweg',
      intro: 'Hier haben Sie die Möglichkeit Ihre Kommunikation mit Vattenfall auf den elektronischen Versand umzustellen. Dadurch werden Sie Ihre Dokumente schneller erhalten und schonen zusätzlich die Umwelt.',
      agreedTxt: 'Elektronischer Versand',
      agreeLabel: 'Auf elektronischen Versand umstellen'
    },
    move: {
      date: '',
      currentProduct: 'aktueller Tarif',
      finishButton: 'Zahlungspflichtig bestellen',
      banner: 'Umzug melden',
      optional: ' (optional)',
      required: '* Pflichtfeld ',
      noProductsText: "Aktuell können wir Ihnen keine Umzugstarife anbieten.",
      fullSummary: {
        openDetails: 'Vertragsdaten anzeigen',
        closeDetails: 'Vertragsdaten schließen',
        print: 'Zusammenfassung drucken',
        movingData: 'Umzugsdaten',
        chooseTarif: 'Tarif wählen',
        paymentData: 'Bezahlung',
        title: 'Vielen Dank, wir haben den Auftrag zu Ihrem Umzug erhalten Ihr Auftrag wird nun geprüft.',
        nextStepsIntro: 'Wie geht es jetzt weiter?',
        nextStep1: 'Ihr Auftrag wird bearbeitet. Sie erhalten in Kürze eine Bestätigung zum Auftragseingang mit dem voraussichtlichen Termin zum Lieferbeginn. ',
        nextStep2: 'Zum Lieferbeginn erhalten Sie die Vertragsbestätigung, auch mit den Informationen zu Ihren Abschlägen.',
        nextStep3: 'Nach der Abmeldung Ihrer alten Lieferstelle zum Auszugstermin erhalten Sie eine Auszugsbestätigung und eine Schlussrechnung.',
        intro1: 'Vielen Dank!',
        intro2: 'Wir haben den Auftrag zu Ihrem Umzug erhalten!'
      },
      stepOne: {
        newAddress: 'Neue Lieferadresse',
        invoiceAddress: 'Rechnungsadresse',
        title: 'Umzugsdaten',
        currentAddress: 'Alte Lieferadresse',
        addressTitle: 'Neue Lieferstelle',
        introText: 'Geben Sie hier Ihre neue Adresse, die Zählernummer und Ihr Einzugsdatum an der neuen Lieferadresse ein. Falls Ihnen das Auszugsdatum der alten Lieferadresse schon bekannt ist, tragen Sie dieses unten ein. Den Auszugszählerstand melden Sie uns bitte erst nach der Schlüsselübergabe.',
        form: {
          city: 'Ort *',
          postCode: 'Postleitzahl *',
          houseNumber: 'Hausnummer *',
          street: 'Straße *',
          meterNumber: 'Zählernummer',
          dateLabelMoveIn: 'Einzugsdatum',
          dateLabelMoveOut: 'Auszugsdatum',
          sameAsDeliveryAddress: 'Identisch mit neuer Lieferadresse',
          differentInvoiceAddress: 'Abweichend von neuer Lieferadresse',
          currentAddress: 'Aktuelle Lieferstelle',
          currentTariff: 'Aktueller Tarif',
          contractAccount: 'Vertragskonto'
        },
        validations: {
          postCode: {
            required: 'Bitte füllen Sie dieses Feld aus.',
            length: 'Bitte geben Sie 5 Ziffern ein.',
            empty: 'Für die angegebene Postleitzahl wurden keine Orte gefunden.',
            blacklist: 'Die angegebene Postleitzahl gehört nicht zu unserem Liefergebiet.'
          },
          street: {
            required: 'Bitte füllen Sie dieses Feld aus.',
            incorrect: 'Die Straße wurde unter der eingegebenen Postleitzahl nicht gefunden.'
          },
          houseNumber: {
            required: 'Bitte füllen Sie dieses Feld aus.'
          },
          city: {
            required: 'Bitte füllen Sie dieses Feld aus.'
          },
          meterNumber: {
            required: 'Bitte füllen Sie dieses Feld aus.',
            regex: 'Die Zählernummer darf nur Zahlen, Buchstaben und die Zeichen "-", "_", "/" oder "\\" enthalten und kann max. 18 Zeichen lang sein.'
          },
          deliveryDate: {
            required: 'Bitte füllen Sie dieses Feld aus.'
          },
          moveInDate: {
            required: 'Bitte füllen Sie dieses Feld aus.'
          },
          addressNotFound: 'Die Straße wurde unter der eingegebenen Postleitzahl nicht gefunden. Bitte überprüfen Sie Ihre Eingabe und bestätigen Sie mit "Weiter".'
        }
      },
      stepTwo: {
        addressTitle: 'Rechnungsadresse',
        title: 'Tarif wählen',
        bonusTitle: 'Bonus',
        footnotes: {
          showFootnotes: 'Fußnoten anzeigen',
          hideFootnotes: 'Fußnoten schließen'
        },
        summary: {
          newTarif: 'Neuer Tarif',
          yearlyPrice: 'Jahrespreis',
          productDetails: 'Produkt-/Preisdetails',
          basicPrice: 'Grundpreis',
          consumptionPrice: 'Verbrauchspreis'
        },
        moveProduct: {
          header: 'Ihren Tarif nehmen Sie einfach zu Ihrer neuen Lieferadresse mit.',
          button: 'Tarif mitnehmen'
        },
        alternativeProduct: {
          header: 'Alternativ können Sie einen neuen Tarif wählen',
          button: 'Tarif wählen'
        },
        tariffDelivered: {
          link: 'Sie möchten Ihren derzeitigen Tarif nicht mitnehmen?',
          title: 'Sie möchten Ihren Tarif nicht mitnehmen?',
          info: 'Bitte rufen Sie uns an und wir beraten Sie individuell zu Ihrem Fall.',
          phoneNumber: '0800 1020 0404',
          hours: 'Mo. bis Fr. 8–16 Uhr (kostenfrei)'
        },
        labels: {
          consumptionPriceHT: 'Verbrauchspreise HT',
          consumptionPriceNT: 'Verbrauchspreise NT',
          meterFee: 'Entgelt Zähler',
          euroPerMonth: '€/Monat'
        },
        noTariffBox: {
          title: 'Bei Ihnen ist eine Tarifmitnahme nicht möglich.',
          titleWithCurrentProduct: 'Sie möchten Ihren Tarif nicht mitnehmen?',
          info: 'Wir helfen Ihnen persönlich weiter. Bitte rufen Sie uns an und wir beraten Sie individuell zu Ihrem Fall.',
          infoWithCurrentProduct: 'Bitte rufen Sie uns an und wir beraten Sie individuell zu Ihrem Fall. ',
          phoneNumber: '0800 1020 0404',
          hours: 'Mo. bis Fr. 8–16 Uhr (kostenfrei)'
        },
        noMoveTariff: {
          title: 'Bei Ihnen ist eine Tarifmitnahme nicht möglich. Bitte wählen Sie einen aktuellen Tarif für Ihre neue Adresse aus.'
        }
      },
      stepThree: {
        title: 'Bezahlung',
        userName: 'Name',
        paymentData: 'Bezahldaten',
        paymentType: 'Zahlungsart',
        directDebit: 'Bankeinzug',
        bankTransfer: 'Überweisung',
        iban: 'IBAN',
        bic: 'BIC',
        owner: 'Kontoinhaber',
        ownerEmpty: '(falls abweichend)'
      },
      stepFour: {
        title: 'Bestätigung',
        submitButton: 'Zahlungspflichtig bestellen',
        validation: {
          withdrawal: {
            checked: 'Bitte bestätigen Sie Ihr Einverständnis.'
          }
        }
      },
      summaryPage: {
        title: 'Zusammenfassung',
        introText: 'Bitte lesen Sie die Zusammenfassung der Daten zu Ihrem Umzug sorgfältig durch und bestätigen Sie anschließend mit "Zahlungspflichtig bestellen".',
        infoForMoveOut: 'Bitte beachten Sie, dass Ihr Auszugsdatum baldmöglichst nachgereicht werden muss. Solange uns kein Auszugsdatum vorliegt, kann ihr Umzug nicht abschließend bearbeitet werden. Sie können dies telefonisch innerhalb der Servicezeiten (Mo.-Fr. 8-16 Uhr) unter 0800 1020 0401 (kostenfrei) erledigen.',
        emptyMoveOutInfo: 'Noch offen'
      },
      thxPage: {
        expandButton: 'Vertragsdaten anzeigen',
        collapseButton: 'Vertragsdaten schließen',
        headline: 'Vielen Dank!',
        subHeadline: 'Wir haben den Auftrag zu Ihrem Umzug erhalten!',
        headList: 'Wie geht es jetzt weiter?',
        listItem1: 'Ihr Auftrag wird bearbeitet. Sie erhalten in Kürze eine Bestätigung zum Auftragseingang mit dem voraussichtlichen Termin zum Lieferbeginn. ',
        listItem2: 'Zum Lieferbeginn erhalten Sie die Vertragsbestätigung, auch mit den Informationen zu Ihren Abschlägen.',
        listItem3: 'Nach der Abmeldung Ihrer alten Lieferstelle zum Auszugstermin erhalten Sie eine Auszugsbestätigung und eine Schlussrechnung.',
        meterReadingInfo: 'Optional können Sie jetzt hier Ihren Auszugszählerstand übermitteln. Natürlich ist es ebenso möglich, dies zu einem späteren Zeitpunkt in Ihrem Online-Account zu erledigen.',
        meterReadingLink: 'Auszugszählerstand übermitteln'
      }
    },
    passwordForgotten: {
      banner: {
        title: 'Sie haben Ihr Passwort vergessen?',
        subtitle: 'Nach erfolgreicher Identifizierung schicken wir Ihnen eine E-Mail, mit der Sie Ihr Passwort ändern und Ihren Zugang entsperren können.'
      },
      header: 'Passwort zurücksetzen',
      message: 'Sie können sich mittels E-Mail-Adresse oder Benutzername identifizieren.',
      buttons: {
        send: 'E-Mail anfordern'
      },
      tooltips: {
        identifier: 'Falls Sie dieselbe E-Mail-Adresse in mehreren Vattenfall Online-Accounts hinterlegt haben, verwenden Sie bitte den Benutzernamen zur Identifizierung des betroffenen Accounts.'
      },
      labels: {
        identifier: 'Identifizierung'
      },
      validations: {
        identifier: {
          required: 'Bitte füllen Sie dieses Feld aus.',
          testuser: 'Leider können wir zu Ihrer Eingabe kein Konto finden. Bitte prüfen Sie Ihre Daten und versuchen Sie es erneut.'
        }
      },
      sentPage: {
        header: 'Vielen Dank!',
        message: 'Ihr Passwort wurde erfolgreich geändert.'
      }
    },
    passwordChange: {
      banner: {
        title: 'Passwort ändern',
        subtitle: 'Für einen besseren Schutz empfehlen wir eine Kombination aus Zahlen, Groß- und Kleinbuchstaben sowie Sonderzeichen.'
      },
      header: 'Bitte geben Sie Ihr neues Passwort ein',
      labels: {
        newPassword: 'Neues Passwort',
        repeatPassword: 'Neues Passwort wiederholen'
      },
      validations: {
        newPassword: {
          required: 'Bitte wählen Sie ein Passwort.',
          valid: 'Das Passwort muss zwischen 10 und 35 Zeichen lang sein.'
        },
        repeatPassword: {
          required: 'Bitte wiederholen Sie das gewählte Passwort.',
          inequal: 'Die eingegebenen Passwörter sind nicht identisch.'
        }
      },
      disabledPage: {
        header: 'Passwortänderung nicht möglich',
        resetLinkText: 'Passwort zur&uuml;cksetzen',
        helpLinkText: 'Hilfe &amp; Kontakt',
        message: `<p>Aufgrund des folgenden Fehlers kann Ihr Passwort leider nicht zur&uuml;ckgesetzt werden:<br />
        <br />
        <strong>{reason}</strong><br />
        <br />
        Wenn Sie Ihr Passwort erneut zur&uuml;cksetzen m&ouml;chten, nutzen Sie bitte noch einmal die Funktion {resetLink}. <br /><br />
        Haben Sie Fragen oder suchen einen Ansprechpartner, dann besuchen Sie bitte {helpLink}.</p>`
      },
      sentPage: {
        header: 'Ihr Passwort wurde erfolgreich geändert.',
        userProfileName: 'Benutzerprofil'
      }
    },
    deregister: {
      banner: {
        title: 'Benutzerprofil löschen',
        subtitle: 'Bitte beachten Sie, dass dieser Schritt nicht rückgängig gemacht werden kann. Falls Sie den Kundenservice Online später wieder nutzen möchten, müssen Sie sich neu registrieren.'
      },
      message: 'Möchten Sie sich wirklich deregistrieren? Eine Nutzung des Vattenfall Online Service ist anschließend nicht mehr möglich.',
      button: 'Benutzerprofil endgültig löschen',
      username: 'Benutzerprofil',
      sentPage: {
        banner: 'Benutzerprofil gelöscht',
        header: 'Bestätigung',
        linkText: 'hier',
        message: 'Sie wurden erfolgreich deregistriert.<br /><br />Um sich über aktuelle Neuigkeiten von Vattenfall zu informieren, klicken Sie bitte {link}.'
      }
    },
    passwordValidationIndicator: {
      before: 'Das Passwort muss folgende Kriterien erfüllen:',
      isLongEnough: '10 Zeichen',
      hasBigAndSmallChars: 'Groß- und Kleinbuchstaben',
      hasNumbers: 'Eine Zahl',
      maximumLength: 'Maximal 35 Zeichen'
    },
    loadProfile: {
      contractTypes: {
        ELECTRICITY: 'Strom',
        GAS: 'Gas'
      },
      send: 'Neu berechnen',
      noData: 'Für den ausgewählten Zeitraum sind keine Daten verfügbar.',
      thisWeek: 'Daten aus dieser Woche anzeigen',
      thisMonth: 'Daten aus diesem Monat anzeigen',
      downloadText: 'Die gemessenen Einzelwerte können Sie sich auch als Excel-Datei über den folgenden Link herunterladen: ',
      labelExcel: 'Lastgangdaten in Tabellenform',
      banner: 'Lastgang',
      date: 'Datum',
      fromDate: 'Von Datum',
      toDate: 'Bis Datum',
      title: 'Lastgänge',
      deliveryAddress: 'Lieferstelle',
      contract: 'Vertrag',
      deliveryPoint: 'Marktlokationsnummer',
      sendButton: 'Neu berechnen',
      labels: {
        day: 'Tag',
        thisWeek: 'Diese Woche',
        thisMonth: 'Dieser Monat',
        timePeriod: 'Festgelegter Zeitraum'
      },
      validationsDate: {
        required: 'Bitte geben Sie das Datum im Format TT.MM.JJJJ ein.',
        beforeMinimum: 'Bitte geben Sie ein Datum bis einschließlich {minDateText} ein.',
        afterMaximum: 'Bitte geben Sie ein Datum ab dem {maxDateText} ein.'
      }
    },
    excelFile: {
      title: 'Treten in der Leistungsspalte leere Zellen auf, so ist für diesen Zeitpunkt leider kein Meßwert verfügbar.',
      sheetName: 'Lastgangtabelle',
      workBookName: 'Messwerte_',
      firstColumnHeader: 'Datum/Zeit',
      secondColumnHeader: 'Leistung/kW',
      labels: {
        pointOfDeliveryDesignation: 'MarktlokationsId',
        loadType: 'Lastgangtyp',
        unitOfMeasurement: 'Maßeinheit',
        amountOfMeasurementData: 'Meßperiodenanzahl',
        downloadTime: 'Downloadzeitpunkt'
      }
    },
    installmentPlan: {
      banner: 'Ratenplan vereinbaren',
      subtitle: 'Sie können einen Ratenplan in einem vorgegebenen Rahmen festlegen',
      title: 'Ratenplan zur Begleichung der offenen Forderung festlegen',
      totalValueText: 'Unsere offene Gesamtforderung beträgt:',
      totalValueWithoutDepositText: 'Unsere offene Gesamtforderung ohne Abschläge beträgt:',
      listInformation: {
        li1: 'Die einmaligen <strong>Bearbeitungskosten</strong> betragen {fee}',
        li2: 'Nachdem Sie Startdatum und Ratenhöhe bestimmt haben, können Sie unter “Vorschau anzeigen” Ihren möglichen Ratenplan vorab sehen',
        active1: 'Ihr Ratenplan kann <strong>maximal bis zur nächsten Jahresabrechnung</strong> abgeschlossen werden',
        active2: 'Ihr monatlicher <strong>Zahlbetrag</strong> setzt sich jeweils aus Ihrer <strong>Rate und</strong> Ihrem monatlichen <strong>Abschlag</strong> zusammen',
        inactive1: 'Ihr Ratenplan kann <strong>maximal für 12 Monate</strong> abgeschlossen werden'
      },
      datepickerLabel: 'Wann soll die erste Rate starten?',
      amountOfInstallments: 'Daraus resultierende Ratenanzahl:',
      payment: 'Wie hoch soll die monatliche Rate sein?',
      buttonPreview: 'Vorschau anzeigen',
      buttonCancel: 'Abbrechen',
      goBackToForm: 'Ratenplan ändern',
      sendButton: 'Ratenplan kostenpflichtig abschließen',
      sendButtonWithoutCosts: 'Ratenplan abschließen',
      previewHeader: 'Vorschau Ihres Ratenplans',
      previewIntro: 'Im von Ihnen gewählten Ratenplan sind folgende Forderungen enthalten:',
      previewIntroWithoutDeposit: 'In Ihrem gewählten Ratenplan sind diese offenen Forderungen ohne Abschläge enthalten:',
      previewListInformation: {
        li1: '<strong>Unsere Forderung</strong> in Höhe von <strong>{amount}</strong>',
        li2: 'Die <strong>Bearbeitungskosten</strong> für die Erstellung Ihres Ratenplans von <strong>{fee}</strong>',
        active1: 'Ihre weiterhin monatlich anfallenden <strong>Abschlagszahlungen von {openPartPayment}</strong> während der gesamten Laufzeit Ihres Ratenplans'
      },
      previewIntro2: 'Ihre monatlichen Abschläge sind nicht im Ratenplan enthalten und müssen separat beglichen werden. Hier sind Ihre Zahlungstermine für Ihre Raten:',
      previewIntro3: 'Hier sind Ihre Zahlungstermine für Ihre Raten:',
      tableHeaderLp: 'Rate',
      tableHeaderDate: 'Fälligkeitsdatum',
      tableHeaderValue: 'Zahlbetrag',
      firstRateInfoActive: 'Ihr Zahlbetrag setzt sich jeweils aus Ihrer gewählten Rate und Ihrem monatlichen Abschlag zusammen.<br />Da Sie nur einen glatten Eurobetrag als Rate wählen können, entsteht meist ein Differenzbetrag zwischen der Summe aller Raten und der Gesamtforderung.<br /> Dieser Differenzbetrag und die Bearbeitungskosten werden dem ersten Zahlbetrag zugeschlagen.',
      firstRateInfoInactive: 'Da Sie nur einen glatten Eurobetrag als Rate wählen können, entsteht meist ein Differenzbetrag zwischen der Summe aller Raten und der Gesamtforderung.<br />Dieser Differenzbetrag und die Bearbeitungskosten werden dem ersten Zahlbetrag zugeschlagen.',
      firstRateInfoDepositeNotIncluded: 'Da Sie Ihre monatliche Rate nur als runden Betrag wählen können, kann sich ein geringer Differenzbetrag ergeben, der in der ersten Rate enthalten ist. Dieser Differenzbetrag und die Bearbeitungskosten sind in der ersten Rate enthalten.',
      infoBoxTitle: 'Bitte beachten Sie:',
      infoBoxText: 'Diese Ratenzahlungsvereinbarung wird sofort und ohne Ankündigung ungültig, sobald eine Rate nicht pünktlich oder nicht in voller Höhe bezahlt wird. Die Gesamtforderung ist dann ohne Aufforderung sofort zu zahlen.',
      previewInfo2: 'Diese Ratenzahlungsvereinbarung wird sofort und ohne Ankündigung <strong>ungültig</strong>, wenn Sie eine Rate nicht pünktlich oder nicht in voller Höhe bezahlen.',
      previewInfo3: 'Die Gesamtforderung ist dann ohne Aufforderung <strong>sofort und vollständig</strong> von Ihnen zu zahlen. Bitte halten Sie die vorstehenden Termine daher exakt ein.',
      depositNotIncluded: '<strong>Ihre monatlichen Abschläge sind nicht im Ratenplan enthalten und müssen separat beglichen werden.</strong><br />Beachten Sie bitte hierzu Ihre Zahlungstermine.',
      thxPage: {
        header: 'Sie haben Ihren Ratenplan erfolgreich erstellt',
        installmentsPlan: 'Ihr Ratenplan im Überblick',
        bankData: {
          header: 'Ihre Bezahldaten',
          directDebit: 'Bankeinzug',
          paymentBy: 'Art der Bezahlung',
          accountOwner: 'Kontoinhaber',
          creditInstitute: 'Kreditinstitut',
          iban: 'IBAN',
          bic: 'BIC',
          creditIdentificatorNumber: 'Gläubiger Identifikationsnummer'
        },
        vattenfallAccount: {
          bankTransfer: 'Überweisung',
          bank: 'Frankfurter Volksbank',
          accountLabel: 'Kontonummer',
          bankAaccounNumber: '9008513590085135',
          bankCodeLabel: 'Bankleitzahl',
          info: 'Bitte überweisen Sie fällige Zahlungen auf unser Bankkonto. ',
          iban: 'DE93 5005 0000 0090 0851 35',
          bankCode: '50050000',
          accountNumber: '90085135',
          bic: 'HELADEFFXXX',
          usageLabel: 'Verwendungszweck'
        }
      }
    },
    moveIn: {
      next: 'Weiter',
      previous: 'Zurück',
      back: 'Zur Tarifauswahl',
      tariff: {
        consumptionAnnual1: 'bei ',
        consumptionAnnual2: ' <b>kWh</b> <br />Jahresverbrauch',
        priceAnnual: 'Jahrespreis',
        priceOnce: 'Einmalpreis',
        priceBase: 'Grundpreis',
        meterFee: 'Entgelt Zähler',
        meterFeeInfo: 'Das Entgelt Zähler gilt für eine konventionelle Messeinrichtung. Je nach eingebauter Zählerart ergeben sich unterschiedliche Kosten. Weitere Informationen finden Sie',
        priceConsumptionSingle: 'Verbrauchspreis',
        priceConsumptionHigh: 'Verbrauchspreise HT',
        priceConsumptionNormal: 'Verbrauchspreise NT',
        euro: '€',
        here: 'hier.',
        month: '/Monat',
        euroPerMonth: '€/Monat',
        centPerKwh: 'Cent/kWh',
        contractDuration: 'Mindestvertragslaufzeit',
        contractExtension: 'Vertragsverlängerung',
        priceGuarantee: 'Preisgarantie',
        bonus: 'Bonus',
        resignationPeriod: 'Kündigungsfrist',
        validations: {
          wallboxAgreement: {
            checked: ''
          }
        }
      },
      step0: {
        header: 'Ihr Vattenfall-Experte empfiehlt:',
        buttons: {
          original: 'Tarif beibehalten',
          upsell: 'Jetzt Tarif optimieren'
        },
        selected: {
          original: 'Keine Optimierung gewünscht',
          upsell: 'Ihr Tarif wurde optimiert'
        }
      },
      step1: {
        header: 'Persönliche Daten',
        salutation: 'Anrede',
        firstname: 'Vorname',
        lastname: 'Nachname',
        secondPerson: 'Zweiter Vertragspartner',
        email: 'E-Mail-Adresse',
        emailHint: 'Die Vattenfall Europe Sales GmbH nutzt Ihre E-Mail-Adresse, um Sie im Rahmen unserer Online-Services, zur Vertragsabwicklung und zur Information über eigene, ähnliche Energieprodukte zu kontaktieren. Dem können Sie jederzeit per E-Mail an werbewiderspruch@vattenfall.de widersprechen.',
        phone: 'Telefonnummer',
        phoneHint: 'Geben Sie hier Ihre Festnetz- oder Mobilnummer an, damit wir etwaige Rückfragen mit Ihnen schneller klären können. Bitte geben Sie nur Ziffern ein, z.B. 0171 1234567.',
        companyName1: 'Firmierung',
        companyName2: 'Firmierung Zusatz',
        companyNumber: 'Handelsregisternr.',
        companyCourt: 'Handelsgericht',
        birthDate: 'Geburtstag',
        postCode: 'Postleitzahl',
        city: 'Ort',
        street: 'Straße',
        houseNumber: 'Hausnummer',
        inCareOf: 'c/o',
        inCareOfHint: 'Sollte Ihr Name nicht am Briefkasten stehen, können Sie hier den Namen auf dem Briefkasten eintragen.',
        deliveryAddress: 'Lieferanschrift',
        address: 'Anschrift',
        billingAddress: 'Rechnungsadresse',
        differentBillingAddress: 'Abweichende Rechnungsadresse',
        houseNumberLeadingDigit: 'Hausnummer muss mit einer Ziffer beginnen.',
        addressNotFound: 'Die Straße wurde unter der eingegebenen Postleitzahl nicht gefunden. Bitte überprüfen Sie Ihre Eingabe und bestätigen Sie mit "Weiter".',
        validations: {
          salutation: {
            required: 'Bitte geben Sie Ihre Anrede ein.'
          },
          birthDate: {
            required: 'Bitte geben Sie Ihren Geburtstag ein.'
          },
          email: {
            email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
            required: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
          },
          phone: {
            required: 'Bitte geben Sie eine gültige Telefonnummer ein.',
            phoneNumberFormat: 'Das Format der Telefonnummer ist ungültig.'
          },
          companyName: {
            regex: 'Geben Sie bitte eine gültige Firmierung mit maximal 35 Zeichen ein.'
          },
          companyNumber: {
            regex: 'Bitte geben Sie eine gültige Handelsregisternr. ein. Diese beginnt mit HRA, HRB oder VR.'
          },
          companyCourt: {
            selected: 'Bitte geben Sie ein gültiges Handelsgericht ein.'
          }
        }
      },
      step2: {
        header: 'Angaben zur Belieferung',
        reason: 'Bestellgrund',
        reasonChange: 'Lieferantenwechsel',
        reasonChangeDescriptionPrivate: 'Ich bleibe in meiner Wohnung und möchte von meinem bisherigen Lieferanten zu Vattenfall wechseln.',
        reasonChangeDescriptionCompany: 'Ich bleibe am selben Standort und möchte von meinem bisherigen Lieferanten zu Vattenfall wechseln.',
        reasonMove: 'Umzug / Einzug',
        reasonMoveDescriptionPrivate: 'Ich ziehe in eine neue Wohnung ein oder bin bereits umgezogen und möchte dort von Vattenfall versorgt werden.',
        reasonMoveDescriptionCompany: 'Ich ziehe an einen neuen Standort oder bin bereits umgezogen und möchte dort von Vattenfall versorgt werden.',
        dateNext: 'Nächstmöglicher Termin',
        dateNextHint: 'Die Auswahl „Nächstmöglicher Termin“ hängt von der Vertragslaufzeit und der Kündigungsfrist Ihres derzeitigen Energielieferanten ab. Bei dieser Option werden wir unter den oben genannten Abhängigkeiten den frühestmöglichen Starttermin für Ihre Belieferung anstreben. Falls Sie einen späteren Termin bevorzugen, wählen Sie die Option „Wunschtermin“.',
        dateCustom: 'Wunschtermin',
        meterNumber: 'Zählernummer',
        meterNumberHint: 'Ihre Zählernummer finden Sie auf der Auftragsbestätigung oder Ableseaufforderung Ihrer letzten Energierechnung oder, falls Sie neu eingezogen sind, auf Ihrem Übergabeprotokoll. Natürlich finden Sie die Zählernummer auch immer auf Ihrem Zähler, häufig in der Nähe des Barcodes.',
        meterReading: 'Zählerstand ohne Nachkommastelle',
        meterReadingHint: 'Tragen Sie hier Ihren Zählerstand ohne Nachkommastellen ein. Bitte prüfen Sie Ihre Eingabe, bevor Sie Ihren Zählerstand an uns schicken.',
        meterReadingFuture: 'Am Tag des Liefergebinns einzutragen.',
        previousSupplier: 'Bisheriger Lieferant',
        customerNumber: 'Kundennummer',
        customerNumberHint: 'Ihre Kundennummer bei Ihrem bisherigen Energielieferanten finden Sie in Schreiben zu Vertragsangelegenheiten und Rechnungen von diesem.',
        optional: 'Optional',
        previousSupplierQuestion: 'Haben Sie beim bisherigen Lieferanten bereits gekündigt?',
        yes: 'Ja',
        no: 'Nein',
        date: 'Datum',
        quitDate: 'Kündigungsdatum',
        moveAgreementPrivate: 'Erstbezug nach Neubau: Ich ziehe in einen Neubau oder in eine sanierte Wohnung. Beim Neubau oder bei der Sanierung wurden neue Strom- bzw. Gaszähler installiert.',
        moveAgreementCompany: 'Erstbezug nach Neubau: Ich ziehe in einen Neubau oder an einen sanierten Standort. Beim Neubau oder bei der Sanierung wurden neue Strom- bzw. Gaszähler installiert.',
        moveAgreementHint: 'Wählen Sie diese Option, wenn Sie in einem neu gebauten Haus oder nach einer Renovierung mit neu gesetztem Zähler von Vattenfall beliefert werden möchten. Beachten Sie bitte, dass nicht schon Ihr Vermieter, Verwalter oder Verkäufer auf dem Zähler angemeldet ist. Falls doch, wählen Sie bitte „Einzug / Umzug“.',
        deliveryStart: 'Lieferbeginn',
        validations: {
          dateCustom: {
            required: 'Bitte geben Sie hier Ihren Wunschtermin ein.'
          },
          meterNumber: {
            required: 'Bitte geben Sie hier Ihre Zählernummer ein.',
            meterNumberFormat: 'Die Zählernummer darf keine Leerzeichen oder Fragezeichen enthalten.'
          },
          previousSupplier: {
            required: 'Bitte geben Sie hier Ihren bisherigen Lieferanten ein.',
            listed: 'Der eingegebene Lieferant ist uns leider nicht bekannt. Bitte korrigieren Sie Ihre Eingabe.'
          },
          previousSupplierQuitDate: {
            required: 'Bitte geben Sie hier ein Datum ein.'
          }
        }
      },
      step3: {
        header: 'Zahlungsdaten',
        payment: 'Art der Bezahlung',
        paymentSepa: 'SEPA-Lastschriftmandat',
        paymentTransfer: 'Überweisung',
        paymentTransferInfo: 'Den monatlich zu zahlenden Abschlagsbetrag sowie die Überweisungsdaten bekommen Sie rechtzeitig vor dem Beginn der Belieferung.',
        iban: 'IBAN',
        ibanRequired: 'Bitte tragen Sie hier Ihre Zahlungsdaten für Ihr SEPA-Lastschriftmandat ein.',
        ibanRecommended: 'Wir empfehlen Ihnen die Bezahlung per SEPA-Lastschriftmandat, da Sie sich in diesem Fall um nichts kümmern müssen. Wenn Sie Ihre Abschlagszahlungen monatlich selbst überweisen möchten, klicken Sie einfach auf weiter.',
        ibanSafety: 'Ihre Bezahldaten werden verschlüsselt über einen Sicherheitsserver übertragen.',
        bic: 'BIC',
        owner: 'Kontoinhaber',
        ownerEmpty: '(falls abweichend)',
        ibanAgreement: 'Ich ermächtige die Vattenfall Europe Sales GmbH Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der Vattenfall Europe Sales GmbH auf mein Konto gezogenen Lastschriften einzulösen.<br /><b>Hinweis:</b> Ich kann innerhalb von acht Wochen, beginnend mit dem Datum der Belastung, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.',
        validations: {
          iban: {
            regex: 'Bitte geben Sie eine gültige IBAN ein.'
          },
          bic: {
            regex: 'Bitte geben Sie eine gültige BIC ein.'
          },
          ibanAgreement: {
            checked: 'Sie müssen dem Lastschriftverfahren zustimmen.'
          }
        }
      },
      step4: {
        header: 'Bestellung',
        submit: 'Zahlungspflichtig bestellen',
        cancel: 'Bestellung abbrechen',
        validations: {
          termsAndConditions: {
            checked: ''
          },
          cancelPolicy: {
            checked: ''
          },
          customerConfirmation: {
            checked: 'Bitte bestätigen Sie Ihr Einverständnis.'
          }
        }
      },
      thankYouPage: {
        header: 'Vielen Dank, wir haben Ihre Bestellung erhalten.',
        nextSteps: 'Wie geht es jetzt weiter?',
        pointOne: 'Ihr Auftrag wird nun von uns bearbeitet. In Kürze erhalten Sie dazu eine Auftragseingangsbestätigung per E-Mail.',
        pointTwo: 'Sofern uns der vorgemerkte Lieferbeginn vom Netzbetreiber zugesagt wird, erhalten Sie kurz vor Lieferbeginn Ihre Vertragsbestätigung – auch mit den Informationen zu Ihren Abschlägen.',
        expandButton: 'Vertragsdaten anzeigen',
        collapseButton: 'Vertragsdaten schließen',
        backButton: 'Zur Startseite'
      },
      footnotes: {
        showFootnotes: 'Fußnoten anzeigen',
        hideFootnotes: 'Fußnoten schließen',
        footnoteEmailPhone: 'Zu diesem Zwecke gestatte ich der Vattenfall Europe Sales GmbH, die von mir erhobenen personenbezogenen Daten (Rufnummer, E-Mail-Adresse) zu verarbeiten. Meine Einwilligung in die Nutzung meiner Daten zu Werbezwecken ist für die Vertragserfüllung nicht erforderlich und gilt bis auf Widerruf, den ich jederzeit mit Wirkung für die Zukunft im Online Service auf <a href="https://www.vattenfall.de">www.vattenfall.de</a> oder per Telefon unter 0800 9925 000 erklären kann. Überschneidungen mit bereits versandten Werbemitteilungen sind möglich. Im Falle des Widerrufs entstehen keine anderen Kosten als die Übermittlungskosten nach den Basistarifen.'
      }
    },
    moveInEntry: {
      header: 'Jetzt günstigen Tarif finden',
      private: 'Privatkunden',
      company: 'Gewerbekunden',
      sectors: {
        select: 'Wählen',
        electricity: 'Strom',
        gas: 'Gas'
      },
      labels: {
        consumption: 'Verbrauch kWh/Jahr',
        kwh: 'kWh',
        postalCode: 'Postleitzahl',
        city: 'Ort',
        street: 'Straße',
        houseNo: 'Hausnummer',
        submit: 'Jetzt berechnen',
        selectButtonText: 'Auswählen'
      },
      validations: {
        consumption: {
          required: 'Bitte füllen Sie dieses Feld aus.',
          withinConstraints: 'Bitte geben Sie einen Vorjahresverbrauch zwischen 100 kWh und {max} kWh an.'
        },
        postalCode: {
          required: 'Bitte füllen Sie dieses Feld aus.',
          incorrect: 'Diese Postleitzahl ist nicht bekannt oder wird von uns nicht beliefert. Bitte prüfen Sie Ihre Eingabe.',
          length: 'Bitte geben Sie 5 Ziffern ein.',
          empty: 'Zu der angegebenen Postleitzahl konnten keine Orte ermittelt werden.',
          blacklist: 'Die angegebene Postleitzahl gehört nicht zu unserem Liefergebiet.'
        },
        street: {
          required: 'Bitte füllen Sie dieses Feld aus.',
          incorrect: 'Die Straße wurde unter der eingegebenen Postleitzahl nicht gefunden.'
        },
        houseNo: {
          required: 'Bitte füllen Sie dieses Feld aus.'
        },
        city: {
          required: 'Bitte füllen Sie dieses Feld aus.'
        }
      }
    },
    tariffDetails: {
      banner: 'Tarifdetails',
      basePrice: 'Grundpreis',
      consumptionPrice: 'Verbrauchspreis',
      priceGuarantee: 'Preisgarantie',
      minimumContractLength: 'Mindestvertragslaufzeit',
      resignationPeriod: 'Kündigungsfrist',
      contractExtension: 'Vertragsverlängerung',
      cancelDate: 'Nächstmöglicher Kündigungstermin',
      priceGuaranteeDeadline: 'Preisgarantie',
      until: 'bis ',
      meterFee: {
        header: 'Entgelt Zähler',
        type: 'Zählertyp',
        monthlyCost: 'Monatskosten',
        yearlyCost: 'Jahreskosten',
        netto: 'Netto',
        brutto: 'Brutto'
      },
      dynamicTariff: {
        spotprice: 'Spotmarktpreis',
        spotvalue: 'dynamisch in Cent/kWh',
        transitionalprice: 'Übergangspreis',
        transitionalvalue: 'Ø-Spotmarktpreis in Cent/kWh'
      }
    },
    authorizedPerson: {
      personsHeader: 'Vollmachten',
      banner: 'Vollmacht',
      header: 'Vollmacht pro Vertrag erteilen',
      avaButton: 'Vollmacht verwalten',
      tileSecondHeader: 'Wir wollen Sie vor Datenmissbrauch schützen!',
      tileText: 'Erteilen Sie eine Vollmacht, wenn jemand anderes für Sie vertragsbezogene Auskünfte erhalten und Angelegenheiten klären soll. Liegt keine Vollmacht vor, werden wir niemandem außer Ihnen Auskunft geben.',
      tileListIntro: 'So funktioniert es:',
      tileDot1: 'Klicken Sie auf den Button “Vollmacht verwalten” weiter unten.',
      tileDot2: 'Klicken Sie auf der Folgeseite “Vollmacht erteilen” am entsprechenden Vertragskonto.',
      tileDot3: 'Tragen Sie die Daten des Bevollmächtigten ein und bestätigen Sie Ihre Eingabe mit “Speichern”.',
      tileDot4: 'Im Anschluss wird Ihre Vollmacht am Vertragskonto angezeigt.',
      processIntro: 'Durch eine Vollmacht wollen wir Sie vor Datenmissbrauch durch Unbefugte schützen. Liegt keine Vollmacht vor, werden wir niemandem außer Ihnen (also auch keinem/keiner Ehepartner/-in oder Angehörigen) Auskunft geben.',
      dotIntro: 'Eine Vollmacht beinhaltet die Erlaubnis, in Ihrem Namen:',
      dot1: 'Auskünfte zu Stammdaten (Stammdaten sind: Vor- und Nachname, Akademischer Titel, Namenszusatz, Geburtsdatum, IBAN, Telefonnummer und E-Mail-Adresse, Rechnungsanschrift, Werbeeinverständnis) bzgl. des jeweiligen Vertrages einzuholen',
      dot2: 'Änderungen an diesen Stammdaten vorzunehmen',
      dot3: 'Einsicht in sämtliche Vertragsunterlagen zu nehmen',
      dot4: 'Beendigung des bestehenden Vertrages zu erklären und ein Angebot auf Abschluss eines neuen Vertrages abzugeben',
      processIntro2: 'Sie können Ihre Vollmacht jederzeit ohne Angabe von Gründen im Online Service oder in Textform widerrufen.',
      errors: {
        noVKsExistingForBP: 'Leider steht Ihnen der Prozess aufgrund Ihrer Vertragssituation nicht zur Verfügung.'
      },
      form: {
        name: 'Vorname',
        surname: 'Nachname',
        salutation: 'Anrede',
        addPerson: 'Vollmacht erteilen',
        areYouSure: 'Vollmacht entfernen?',
        yes: 'Ja',
        no: 'Nein',
        cancel: 'Abbrechen',
        delete: 'Entfernen',
        send: 'Speichern'
      },
      validation: {
        salutation: {
          required: 'Bitte geben Sie Ihre Anrede ein.'
        },
        name: {
          required: 'Bitte geben Sie Ihren Vornamen ein.'
        },
        surname: {
          required: 'Bitte geben Sie Ihren Nachnamen ein.'
        }
      }
    },
    signUp: {
      banner: {
        headerHighlights: 'Registrieren Sie sich jetzt bei Vattenfall',
        subHeaderHighlights: 'Verwalten Sie Ihre Verträge online und profitieren Sie von monatlich wechselnden Highlightwelten mit exklusiven Vorteilen nur für Vattenfall Kunden',
        header: 'Registrieren Sie sich jetzt',
        subHeader: 'und nutzen Sie die zahlreichen Vorteile Ihres persönlichen Kundenbereichs'
      },
      salutation: 'Anrede',
      firstName: 'Vorname oder Name',
      lastName: 'Nachname oder Zusatz',
      button: 'Weiter zur Aktivierung',
      email: 'E-Mail-Adresse',
      emailInfo: 'Ihre Eingabe wurde automatisch korrigiert. Alle Großbuchstaben wurden in Kleinbuchstaben umgewandelt, um eine einheitliche Verarbeitung zu ermöglichen.',
      emailHint: 'Die Vattenfall Europe Sales GmbH nutzt Ihre E-Mail-Adresse, um Sie im Rahmen unserer Online Services, zur Vertragsabwicklung und zur Information über eigene, ähnliche Energieprodukte zu kontaktieren. Dem können Sie jederzeit per E-Mail an <a href="mailto:werbewiderspruch@vattenfall.de">werbewiderspruch@vattenfall.de</a> widersprechen.<sup>X1</sup>',
      emailFootnote: '<sup>X1</sup> Zu diesem Zwecke gestatte ich der Vattenfall Europe Sales GmbH, die von mir erhobenen personenbezogenen Daten (Rufnummer, E-Mail-Adresse) zu verarbeiten. Meine Einwilligung in die Nutzung meiner Daten zu Werbezwecken ist für die Vertragserfüllung nicht erforderlich und gilt bis auf Widerruf, den ich jederzeit mit Wirkung für die Zukunft im Online Service auf <a href="https://www.vattenfall.de">www.vattenfall.de</a> oder per Telefon unter 0800 9925 000 erklären kann. Überschneidungen mit bereits versandten Werbemitteilungen sind möglich. Im Falle des Widerrufs entstehen keine anderen Kosten als die Übermittlungskosten nach den Basistarifen.',
      dataPrivacy: 'Die Informationen zum Datenschutz können Sie {link} einsehen.',
      text: 'Sie sind bereits registriert?',
      linkToLogin: 'Dann hier einloggen',
      infoAboutCode: 'Den Aktivierungscode erhalten Sie per E-Mail. Bitte geben sie diesen und Ihr frei gewähltes Passwort ein.',
      validations: {
        dataPrivacy: {
          checked: 'Bitte bestätigen Sie, dass Sie die Informationen zum Datenschutz gelesen und akzeptiert haben.'
        },
        surname: {
          regex: 'Bitte geben Sie Ihren Nachnamen ein.'
        },
        name: {
          regex: 'Bitte geben Sie einen Vornamen ein.'
        },
        email: {
          regex: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
        },
        salutation: {
          required: 'Bitte geben Sie Ihre Anrede ein.'
        }
      },
      bullets: {
        oneHighlights: 'Verwalten Sie Ihre Verträge, wann immer Sie Zeit haben',
        twoHighlights: 'Erkunden Sie spannende Themenwelten',
        threeHighlights: 'Profitieren Sie von attraktiven Rabatten und tollen Gewinnen',
        one: 'Überprüfen Sie Ihre Daten und ändern Sie diese gegebenenfalls ab.',
        two: 'Nach der Bestätigung Ihrer Eingabe mit “Weiter zur Aktivierung” erhalten Sie eine E-Mail mit Ihrem 8-stelligen Aktivierungscode.',
        three: 'Tragen Sie auf der Folgeseite diesen Aktivierungscode ein und vergeben Sie ein Passwort.',
        four: 'Bestätigen Sie danach Ihre Eingabe mit “Registrierung abschließen”.'
      }
    },
    accountActivation: {
      banner: 'Online Service - Nutzerprofil anlegen',
      infoTxt: 'Als Nutzer des Online Service erhalten Sie alle vertragsbezogenen Dokumente online. Über neue Post im Portal informieren wir Sie selbstverständlich per E-Mail.',
      email: 'E-Mail-Adresse',
      code: '8-stelliger Aktivierungscode',
      codeHint: 'Der 8-stellige Aktivierungscode, bestehend aus Großbuchstaben und Zahlen. Dieser wird ihnen innerhalb weniger Minuten per E-Mail zugesandt. Wenn Sie in Ihrem E-Mail-Postfach keine E-Mail mit dem Betreff „Aktivieren Sie Ihren Account“ finden, schauen Sie bitte in Ihrem Spam-Ordner nach.',
      password: 'Passwort',
      repeatPassword: 'Passwortwiederholung',
      dataProtection1: 'Ich bestätige, dass ich die ',
      dataProtection2: ' gelesen und akzeptiert habe.',
      agb1: 'Ich akzeptiere die ',
      agb2: ' des Online Service.',
      dataProtection: 'Informationen zum Datenschutz',
      generalTermsAndCondidtions: 'Allgemeinen Geschäftsbedingungen',
      send: 'Registrierung abschließen',
      validation: {
        repeatPassword: {
          required: 'Bitte wiederholen Sie das gewählte Passwort.',
          equal: 'Die eingegebenen Passwörter sind nicht identisch.'
        },
        email: {
          email: 'Bitte geben Sie hier Ihre E-Mail-Adresse ein.<br/>Beispiel: <strong>mustermann@mustermail.com</strong>',
          required: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
        },
        activationCode: {
          required: 'Aktivierungscode ist erforderlich.'
        },
        password: {
          valid: 'Das Passwort muss zwischen 10 und 35 Zeichen lang sein.',
          required: 'Bitte wählen Sie ein Passwort.'
        },
        agb: {
          checked: 'Bitte bestätigen Sie, dass Sie die AGB zur Nutzung des Online Service akzeptieren.'
        },
        dataProtection: {
          checked: 'Bitte bestätigen Sie, dass Sie die Informationen zum Datenschutz gelesen und akzeptiert haben.'
        }
      }
    },
    contactForm: {
      header: 'Senden Sie uns eine Nachricht',
      subheader: 'Nutzen Sie dieses Formular, um uns eine gesicherte E-Mail zu senden. Sie erhalten eine Bestätigung an Ihre E-Mail-Adresse. Ihre Mitteilung wird aus Datenschutzgründen nicht darin enthalten sein. Diese können Sie jedoch nach dem Absenden speichern.',
      hint: 'Unser Tipp: Nutzen Sie nach Auswahl des Kontaktgrundes den jeweils angebotenen Onlineservice.',
      contactReason: 'Thema',
      message: 'Ihre Mitteilung',
      attachDocument: 'Dokument anfügen',
      attachDocumentHint: 'Sie haben die Möglichkeit ein Dokument mitzuschicken (maximale Größe 10 MB). Dateinamen sollten keine Leerzeichen enthalten. Sie können folgende Dateiformate hochladen: pdf, doc, docx, xls, xlsx, jpg, gif, png, zip. ',
      areYouACustomer: 'Haben Sie ein Anliegen zu einem bereits abgeschlossenen Vertrag?',
      isCustomerInfo: 'Hinweis: Falls Sie selbst nicht Vertragsinhaber sind, benötigen Sie eine Vollmacht, um Auskunft zu erhalten. So schützen wir unsere Kunden vor Datenmissbrauch durch Unbefugte. Die Vollmacht kann unter <a href="https://www.vattenfall.de/vollmacht">vattenfall.de/vollmacht</a> erteilt werden.',
      personalData: 'Persönliche Daten',
      salutation: 'Anrede',
      firstname: 'Vorname',
      lastname: 'Name',
      contractAccountNumber: 'Vertragskontonummer',
      email: 'E-Mail-Adresse',
      phone: 'Telefonnummer tagsüber',
      agreement: 'Die Informationen zum Datenschutz können Sie <a target="_blank" href="//vattenfall.de/datenschutz">hier</a> einsehen.',
      cancel: 'Abbrechen',
      submit: 'Absenden',
      attachment: 'Anhang',
      confirmation: 'Bestätigung',
      requestSent: 'Folgende Anfrage wurde an unseren Kundenservice geleitet:',
      contactInfo: 'Vielen Dank für Ihre Nachricht. Wir werden Ihre Anfrage schnellstmöglich bearbeiten.',
      validations: {
        attachment: {
          fileSizeValidator: 'Anhänge dürfen die Größe von 10 MB nicht überschreiten.',
          fileTypes: 'Dieses Dateiformat wird nicht unterstützt. Nutzen Sie bitte nur: pdf, doc, docx, xls, xlsx, jpg, gif, png, zip.'
        },
        contactReason: {
          required: 'Bitte wählen Sie aus.'
        },
        message: {
          required: 'Bitte füllen Sie dieses Feld aus.'
        },
        salutation: {
          required: 'Bitte geben Sie Ihre Anrede ein.'
        },
        contractAccountNumber: {
          regex: 'Ihre Vertragskontonummer besteht nur aus Ziffern und beginnt mit 83.'
        },
        email: {
          email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
          required: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
        },
        phone: {
          phoneNumberFormat: 'Bitte geben Sie eine gültige Telefonnummer.'
        },
        agreement: {
          checked: ''
        }
      }
    },
    logout: {
      text: 'Wir melden Sie ab...'
    },
    tariffChange: {
      banner: 'Tarifwechsel',
      moreInfo: {
        expand: 'Details zum Tarif',
        collapse: 'Details schließen'
      },
      noProductsText: 'Aktuell können wir Ihnen keine Wechseltarife anbieten.',
      order: 'Jetzt wechseln',
      selectChangeDate: 'Wechseltermin festlegen',
      submit: 'Zahlungspflichtig bestellen',
      backToProductsList: 'Zurück zur Tarifauswahl',
      thankYouPage: {
        header: 'Vielen Dank. Wir haben Ihre Tarifänderung erhalten.',
        nextSteps: 'Wie geht es jetzt weiter?',
        pointOne: 'Ihr Auftrag wird nun bearbeitet. In Kürze erhalten Sie eine Bestätigung per E-Mail. ',
        pointTwo: 'Ihr Tarifwechsel erfolgt nach Ihrem Wunsch zum {changeDate}.',
        pointThree: 'Ihr bestehender Vertrag endet einen Tag vor dem gewünschten Wechseltermin.',
        expandButton: 'Vertragsdaten anzeigen',
        collapseButton: 'Vertragsdaten schließen',
        backButton: 'Zur Startseite'
      },
      validations: {
        agreement: {
          checked: 'Bitte bestätigen Sie Ihr Einverständnis.'
        },
        changeDate: {
          required: ''
        }
      }
    },
    maintenance: {
      title: 'Wartungsarbeiten',
      paragraph1: 'Sehr geehrter Besucher,',
      paragraph2: 'aufgrund von Optimierungsarbeiten ist unser Online Service derzeit nicht verfügbar. In Kürze sind wir mit unseren Services wieder wie gewohnt für Sie da.',
      paragraph3: 'Wir bitten um Ihr Verständnis.'
    },
    genericPage: {
      header: 'Meinen Code eingeben',
      subheader: 'Geben Sie bitte Ihren 8-stelligen Code ein.',
      code: 'Code',
      submit: 'Senden',
      validation: {
        code: {
          required: 'Code ist erforderlich.'
        }
      },
      signUp: {
        banner: {
          header: 'Persönlichen Zugang aktivieren',
          subHeader: 'und zahlreiche Vorteile Ihres persönlichen Kundenbereichs nutzen'
        },
        email: 'E-Mail-Adresse',
        emailHint: "Die Vattenfall Europe Sales GmbH nutzt Ihre E-Mail-Adresse, um Sie im Rahmen unserer Online-Services, zur Vertragsabwicklung und zur Information über eigene, ähnliche Energieprodukte zu kontaktieren. Dem können Sie jederzeit per E-Mail an <a href={'mailto:werbewiderspruch@vattenfall.de'}>{'werbewiderspruch@vattenfall.de'}</a> widersprechen.<sup>X1</sup>",
        salutation: 'Anrede',
        firstName: 'Vorname oder Name',
        lastName: 'Nachname oder Zusatz',
        button: 'Weiter zur Aktivierung',
        validations: {
          salutation: {
            required: 'Bitte geben Sie Ihre Anrede ein.'
          }
        },
        bullets: {
          one: 'Überprüfen Sie Ihre Daten und ändern Sie diese gegebenenfalls ab.',
          two: 'Nach der Bestätigung Ihrer Eingabe mit “Weiter zur Aktivierung” erhalten Sie eine E-Mail mit Ihrem 8-stelligen Aktivierungscode.',
          three: 'Tragen Sie auf der Folgeseite diesen Aktivierungscode ein und vergeben Sie ein Passwort.',
          four: 'Bestätigen Sie danach Ihre Eingabe mit “Registrierung abschließen”.'
        }
      },
      activation: {
        bullets: {
          one: 'Ihren Aktivierungscode erhalten Sie jetzt per E-Mail.',
          two: 'Bitte geben Sie diesen hier ein und vergeben Sie ein Passwort, um Ihren Zugang zu schützen.',
          three: 'Bestätigen Sie danach Ihre Eingabe mit “Registrierung abschließen”.'
        }
      },
      login: {
        text: 'Sie sind bereits registriert? Loggen Sie sich hier ein.'
      }
    },
    footer: {
      tariffsAndOffers: 'Tarife & Angebote',
      onlineServices: 'Online Services',
      vattenfallOffers: 'Vattenfall Angebote',
      otherLanguages: 'Andere Sprachen',
      aboutVattenfall: 'Über Vattenfall',
      electricityTariifs: 'Stromtarife',
      gasTariffs: 'Gastarife',
      heatTariffs: 'Wärmetarife',
      electricityForCars: 'Strom für Elektroautos',
      smartHome: 'Smart Home',
      reportMove: 'Umzug melden',
      enterMeterReading: 'Zählerstand mitteilen',
      changePartPayment: 'Abschläge ändern',
      changeTariff: 'Tarif wechseln',
      reportFault: 'Störung melden',
      vacancyPortal: 'Leerstandsportal',
      myHighlights: 'my Highlights',
      vattenfallShop: 'Vattenfall Shop',
      tellAFriend: 'Freunde werben',
      forEnergyCompanies: 'Für Energieunternehmen',
      infoWorldEnergy: 'Infowelt Energie',
      bewareOfFrauds: 'Vorsicht Falle',
      latePayment: 'Zahlungsverzug',
      sustainability: 'Nachhaltigkeit',
      energyInFocus: 'Energie im Fokus',
      newsroom: 'Newsroom',
      career: 'Karriere',
      imprint: 'Impressum',
      termsOfService: 'Nutzungsbedingungen',
      privacy: 'Datenschutz',
      glossary: 'Glossar',
      contact: 'Hilfe & Kontakt',
      cookieSettings: 'Cookie-Einstellungen',
      faq: 'Fragen & Antworten',
      saveEnergy: 'Energie sparen',
      inside: 'Inside Vattenfall',
      deutschland: 'Vattenfall Deutschland',
      group: 'Vattenfall Group',
      climate: 'Klimaneutrale Website'
    },
    notFoundPage: {
      header: 'Die Seite, nach der Sie suchen, konnte nicht gefunden werden.',
      subheader: 'Vielleicht können Sie den von Ihnen gewünschten Inhalt über die Startseite finden.',
      button: 'Zur Startseite'
    },
    dynamicTariffs: {
      banner: 'Verbrauch und Kosten',
      title: 'Aktuelle Informationen zu den Börsenstrompreisen und Verbräuchen',
      hint: 'Aktuelle Informationen zu den Börsenstrompreisen finden Sie <a target="_blank" href="https://www.vattenfall.de/strom/tarife/oekostrom-dynamik-boersenpreise"> hier. </a>',
      send: 'Neu berechnen',
      meter: 'Zählernummer',
      location: 'Marktlokations-ID',
      processIsNotAvailable: 'Eine detaillierte Berechnung des Spotmarktpreises des jeweiligen Liefermonats (auf Stundenbasis) können Sie zum Ende des Kalendermonats runterladen bzw. einsehen',
      excelFile: {
        headline: 'Detaillierte Berechnung des Spotmarktpreises im jeweiligen Liefermonat*',
        button: 'Excel-Export herunterladen',
        name: 'Daten_',
        firstColumn: 'Liefertag',
        secondColumn: 'Lieferstunde',
        thirdColumn: 'Verbrauch in kWh',
        fourthColumn: 'Markträumungspreis in Cent/kWh**',
        fifthColumn: 'Summe für die Lieferstunde'
      },
      noData: 'Für den ausgewählten Zeitraum sind keine Daten verfügbar.',
      date: 'Datum',
      labels: {
        day: 'Tag',
        week: 'Woche',
        month: 'Monat'
      },
      validationsDate: {
        required: 'Bitte geben Sie das Datum im Format TT.MM.JJJJ ein.',
        beforeMinimum: 'Bitte geben Sie ein Datum bis einschließlich {minDateText} ein.',
        afterMaximum: 'Bitte geben Sie ein Datum ab dem {maxDateText} ein.'
      }
    },
    tariffOffer: {
      headline: "Unsere Empfehlung",
      text1: "Wir freuen uns, dass Sie Kunde bei uns sind und möchten Sie gerne halten.",
      text2: "Jetzt neuen Tarif wählen und Treue-Bonus sichern!",
      submit: "Zum Angebot"
    }
  }
}
export default messages
