import Client from '../Client'
import ErrorResponse from '../error/ErrorResponse'
import Request from '../Request'
import Response from '../Response'
import type Strategy from './Strategy'
import { type StrategyType } from './StrategyType'

/**
 * Strategy retry.
 *
 * Handles response by performing a retry.
 */
export default class StrategyRetry implements Strategy {
  /**
   * @inheritdoc
   */
  public type: StrategyType = 'RETRY'

  /**
   * Instantiates a new retry strategy.
   */
  public constructor (client: Client) {
    this.client = client
  }

  /**
   * @inheritdoc
   */
  public async handle (response: Response): Promise<Response> {
    const { request } = response
    if (!request.canRetry) {
      throw new ErrorResponse(response)
    }
    return this.retry(request)
  }

  /**
   * Retries request.
   */
  protected async retry (request: Request): Promise<Response> {
    request.doRetry()
    return this.client.send(request)
  }

  /**
   * Underlying client.
   */
  protected client: Client
}
