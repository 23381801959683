import StorageAbstract from './Storage'

/**
 * Permanent storage using session storage under the hood.
 */
export default class StorageSession extends StorageAbstract {
  /**
   * @inheritdoc
   */
  protected createStorage (): Storage {
    return this.getSessionStorage()
  }
}
