<template>
  <div class="cso-wrong-date">
    <cso-box>
      <h3>{{ $t('general.dearVisitor') }}</h3>
      {{ $t('general.wrongDate') }}
    </cso-box>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CsoWrongDate'
}
</script>

<style lang="scss">
.cso-wrong-date {
  padding: 80px 0 0 0;
  background-image: url('/static/images/maintenance.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  height: 90vh;

  .cso-box {
    margin: 120px 0 0 0;
    max-width: 460px;
  }
}
</style>