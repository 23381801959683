import ApplicationInsights from '@/api/application-insights/ApplicationInsights'

export default new ApplicationInsights({
  config: {
    endpointUrl: 'https://westeurope-4.in.applicationinsights.azure.com/v2/track',
    disableCookiesUsage: true,
    enableSessionStorageBuffer: true,
    loggingLevelConsole: 0,
    loggingLevelTelemetry: 0,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: ['ecp*.vattenfall.de', '*google*', '*m-pathy.com']
  }
})
