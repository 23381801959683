const ComponentsStates = {
  // component is loading
  loading: 'loading',
  // component is loading without showing spinner
  silentLoading: 'silentLoading',
  // there was non-recoverable error in the component
  error: 'error',
  // same as above, but without any message
  simpleError: 'simpleError',
  // process is unavailable
  unavailable: 'unavailable',
  // ready state for register process
  registerProcess: 'registerProcess',
  // ready state for account activation process
  activationProcess: 'activationProcess',
  // component has received the data and is ready
  ready: 'ready',
  // component is ready for the next step
  readyNextStep: 'readyNextStep',
  // component is ready
  activateAccount: 'activateAccount',
  // component requires user to login
  login: 'login',
  // component is disabled
  disabled: 'disabled',
  // user flow has finished
  finished: 'finished',
  // user flow has finished with rejecting offer
  reject: 'reject',
  // component is not available + messages
  unavailableMessage: 'unavailableMessage',
  // user has to confirm changes
  confirm: 'confirm',
  // ip blocked
  ipBlocked: 'ipBlocked',
  // maintenance mode
  maintenanceMode: 'maintenanceMode',
  // wrong date
  wrongDate: 'wrongDate'
}

export default ComponentsStates
