import type Cacheable from './Cacheable'
import type Collection from './Collection'
import Storage from '../storage/Storage'

/**
 * Cache service.
 */
export default class Cache {
  /**
   * Storage key for cache.
   */
  public static readonly STORAGE_KEY = 'cache'

  /**
   * Instantiates a new cache service.
   */
  public constructor (storage: Storage) {
    this.storage = storage
  }

  /**
   * Checks whether item is in the cache.
   */
  public has<T = unknown> (item: Cacheable<T>): boolean {
    const value = this.get<T>(item)
    return this.isHit(value)
  }

  /**
   * Gets item from the cache.
   */
  public get<T = unknown> (item: Cacheable<T>): T | undefined {
    const collection = this.getCollection()
    const value = collection[item.cacheKey]
    return this.isHit<T>(value) ? value : undefined
  }

  /**
   * Sets item in the cache.
   */
  public set<T = unknown> (item: Cacheable<T>): void {
    const collection = this.getCollection()
    collection[item.cacheKey] = item.cacheValue
    this.setCollection(collection)
  }

  /**
   * Gets collection of all items stored in the cache.
   */
  protected getCollection (): Collection {
    return this.storage.get(Cache.STORAGE_KEY) || {}
  }

  /**
   * Sets collection of all items stored in the cache.
   */
  protected setCollection (collection: Collection): void {
    this.storage.set(Cache.STORAGE_KEY, collection)
  }

  /**
   * Returns whether given value was hit.
   */
  protected isHit<T> (value: unknown): value is T {
    return value !== undefined
  }

  /**
   * Underlying storage to be used for caching.
   */
  protected storage: Storage
}
