import * as DI from '@/api/digital-interface/client'

/**
 * Token null interceptor.
 *
 * Handles null token.
 */
export default class TokenNull implements DI.Interceptor {
  /**
   * @inheritdoc
   */
  public async intercept (context: DI.InterceptorContext): Promise<void> {
    try {
      await context.proceed()
    } catch (error) {
      this.handleError(context, error)
    }
  }

  /**
   * Handles error.
   */
  protected handleError (context: DI.InterceptorContext, error: any) {
    if (error instanceof DI.ErrorTokenNull) {
      context.response = DI.Response.createEmpty(context.request)
    } else {
      throw error
    }
  }
}
