<template>
  <div :class="['row', customClass]">
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'CsoGridRow',
  props: {
    customClass: {
      type: String,
      required: false
    }
  }
}
</script>
