import { type DateString, type Language } from './core/model-shared'

/**
 * Configuration object.
 *
 * Passed into a new client instance.
 */
export default class Config {
  /**
   * Validates configuration and throws on error.
   */
  public static validate (config: Config): void {
    if (config.subscriptionKey === undefined) {
      throw new Error('Required "subscriptionKey" key not supplied in config')
    }
    if (config.client === undefined) {
      throw new Error('Required "client" key not supplied in config')
    }
    if (config.principal === undefined) {
      throw new Error('Required "principal" key not supplied in config')
    }
    if (config.language === undefined) {
      throw new Error('Required "language" key not supplied in config')
    }
    if (config.host === undefined) {
      throw new Error('Required "host" key not supplied in config')
    }
    if (config.stateSince && !Date.parse(config.stateSince)) {
      throw new Error('Invalid "stateSince" date format in config')
    }
  }

  /**
   * Default subscription key used for request.
   */
  public subscriptionKey!: string

  /**
   * Default client used for request.
   */
  public client!: string

  /**
   * Default principal used for request.
   */
  public principal!: string

  /**
   * Default language used for request.
   */
  public language!: Language

  /**
   * Default api host used for request.
   */
  public host!: string

  /**
   * Default state since date to be used for request.
   */
  public stateSince?: DateString
}
