<template>
  <div class="banner-wrapper"
        :class="{'cso-banner-open-area': !isClosedArea}">
    <h1 class="header-title">
        {{ visibleHeader }}
    </h1>
    <cso-button v-if="button"
                modifier="custom-dark"
                @click="button.callback"
                :label="button.text"></cso-button>
  </div>
</template>

<script lang="ts">
import CsoRouter from '@/api/CsoRouter'
import CsoButton from '@/components/core/composition/CsoButton.vue'

export default {
  name: 'CsoBanner',
  components: {
    CsoButton
  },
  props: {
    title: {
      type: String,
      required: false
    },
    salutation: {
      type: String,
      required: false,
      default: ''
    },
    button: {
      type: Object,
      required: false
    }
  },
  computed: {
    visibleHeader () {
      return (!this.title && this.salutation) ? `${this.salutation}` : this.title
    },
    isClosedArea (): boolean {
      return !CsoRouter.isOpenArea
    }
  }
}
</script>

<style lang="scss">

.banner-wrapper {
  @include hidden-print();
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1320px;
  margin: 36px auto 0 auto;

  @include sm {
    padding-left: 15px;
    padding-right: 5px;
    margin: 32px auto 0 auto;
    flex-direction: column;
  }

  &.cso-banner-open-area {
    padding-left: 10px;
    padding-right: 10px;
  }

  h1 {
    color: $black;
    font-size: 48px;
    font-family: $font-family-display;
    line-height: normal;
    margin: 0;

    @include md {
      font-size: 28px;
    }

    @include sm {
      align-self: flex-start;
    }
  }

  & > button {
    @include sm {
      margin: 20px 0;
    }
  }
}
</style>
