import * as DI from '@/api/digital-interface/client'

/**
 * Demo interceptor.
 *
 * Mocks demo user calls.
 */
export default class Demo implements DI.Interceptor {
  /**
   * @inheritdoc
   */
  public async intercept (context: DI.InterceptorContext): Promise<void> {
    if (this.shouldMockResponse(context)) {
      await this.mockResponse(context)
    } else {
      await context.proceed()
    }
  }

  /**
   * Returns whether response for this request should be mocked.
   */
  protected shouldMockResponse (context: DI.InterceptorContext) {
    const { token, endpoint } = context.request
    return token && token.isDemo() && Demo.MOCKED_ENDPOINTS.includes(endpoint)
  }

  /**
   * Mocks response.
   */
  protected async mockResponse (context: DI.InterceptorContext) {
    const { request, request: { version, endpoint } } = context
    const slug = endpoint === '/Inhalte/GetDateien'
      ? `-${(request.body as any).Request.Dateien[0].DateiId}`
      : ''
    const mockUrl = `/demo/${version}${endpoint}${slug}.json`
    const mock = await fetch(mockUrl)
    const mockBody = await mock.json()
    context.response = new DI.Response(200, {}, mockBody, request)
  }

  /**
   * List of mocked endpoints.
   *
   * Not all endpoints are mocked. Some simple and non-contextual endpoints,
   * like /Inhalte/GetInhalt, are passed through to digital interface.
   */
  protected static MOCKED_ENDPOINTS = [
    '/Account/GetAccount',
    '/Arbeitsauftraege/GetArbeitsauftrag',
    '/Inhalte/GetDateien',
    '/Inhalte/GetKundendokumente',
    '/Opts/GetOpts',
    '/Produkt/GetWechselprodukte',
    '/Prozesse/GetProzesse',
    '/Prozesse/GetProzessinformation',
    '/Validierung/CheckFelder',
    '/Vertrag/GetLastgang',
    '/Vertrag/GetVertraege',
    '/Vertrag/GetVertragsliste',
    '/Zaehlerstand/GetZaehlerstaende',
    '/Zahlung/GetZahlungsinformation'
  ]
}
