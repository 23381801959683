import Context from '../Context'
import ErrorTokenNull from '../../error/ErrorTokenNull'
import type Interceptor from '../Interceptor'

/**
 * Authenticator interceptor.
 *
 * Handles authentication logic.
 */
export default class Authenticator implements Interceptor {
  /**
   * @inheritdoc
   */
  public async intercept (context: Context): Promise<void> {
    if (this.isTokenNull(context)) {
      throw this.createErrorTokenNull(context)
    }
    await context.proceed()
  }

  /**
   * Returns whether token is null.
   */
  protected isTokenNull (context: Context): boolean {
    const { token, endpoint } = context.request
    const isAnonymousEndpoint = /\/Account\/GetToken/.exec(endpoint)
    return !token && !isAnonymousEndpoint
  }

  /**
   * Creates token null error.
   */
  protected createErrorTokenNull (context: Context): ErrorTokenNull {
    return new ErrorTokenNull(context.request)
  }
}
