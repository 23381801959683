import ApplicationInsights from '@/api/application-insights/ApplicationInsights'
import * as DI from '@/api/digital-interface/client'

/**
 * Logger interceptor.
 *
 * Logs digital interface errors in application insights.
 */
export default class Logger implements DI.Interceptor {
  /**
   * Instantiates a new logger interceptor.
   */
  public constructor (ai: ApplicationInsights) {
    this.ai = ai
  }

  /**
   * @inheritdoc
   */
  public async intercept (context: DI.InterceptorContext): Promise<void> {
    try {
      await context.proceed()
    } catch (error) {
      this.handleError(error, context)
    }
  }

  /**
   * Handler error.
   */
  protected handleError (error: unknown, context: DI.InterceptorContext) {
    if (this.shouldLogError(error)) {
      this.log(error, context)
    }
    throw error
  }

  /**
   * Returns whether given digital interface error
   * should be logged in application insights.
   */
  protected shouldLogError (error: unknown): error is Error {
    if (error instanceof DI.ErrorResponse) {
      return error.response.body.StatusCode === 'SYSTEM_ERROR'
    } else {
      return true
    }
  }

  /**
   * Logs digital interface error in application insights.
   */
  protected log (error: Error, context: DI.InterceptorContext): void {
    this.ai.trackDigitalInterfaceError(error, context.request, context.response)
  }

  /**
   * Application insights client.
   */
  protected ai: ApplicationInsights
}
