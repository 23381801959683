import di from '@/api/digital-interface'
import CsoRouter from '@/api/CsoRouter'
import store from '@/store'
import LoggingService from '@/common/LoggingService'
import MultipleTabs from '@/common/MultipleTabs'
import Utils from '@/common/Utils'
import StaticUrls from '@/common/StaticUrls'
import PropertyService from '@/common/PropertyService'

export default class Authentication {
  static async login (params) {
    LoggingService.log(LoggingService.eventTypes.app, 'Login from Authentication class')
    if (params.code) {
      await di.v13.authenticateUserFromMagicLink({...params, ...Utils.getUserAgent()})
    } else if (params.username) {
      await di.v13.authenticateUserFromCredentials({...params, ...Utils.getUserAgent()})
    } else if (params.demo) {
      await di.v13.authenticateDemo()
    }
    if (params.middleware) {
      await params.middleware()
    }
    store.clearAll()
    MultipleTabs.update()
    await store.fetchVertragsliste()
    CsoRouter.isOpenArea = false
    const deeplink = CsoRouter.findInRoutes('/' + CsoRouter.router?.currentRoute?.value?.params?.deepLink)
    const userPopup = di.token.isSource('KSO_DB') ? 'true' : undefined
    const domainPopup = di.token.username.includes('@energie.check24.de') ? undefined : undefined
    if (deeplink) {
      CsoRouter.router.push({ path: deeplink.path, query: { userPopup, domainPopup } })
    } else {
      CsoRouter.router.push({ name: 'dashboard', query: { userPopup, domainPopup } })
    }
  }

  static async logout (params) {
    LoggingService.log(LoggingService.eventTypes.app, 'Logout from Authentication class')
    const redirect = di.token?.isDemo() ? StaticUrls.demoUserThankYouPage 
              : PropertyService.isPrd() ? StaticUrls.logoutPage 
              : StaticUrls.login
    if (di.token && !di.token.isAnonymous()) {
      Utils.windowLoading(true)
      await di.v13.deauthenticate()
      store.clearAll()
      MultipleTabs.update()
      di.resetParentLogId()
      await di.v13.authenticateAnonymous()
      CsoRouter.isOpenArea = true
      Utils.windowLoading(false)
    }
    if (params?.redirect !== false) {
      window.location.href = redirect
    }
  }
}
