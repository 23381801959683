import type Cacheable from './cache/Cacheable'
import type HttpHeaders from './http/Headers'
import type HttpResponse from './http/Response'
import Request from './Request'
import { Message, ResponseBody } from './core/model-shared'

/**
 * Response.
 */
export default class Response<X = unknown, Y = unknown> implements HttpResponse<ResponseBody<Y>>, Cacheable<HttpResponse<ResponseBody<Y>>> {
  /**
   * The http status code of the response.
   */
  public status: number

  /**
   * The headers of the response.
   */
  public headers: HttpHeaders

  /**
   * The body of the response.
   */
  public body: ResponseBody<Y>

  /**
   * The request that returned this response.
   */
  public request: Request<X, Y>

  /**
   * Creates empty response.
   */
  public static createEmpty (request: Request) {
    return new Response(0, {}, {
      LogId: '',
      Meldungen: [],
      ParentLogId: request.body.ParentLogId!,
      Result: undefined,
      StatusCode: 'OK'
    }, request)
  }

  /**
   * Instantiates a new response.
   */
  public constructor (status: number, headers: HttpHeaders, body: ResponseBody<Y>, request: Request<X, Y>) {
    this.status = status
    this.headers = headers
    this.body = body
    this.request = request
  }

  /**
   * @inheritdoc
   */
  public get cacheKey (): string {
    const { version, endpoint } = this.request
    const body = this.request.body as any
    return `${version} ${endpoint} ${body.Sprache}`
  }

  /**
   * @inheritdoc
   */
  public get cacheValue (): HttpResponse<ResponseBody<Y>> {
    return {
      status: this.status,
      headers: this.headers,
      body: this.body
    }
  }

  /**
   * Returns message by code.
   */
  public getMessage (code: string): Message | undefined {
    return this.body.Meldungen.find(message => message.Code === code)
  }
}
