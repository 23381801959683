<template>
  <div>
    <cso-navigation v-if="showNavigation"></cso-navigation>
    <div class="page-wrapper">
      <div>
        <cso-banner v-if="showBanner"
                    :title="bannerTitle"
                    :salutation="bannerSalutation"
                    :lastname="bannerLastname">
        </cso-banner>
        <div class="container no-padding-mobile"
             v-if="showContractSelector">
          <cso-contract-selector @loaded="contractLoaded"
                                 @failedLoadingContracts="failedLoadingContracts"
                                 @loadedContracts="loadedContracts"></cso-contract-selector>
        </div>
        <div class="cso-page page-wrapper">
          <div class="container">
            <slot></slot>
            <section v-if="showGoToTopButton"
                     class="cso-page-structure">
              <cso-go-to-top />
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CsoBanner from '@/components/core/business/CsoBanner.vue'
import CsoContractSelector from '@/components/core/business/CsoContractSelector.vue'
import CsoGoToTop from '@/components/core/composition/CsoGoToTop.vue'
import CsoNavigation from '@/components/core/composition/CsoNavigation.vue'

export default {
  name: 'cso-page-wrapper',
  components: {
    CsoBanner,
    CsoContractSelector,
    CsoGoToTop,
    CsoNavigation
  },
  props: {
    bannerTitle: {
      type: String,
      required: false
    },
    bannerSalutation: {
      type: String,
      required: false
    },
    bannerLastname: {
      type: String,
      required: false
    },
    showContractSelector: {
      type: Boolean,
      required: false,
      default: true
    },
    showNavigation: {
      type: Boolean,
      required: false,
      default: true
    },
    showBanner: {
      type: Boolean,
      required: false,
      default: true
    },
    showGoToTopButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    contractLoaded () {
      this.$emit('loaded')
    },

    loadedContracts (contractSelectorOptions) {
      this.$emit('loadedContracts', contractSelectorOptions)
    },

    failedLoadingContracts () {
      this.$emit('failedLoadingContracts')
    }
  }
}
</script>
