import StorageAbstract from './Storage'

/**
 * Permanent storage using local storage under the hood.
 */
export default class StorageLocal extends StorageAbstract {
  /**
   * @inheritdoc
   */
  protected createStorage (): Storage {
    return this.getLocalStorage()
  }
}
