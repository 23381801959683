<template>
  <div>
    <link rel="preload" href="https://vattenfall-de.my.onetrust.eu/cdn/cookies/scripttemplates/otSDKStub.js" as="script" />
    <link rel="preload" :href="piwikUrl" as="script" />
    <div class="cso-marketing"></div>
  </div>
</template>

<script>
export default {
  name: 'CsoMarketing',

  computed: {
    piwikUrl () {
      return 'https://vattenfall.containers.piwik.pro/' + this.c_identifier() + '.js'
    }
  },

  created () {
    if (location.hostname === 'localhost') {
      return
    }
    this.load()
  },

  methods: {
    c_identifier () {
      const aId = ['b6eacfbe-7660-4775-98a0-2abb109deef8', 'c70d767d-a03a-4695-b32e-760cc29eb91f']
      if (document.cookie?.includes('vf_debug=test')) {
        return aId[1]
      }
      const object = ['service.vattenfall.de', 'preisrechner.vattenfall.de', 'www.vattenfall.de', 'angebot.vattenfall.de']
      if (object.includes(window.location.hostname)) {
        return aId[0]
      }
      return aId[1]
    },

    load () {
      /* eslint-disable */
      // v.2023-07-17
      // OneTrust CMP
      (function () {
        const f = document.getElementsByTagName('script')[0];
        let j = document.createElement('script');
        j.src = 'https://vattenfall-de.my.onetrust.eu/cdn/cookies/scripttemplates/otSDKStub.js';
        j.setAttribute("charset", "UTF-8");
        j.setAttribute("data-language", "de");
        j.setAttribute("data-domain-script", "722792af-ff76-458e-998c-9e7119608fde");
        f.parentNode.insertBefore(j, f);
        function OptanonWrapper () { };
      })();
      // Piwik Pro Tag Manager
      (function (window, document, dataLayerName, id) {
        window[dataLayerName] = window[dataLayerName] || [], window[dataLayerName].push({ start: (new Date).getTime(), event: "stg.start" }); var scripts = document.getElementsByTagName('script')[0], tags = document.createElement('script');
        function stgCreateCookie (a, b, c) { var d = ""; if (c) { var e = new Date; e.setTime(e.getTime() + 24 * c * 60 * 60 * 1e3), d = "; expires=" + e.toUTCString() } document.cookie = a + "=" + b + d + "; path=/" }
        var isStgDebug = (window.location.href.match("stg_debug") || document.cookie.match("stg_debug")) && !window.location.href.match("stg_disable_debug"); stgCreateCookie("stg_debug", isStgDebug ? 1 : "", isStgDebug ? 14 : -1);
        var qP = []; dataLayerName !== "dataLayer" && qP.push("data_layer_name=" + dataLayerName), isStgDebug && qP.push("stg_debug"); var qPString = qP.length > 0 ? ("?" + qP.join("&")) : "";
        tags.async = !0, tags.src = "https://vattenfall.containers.piwik.pro/" + id + ".js" + qPString, scripts.parentNode.insertBefore(tags, scripts);
        !function (a, n, i) { a[n] = a[n] || {}; for (var c = 0; c < i.length; c++)!function (i) { a[n][i] = a[n][i] || {}, a[n][i].api = a[n][i].api || function () { var a = [].slice.call(arguments, 0); "string" == typeof a[0] && window[dataLayerName].push({ event: n + "." + i + ":" + a[0], parameters: [].slice.call(arguments, 1) }) } }(i[c]) }(window, "ppms", ["tm", "cm"]);
      })(window, document, 'dataLayer', this.c_identifier());
    }
  }
}
</script>

<style scoped>
.cso-marketing {
  display: none;
}
</style>
