/**
 * Permanent storage.
 */
export default abstract class StorageAbstract {
  /**
   * Prefix used for storage keys.
   */
  public static readonly PREFIX = 'vfdi'

  /**
   * Instantiates a new permanent storage.
   */
  public constructor () {
    this.storage = this.createStorage()
  }

  /**
   * Gets value from the storage with given key.
   */
  public get<T = unknown> (key: string): T | undefined {
    const item = this.getItem(key)
    return this.deserializeItem(item)
  }

  /**
   * Sets value to the storage with given key.
   */
  public set<T = unknown> (key: string, value: T | undefined): void {
    if (value !== undefined) {
      const item = this.serializeItem(value)
      this.setItem(key, item)
    } else {
      this.removeItem(key)
    }
  }

  /**
   * Gets item.
   */
  protected getItem (key: string): string | null {
    const keyPrefixed = StorageAbstract.getKeyPrefixed(key)
    return this.storage.getItem(keyPrefixed)
  }

  /**
   * Sets item.
   */
  protected setItem (key: string, value: string): void {
    const keyPrefixed = StorageAbstract.getKeyPrefixed(key)
    this.storage.setItem(keyPrefixed, value)
  }

  /**
   * Removes item.
   */
  protected removeItem (key: string): void {
    const keyPrefixed = StorageAbstract.getKeyPrefixed(key)
    this.storage.removeItem(keyPrefixed)
  }

  /**
   * Serializes item.
   */
  protected serializeItem (value: any): string {
    return JSON.stringify(value)
  }

  /**
   * Deserializes item.
   */
  protected deserializeItem (value: string | null): any {
    return value === null ? undefined : JSON.parse(value)
  }

  /**
   * Creates underlying storage.
   */
  protected abstract createStorage (): Storage

  /**
   * Returns local storage.
   */
  protected getLocalStorage (): Storage {
    return window.localStorage
  }

  /**
   * Returns session storage.
   */
  protected getSessionStorage (): Storage {
    return window.sessionStorage
  }

  /**
   * Underlying storage.
   */
  protected storage: Storage

  /**
   * Returns key with prefix.
   */
  protected static getKeyPrefixed (key: string): string {
    return `${StorageAbstract.PREFIX}.${key}`
  }
}
