import StaticUrls from '@/common/StaticUrls'

export type Type = 'EMPTY' | 'NO_CONTRACT' | 'NONCOMM' | 'DEFAULT'

export interface Item {
  title: string
  url: string
  iconClass: string
}

export interface Mobileitem {
  title: string
  url: string
}

export const items: Item[] = [
  {
    title: 'DASHBOARD',
    url: StaticUrls.homepage,
    iconClass: 'home'
  },
  {
    title: 'USER_PROFILE',
    url: StaticUrls.userProfile,
    iconClass: 'user'
  },
  {
    title: 'ACCOUNT_OVERVIEW',
    url: StaticUrls.accountOverview,
    iconClass: 'account'
  },
  {
    title: 'CONSUMPTION_OVERVIEW',
    url: StaticUrls.consumptionOverview,
    iconClass: 'consumption'
  },
  {
    title: 'CONTRACT_OVERVIEW',
    url: StaticUrls.deliveryAndContract,
    iconClass: 'contract'
  },
  {
    title: 'TELL_A_FRIEND',
    url: StaticUrls.tellAFriend,
    iconClass: 'tell-a-friend'
  },
  {
    title: 'FAQ',
    url: StaticUrls.smartFAQ,
    iconClass: 'faq'
  }
]

export const mobileItems: Mobileitem[] = [
  {
    title: 'DASHBOARD',
    url: StaticUrls.homepage
  },
  {
    title: 'USER_PROFILE',
    url: StaticUrls.userProfile
  },
  {
    title: 'ACCOUNT_OVERVIEW',
    url: StaticUrls.accountOverview
  },
  {
    title: 'CONSUMPTION_OVERVIEW',
    url: StaticUrls.consumptionOverview
  },
  {
    title: 'CONTRACT_OVERVIEW',
    url: StaticUrls.deliveryAndContract
  },
  {
    title: 'TELL_A_FRIEND',
    url: StaticUrls.tellAFriend
  },
  {
    title: 'FAQ',
    url: StaticUrls.smartFAQ
  }
]

export const menuEmpty = [] as string[]

export const menuNoContract = [
  'DASHBOARD',
  'USER_PROFILE',
  'FAQ'
]

export const menuNoncomm = [
  'DASHBOARD',
  'USER_PROFILE',
  'ACCOUNT_OVERVIEW',
  'CONTRACT_OVERVIEW',
  'TELL_A_FRIEND',
  'FAQ'
]

export const menuDefault = [
  'DASHBOARD',
  'USER_PROFILE',
  'ACCOUNT_OVERVIEW',
  'CONSUMPTION_OVERVIEW',
  'CONTRACT_OVERVIEW',
  'TELL_A_FRIEND',
  'FAQ'
]
