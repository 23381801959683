<template>
  <div class="go-to-top-wrapper">
    <i @click="goToTop()"
       class="vf-icon vf-icon-up vf-icon-background--white vf-icon-size--48"></i>
  </div>
</template>
<script>
export default {
  name: 'CsoGoToTop',
  methods: {
    goToTop () {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss">
.go-to-top-wrapper {
  text-align: right;

  i {
    cursor: pointer;

    @include box-shadow(2);

    &:active {
      @include box-shadow(3);
    }
  }
}
</style>
