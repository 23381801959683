import { Message } from '../core/model-shared'
import Response from '../Response'

/**
 * Response error.
 */
export default class ErrorResponse extends Error {
  /**
   * Response that threw this error.
   */
  public response: Response

  /**
   * Instantiates a new response error.
   */
  public constructor (response: Response) {
    const error = ErrorResponse.getError(response)
    super(`Digital Interface returned an error: ${error.Code}`)
    this.response = response
  }

  /**
   * Error code from the response.
   */
  public get code (): string {
    return this.error.Code
  }

  /**
   * Error text from the response.
   */
  public get text (): string {
    return this.error.FachlicheBeschreibung || this.error.TechnischeBeschreibung
  }

  /**
   * Error from the response.
   */
  protected get error (): Message {
    return ErrorResponse.getError(this.response)
  }

  /**
   * Returns error from the response.
   */
  protected static getError (response: Response): Message {
    const error = response.body && response.body.Meldungen && response.body.Meldungen[0]
    return error || ErrorResponse.ERROR_DEFAULT
  }

  /**
   * Default error in case no error was returned in the response.
   */
  protected static readonly ERROR_DEFAULT: Message = {
    FachlicheBeschreibung: 'Entschuldigung, ein Fehler ist aufgetreten',
    TechnischeBeschreibung: '',
    Code: ''
  }
}
