import LoggingService from '@/common/LoggingService'

export default class MultipleTabs {
  static CHECK_PERIOD = 1000

  static register () {
    this.id = this.id || Date.now()
    this.localValue = this.sharedValue
    setInterval(MultipleTabs.refreshIfDifferent, MultipleTabs.CHECK_PERIOD)
  }

  static update () {
    const newValue = Date.now()
    MultipleTabs.localValue = newValue
    MultipleTabs.sharedValue = newValue
  }

  static refreshIfDifferent () {
    if (MultipleTabs.localValue !== MultipleTabs.sharedValue) {
      LoggingService.log(LoggingService.eventTypes.multipleTabs, 'Change detected, refreshing')
      window.location.reload()
    }
  }

  static get sharedValue () {
    return +localStorage.getItem('cso.multiple_tabs_value')
  }

  static set sharedValue (value) {
    localStorage.setItem('cso.multiple_tabs_value', value)
  }

  static get id () {
    return +sessionStorage.getItem('cso.tab_id')
  }

  static set id (id) {
    sessionStorage.setItem('cso.tab_id', id)
  }

  static localValue
}
