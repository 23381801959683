<template>
  <div class="cso-box"
       :class="{'has-overlay': hasOverlay}">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'cso-box',
  props: {
    hasOverlay: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
<style lang="scss">
.cso-box {
  @include box-shadow(3);
  background-color: $white;
  padding: 20px;
  margin-top: 10px;
  color: $blue-night;
  @include print-view {
    padding: 0 20px;
  }
  @include md {
    padding: 10px;
  }
}
</style>
