<script>
export default {
  name: 'CsoGlobalStyles',
  render () {
    return ''
  }
}
</script>
<style lang="scss">
@import "@/styles/general.scss";
</style>
