<template>
  <sweet-modal ref="modal"
               :title="header"
               :hide-close-button="hideCloseButton"
               :blocking="hideCloseButton">
    <slot></slot>
  </sweet-modal>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue-3'

export default {
  name: 'CsoDialog',
  components: {
    SweetModal
  },
  props: {
    header: {
      type: String,
      required: false
    },

    hideCloseButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    open () {
      this.$refs.modal.open()
    },
    close () {
      this.$refs.modal.close()
    }
  }
}
</script>

<style lang="scss">
.sweet-modal-overlay {
  background: rgb(0, 0, 0, 0.6499999761581421);
  z-index: 19001;
}

.sweet-title > h2 {
  font-size: 36px !important;
  font-weight: 700 !important;
  font-family: $font-family-display !important;
  height: inherit;

  @include md {
    font-size: 24px !important;
  }
}
.sweet-title {
  border-bottom: 0 !important;
  height: 45px !important;
  padding-left: 0 !important;
  margin-bottom: 12px;

  @include md {
    padding: 0 16px !important;
    margin: 0;
  }
}
.sweet-modal {
  max-width: 1034px !important;
  max-height: min(861px, 100vh) !important;
  color: $black !important;
  padding: 48px 72px;
  margin: auto;

  @include lg {
    width: 100vw !important;
  }

  @include md {
    padding: 68px 21px !important;
    max-height: 100vh !important;
    max-height: 100svh !important;
    max-width: 94vw !important;
    width: 500px !important;
    margin: 1rem auto;
  }
  @media screen and (max-width: 600px) {
    position: relative !important;
  }

  @include xs {
    padding: 68px 0 !important;
    max-height: 100vh !important;
    max-height: 100svh !important;
    width: 94vw !important;
    margin: auto;
  }

  .sweet-box-actions {
    top: 16px !important;
    right: 16px !important;
    @include hidden-print();
  }

  .sweet-box-actions .sweet-action-close:hover {
    background-color: transparent !important;
    color: black !important
  }

  .sweet-box-actions .sweet-action-close svg {
    width: 32px !important;
    height: 32px !important;
  }

  .sweet-content {
    margin: 0 !important;
    padding: 0 !important;
    justify-content: center !important;
  }
}


@media print {
  .sweet-modal-overlay {
    position: relative !important;
  }

  .sweet-modal.is-visible {
    width: 100%;
    height: 100%;
    overflow-y: visible;
    position: relative;
    top: 0;
    left: 0;
    padding: 0 50px 0 0;
    transform: none !important;
    max-height: none !important;
  }
}
</style>
