import DigitalInterface from '../DigitalInterface'
import ErrorTokenExpired from '../error/ErrorTokenExpired'
import Response from '../Response'
import type Strategy from './Strategy'
import { type StrategyType } from './StrategyType'
import Token from '../authentication/Token'
import { type TokenRequest, type TokenResponse, type Version } from '../core/model-shared'

/**
 * Strategy refresh.
 *
 * Handles response by refreshing authentication token used in the request,
 * updating default token if relevant, and retrying original request.
 */
export default class StrategyRefresh implements Strategy {
  /**
   * @inheritdoc
   */
  public type: StrategyType = 'REFRESH'

  /**
   * Instantiates a new refresh strategy.
   */
  public constructor (di: DigitalInterface) {
    this.di = di
    this.promises = {}
  }

  /**
   * @inheritdoc
   */
  public async handle (response: Response): Promise<Response> {
    const { request } = response
    // Edge case: Empty token.
    if (!request.token) {
      throw new ErrorTokenExpired(Token.empty())
    }
    // Edge case: Max retries reached.
    if (!request.canRetry) {
      throw new ErrorTokenExpired(request.token)
    }
    // Do refresh.
    const oldAccessToken = request.token.accessToken
    // If there is no pending refresh for that token, create it.
    if (!this.promises[oldAccessToken]) {
      const newTokenPromise = this.refresh(request.version, request.token)
        .finally(() => delete this.promises[oldAccessToken])
      this.promises[oldAccessToken] = newTokenPromise
    }
    // Await for new token.
    const token = await this.promises[oldAccessToken]
    // If default token was used, update it.
    if (this.di.token?.accessToken === oldAccessToken) {
      this.di.token = token
    }
    // Retry original request.
    request.token = token
    request.doRetry()
    return this.di.sendRequest(request)
  }

  /**
   * Refresh token.
   */
  protected async refresh (version: Version, token: Token) {
    const response = await this.di.send<TokenRequest, TokenResponse>({
      version,
      method: 'POST',
      endpoint: StrategyRefresh.URL,
      body: { Request: { RefreshToken: token.refreshToken } }
    })
    return Token.createFromResponse(response.body.Result, token.username, token.role)
  }

  /**
   * Client.
   */
  protected di: DigitalInterface

  /**
   * Map of pending refresh for each token.
   */
  protected promises: Record<string, Promise<Token | undefined>>

  /**
   * Refresh endpoint url.
   */
  protected static URL = '/Account/GetTokenRefresh'
}
