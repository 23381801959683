import ai from '@/api/application-insights'
import di from '@/api/digital-interface'
import * as DI from '@/api/digital-interface/client'
import LoggingService from '@/common/LoggingService'
import { type Teaser } from '@/components/routes/digital-interface-dashboard/Model'
import { ObservableStore } from '@codewithdan/observable-store'
import Utils from '@/common/Utils'

export interface State {
  vk: string,
  gp: string,
  gpOnly: string,
  vertragsliste: DI.V13.Vertragsliste
  prozesse: DI.V13.Prozesse
  account: DI.V13.Account
  prozessInformation: DI.V13.Prozessinformation,
  teasers: Teaser[],
  felder: DI.V13.Felder,
  maintananceMode: {
    enabled: boolean,
    message: string
  },
  captcha: {
    enabled: boolean,
    header: string,
    message: string
  },
  captchaToken: string,
  allVk: Array<DI.Model13.Vertrag.GetVertragsliste.ResponseGPVertraege>
}

export interface SelectedContract {
  gp: string
  vk: string
}

export interface SelectedBusinessPartner {
  gpOnly: string
}

export class Store extends ObservableStore<State> {
  public constructor () {
    super({
      // logStateChanges: true,
      // trackStateHistory: true
    })
    const { gpOnly } = this.selectedBusinessPartner
    const { gp, vk } = this.selectedContract
    this.set({
      gp,
      vk,
      gpOnly,
      teasers: undefined,
      vertragsliste: DI.V13.Vertragsliste.createEmpty(),
      prozesse: DI.V13.Prozesse.createEmpty(),
      account: DI.V13.Account.createEmpty(),
      prozessInformation: DI.V13.Prozessinformation.createEmpty(),
      felder: DI.V13.Felder.createEmpty(),
      maintananceMode: {
        enabled: false,
        message: ''
      },
      captcha: {
        enabled: false,
        header: '',
        message: ''
      },
      captchaToken: '',
      allVk: []
    }, 'INIT')
    // LoggingService.log(LoggingService.eventTypes.store, 'Init')
  }

  public get vk () {
    return this.state.vk
  }

  public get gp () {
    return this.state.gp
  }

  public get gpOnly () {
    return this.state.gpOnly
  }

  public get GP () {
    return this.state.vertragsliste.getGP(this.state.gp)
  }

  public get VK () {
    return this.state.vertragsliste.getVK(this.state.vk)
  }

  public get hasGP () {
    return this.state.vertragsliste.hasGP
  }

  public get hasVK () {
    return this.state.vertragsliste.hasVK
  }

  public get allVK () {
    return this.state.vertragsliste.VK
  }

  public get allGP () {
    return this.state.vertragsliste.GP
  }

  public get vertragsliste () {
    return this.state.vertragsliste
  }

  public get GPOnly () {
    return this.state.vertragsliste.getGP(this.state.gpOnly)
  }

  public get prozesse () {
    return this.state.prozesse
  }

  public get teasers () {
    return this.state.teasers
  }

  public get account () {
    return this.state.account
  }

  public get felder () {
    return new DI.V13.Felder(Utils.copyObject(this.state.felder))
  }

  public async fetchFelder () {
    LoggingService.log(LoggingService.eventTypes.store, 'Fetch GetFelder')
    if (!this.getFelderPromise) {
      this.getFelderPromise = (async () => {
        const felder = await di.v13.getFelder([
          'ANREDEN',
          'EINWILLIGUNGEN',
          'PRAEFERENZEN',
          'REGISTERGERICHTE'
        ])
        this.set({ felder }, 'FETCH_GETFELDER')
        LoggingService.log(LoggingService.eventTypes.store, 'GetFelder fetched')
      })()
    }
    await this.getFelderPromise
  }

  public async fetchVertragsliste () {
    LoggingService.log(LoggingService.eventTypes.store, 'Fetch vertragsliste')
    if (!this.vertragslistePromise) {
      this.vertragslistePromise = (async () => {
        // Fix for anonymous token.
        if (!di.token || di.token?.isAnonymous()) {
          // To include stack trace we need to log it as error.
          ai.trackError(new Error('FETCH_VERTRAGSLISTE_ANONYMOUS'))
          LoggingService.error(LoggingService.eventTypes.store, 'Fetch vertragsliste with anonym token')
          const vertragsliste = DI.V13.Vertragsliste.createEmpty()
          this.set({ vertragsliste }, 'FETCH_VERTRAGSLISTE')
        } else {
          const vertragsliste = await di.v13.getVertragsliste(di.token!.username!)
          const allVk = vertragsliste.VK
          this.set({ vertragsliste, allVk }, 'FETCH_VERTRAGSLISTE')
          if (this.hasVK && !this.VK) {
            await this.selectVK(this.state.allVk[0].VK.ReferenzID)
          }
          LoggingService.log(LoggingService.eventTypes.store, 'Vertragsliste fetched')
        }
      })()
    }
    await this.vertragslistePromise
  }

  public async fetchAccount () {
    LoggingService.log(LoggingService.eventTypes.store, 'Fetch account')
    if (!this.accountPromise) {
      this.accountPromise = (async () => {
        // Fix for anonymous token.
        if (!di.token || di.token?.isAnonymous()) {
          // To include stack trace we need to log it as error.
          ai.trackError(new Error('FETCH_ACCOUNT_ANONYMOUS'))
          LoggingService.error(LoggingService.eventTypes.store, 'Fetch account with anonym token')
          const account = DI.V13.Account.createEmpty()
          this.set({ account }, 'FETCH_ACCOUNT')
        } else {
          const account = await di.v13.getAccount(di.token!.username!)
          this.set({ account }, 'FETCH_ACCOUNT')
          LoggingService.log(LoggingService.eventTypes.store, 'Account fetched')
        }
      })()
    }
    await this.accountPromise
  }

  public clearVertragsliste () {
    LoggingService.log(LoggingService.eventTypes.store, 'Clear Vertragsliste')
    const vertragsliste = DI.V13.Vertragsliste.createEmpty()
    this.vertragslistePromise = undefined
    this.set({ vertragsliste }, 'CLEAR_VERTRAGSLISTE')
  }

  public async fetchDashboardProzesse () {
    LoggingService.log(LoggingService.eventTypes.store, 'Fetch Dashboard Prozesse')
    if (!this.dashboardProzessePromise) {
      this.dashboardProzessePromise = (async () => {
        // Fix for missing contract account number.
        if (!this.state.vk || di.token?.isAnonymous()) {
          // To include stack trace we need to log it as error.
          LoggingService.error(LoggingService.eventTypes.store, 'Fetch Dashboard Prozesse with anonym token')
          const prozesse = DI.V13.Prozesse.createEmpty()
          this.set({ prozesse }, 'FETCH_DASHBOARD_PROZESSE')
        } else {
          const prozesse = await di.v13.getProzesse([this.state.vk], [
            'ABSCHLAGSAENDERUNG',
            'LASTGANG',
            'PRODUKTWECHSEL',
            'FAELLIGE_FORDERUNG',
            'RATENPLANERSTELLUNG',
            'TARIFDETAILS',
            'ZAEHLERSTANDSERFASSUNG',
            'ZAHLUNGSINFORMATIONSAENDERUNG',
            'KUNDENDOKUMENTE'
          ])
          this.set({ prozesse }, 'FETCH_DASHBOARD_PROZESSE')
          LoggingService.log(LoggingService.eventTypes.store, 'Dashboard Prozesse fetched')
        }
      })()
    }
    await this.dashboardProzessePromise
  }

  public clearDashboardProzesse () {
    LoggingService.log(LoggingService.eventTypes.store, 'Clear Dashboard Prozesse')
    const prozesse = DI.V13.Prozesse.createEmpty()
    this.dashboardProzessePromise = undefined
    this.set({ prozesse }, 'CLEAR_DASHBOARD_PROZESSE')
  }

  public setTeasers (teasers: Teaser[]) {
    LoggingService.log(LoggingService.eventTypes.store, 'Set teasers')
    this.set({ teasers }, 'SET_TEASERS')
  }

  public setMaintanceMode (maintananceMode: { enabled: boolean, message: string }) {
    LoggingService.log(LoggingService.eventTypes.store, 'Set Maintanance mode')
    this.set({ maintananceMode }, 'SET_MAINTANANCE_MODE')
  }

  public setCaptcha (enabled: boolean, header: string, message: string) {
    LoggingService.log(LoggingService.eventTypes.store, 'Set Captcha to: ' + enabled)
    this.set({ captcha: { enabled, header, message } }, 'SET_CAPTCHA')
  }

  public setCaptchaToken (captchaToken: string) {
    LoggingService.log(LoggingService.eventTypes.store, (captchaToken ? 'Set' : 'Clear') + ' Captcha token')
    this.set({ captchaToken }, 'SET_CAPTCHA_TOKEN')
  }

  public clearTeasers () {
    LoggingService.log(LoggingService.eventTypes.store, 'Clear teasers')
    const teasers = undefined
    this.set({ teasers }, 'CLEAR_TEASERS')
  }

  public clearAccount () {
    LoggingService.log(LoggingService.eventTypes.store, 'Clear account')
    const account = DI.V13.Account.createEmpty()
    this.accountPromise = undefined
    this.set({ account }, 'CLEAR_ACCOUNT')
  }

  public async selectVK (number: string) {
    const GP = this.state.vertragsliste.getGPbyVK(number)!
    const VK = this.state.vertragsliste.getVK(number)!
    const vk = VK.VK.ReferenzID
    const gp = GP.GP.ReferenzID
    LoggingService.log(LoggingService.eventTypes.store, 'selectVK', 'vk: ' + vk + ', gp: ' + gp)
    this.selectedContract = { gp, vk }
    this.set({ gp, vk }, 'SELECT_VK')
  }

  public clearVK () {
    const { gp, vk } = Store.SELECTED_CONTRACT_EMPTY
    this.selectedContract = { gp, vk }
    this.set({ gp, vk }, 'CLEAR_VK')
  }

  public async selectGP (number: string) {
    const GP = this.state.vertragsliste.getGP(number)!
    const gpOnly = GP.GP.ReferenzID
    LoggingService.log(LoggingService.eventTypes.store, 'selectGP', gpOnly)
    this.selectedBusinessPartner = { gpOnly }
    this.set({ gpOnly }, 'SELECT_GP_ONLY')
  }

  public clearGP () {
    const { gpOnly } = Store.SELECTED_BUSINESS_PARTNER_ONLY_EMPTY
    this.selectedBusinessPartner = { gpOnly }
    this.set({ gpOnly }, 'CLEAR_GP_ONLY')
  }

  public subscribe (observer: (state: State) => void) {
    return this.stateChanged.subscribe(observer)
  }

  public set (state: Partial<State>, action: string) {
    this.setState(state, action, true, false)
  }

  public get state () {
    return this.getState(false)
  }

  public clearAll () {
    LoggingService.log(LoggingService.eventTypes.store, 'Clear all')
    this.clearVertragsliste()
    this.clearAccount()
    this.clearDashboardProzesse()
    this.clearTeasers()
    this.clearVK()
    this.clearGP()
    this.clearLocalStorage()
  }

  protected clearLocalStorage () {
    LoggingService.log(LoggingService.eventTypes.store, 'Clear localStorage')
    localStorage.removeItem(Store.SELECTED_CONTRACT_KEY)
    localStorage.removeItem(Store.SELECTED_BUSINESS_PARTNER_ONLY_KEY)
  }

  protected set selectedContract (selectedContract: SelectedContract) {
    const item = JSON.stringify(selectedContract)
    localStorage.setItem(Store.SELECTED_CONTRACT_KEY, item)
  }

  protected get selectedContract () {
    const item = localStorage.getItem(Store.SELECTED_CONTRACT_KEY)
    return item ? JSON.parse(item) : Store.SELECTED_CONTRACT_EMPTY
  }

  protected set selectedBusinessPartner (selectedBusinessPartner: SelectedBusinessPartner) {
    const item = JSON.stringify(selectedBusinessPartner)
    localStorage.setItem(Store.SELECTED_BUSINESS_PARTNER_ONLY_KEY, item)
  }

  protected get selectedBusinessPartner () {
    const item = localStorage.getItem(Store.SELECTED_BUSINESS_PARTNER_ONLY_KEY)
    return item ? JSON.parse(item) : Store.SELECTED_BUSINESS_PARTNER_ONLY_EMPTY
  }

  protected vertragslistePromise: Promise<void> | undefined
  protected dashboardProzessePromise: Promise<void> | undefined
  protected accountPromise: Promise<void> | undefined
  protected getFelderPromise: Promise<void> | undefined

  protected static SELECTED_CONTRACT_KEY = 'cso.selected_contract'
  protected static SELECTED_CONTRACT_EMPTY = { gp: '', vk: '' }

  protected static SELECTED_BUSINESS_PARTNER_ONLY_KEY = 'cso.selected_business_partner_only'
  protected static SELECTED_BUSINESS_PARTNER_ONLY_EMPTY = { gpOnly: '' }
}

export default new Store()
