import * as Model from '../../core/1.3/model'
import Response from '../../Response'
import { type ValidierungFelder } from './types'

// Alias for convenience.
class ValidierungRequestBody extends Model.Validierung.CheckFelder.Request { }
class ValidierungResponseBody extends Model.Validierung.CheckFelder.Response { }
type ValidierungResponse = Response<ValidierungRequestBody, ValidierungResponseBody>
type Feld = keyof ValidierungFelder

/**
 * Validation helper.
 */
export default class Validierung extends ValidierungResponseBody {
  /**
   * Corrected fields.
   */
  public declare KorrigierteFelder: ValidierungFelder

  /**
   * Original fields.
   */
  public OriginalFelder: Partial<ValidierungFelder>

  /**
   * Instantiates a new validation helper.
   */
  public constructor (response: ValidierungResponse, originalFelder: Partial<ValidierungFelder>) {
    super()
    Object.assign(this, response.body.Result)
    this.KorrigierteFelder = this.KorrigierteFelder || {}
    this.OriginalFelder = originalFelder
    this.response = response
  }

  /**
   * Whether validation succeded.
   */
  public get Ok (): boolean {
    return this.ResponseOk && !this.FelderKorrigiert
  }

  /**
   * Whether validation partially succeeded
   * and fields have been automatically corrected.
   */
  public get Korrigiert (): boolean {
    return this.ResponseOk && this.FelderKorrigiert
  }

  /**
   * Whether validation failed.
   */
  public get Error (): boolean {
    return !this.ResponseOk
  }

  /**
   * Validation messages.
   */
  public get Meldungen (): string {
    return this.response.body.Meldungen
      .map(message => message.FachlicheBeschreibung || message.TechnischeBeschreibung)
      .join('\n')
  }

  /**
   * Merged fields.
   */
  public get Felder (): ValidierungFelder {
    return {
      ...this.OriginalFelder,
      ...this.KorrigierteFelder
    }
  }

  /**
   * Returns whether specified fields were not corrected.
   */
  public nichtKorrigiert (felder: Feld[]): boolean {
    return !felder.find(feld => this.KorrigierteFelder[feld])
  }

  /**
   * Whether fields have been automatically corrected.
   */
  protected get FelderKorrigiert (): boolean {
    return Object.keys(this.KorrigierteFelder).length > 0
  }

  /**
   * Wether response status code is ok.
   */
  protected get ResponseOk (): boolean {
    return this.response.body.StatusCode === 'OK'
  }

  /**
   * Response returned from the api.
   */
  protected response: ValidierungResponse
}
