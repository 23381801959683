<template>
  <div class="cso-domain-popup" v-if="$route.query.domainPopup">
    <div class="content">
      <div @click="close"></div>
      <h3>{{ $t('domainPopup.headline') }}</h3>
      <p v-html="$t('domainPopup.text1')"></p>
      <p v-html="$t('domainPopup.text2')"></p>
      <span v-html="$t('domainPopup.text3')"></span> <span class="link" @click="go">{{ $t('domainPopup.link') }}</span>.
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CsoDomainPopup',

  methods: {
    go () {
      this.$router.push({ name: 'personalData' })
    },
    close () {
      this.$router.push({ query: {} })
    }
  }
}
</script>

<style lang="scss">
.cso-domain-popup {
  position: fixed;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background-color: #000000b8;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    position: relative;
    background-color: white;
    width: 90%;
    border-radius: 4px;
    padding: 20px 20px 40px 20px;
    max-width: 640px;
    margin: 0 20px 0 0;

    h3 {
      font-size: 16px;
      margin-bottom: 16px;
    }

    div {
      position: absolute;
      right: 14px;
      background-image: url(/static/icons/close-big.svg);
      background-size: cover;
      cursor: pointer;
      width: 22px;
      height: 22px;

      &:hover {
        opacity: 0.8;
      }
    }
  
    p, span {
      font-size: 16px;
    }
    
    @include xs {
      margin: 50px 20px 0 0;
      padding: 10px 10px 20px 10px;

      p, span {
        font-size: 14px;
      }
    }
  }

  .link {
    color: #2071B5;
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>
