<template>
  <div>
    <cso-dialog :header="$t('general.captcha.title')"
                :hide-close-button="true"
                ref="dialog">
      <p>{{ $t('general.captcha.subtitle') }}</p>
      <div ref="captcha"
          class="frc-captcha"></div>
    </cso-dialog>
  </div>
</template>
<script lang="ts">
import { WidgetInstance } from 'friendly-challenge'
import { Subscription } from 'rxjs'
import store, { type State } from '@/store'
import di from '@/api/digital-interface'
import LoggingService from '@/common/LoggingService'
import PropertyService from '@/common/PropertyService'

import CsoDialog from '@/components/core/composition/CsoDialog.vue'

let subscription: Subscription

export interface CaptchaResult {
  isHuman: boolean;
  friendlyCaptchaToken?: string;
}

interface Dialog {
  open (): void
  close (): void
}

export default {
  name: 'CsoFriendlyCaptcha',
  components: {
    CsoDialog
  },

  data () {
    return {
      widget: null as any,
      isDialogOpen: false,
      pendingResult: false
    }
  },

  methods: {
    observeStore (state: State) {
      if (state.captcha.enabled && this.isDialogOpen === false) {
        this.openDialog()
      }
    },

    openDialog () {
      LoggingService.log(LoggingService.eventTypes.userEvent, 'Captcha open')
      const dialog = this.$refs.dialog as Dialog
      this.isDialogOpen = true
      this.pendingResult = true
      dialog.open()
      this.startCaptcha()
    },

    closeDialog () {
      const dialog = this.$refs.dialog as Dialog
      this.isDialogOpen = false
      dialog.close()
    },

    startCaptcha () {
      const widgetElement = this.$refs.captcha as HTMLElement
      this.widget = new WidgetInstance(widgetElement, {
        doneCallback: (friendlyCaptchaToken: any) => {
          this.handleCaptchaResult({ isHuman: true, friendlyCaptchaToken })
        },
        errorCallback: () => {
          this.handleCaptchaResult({ isHuman: false })
        },
        sitekey: PropertyService.friendlyCaptchaSitekey,
        language: 'de',
        startMode: 'auto',
        skipStyleInjection: true,
        puzzleEndpoint: 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle'
      })
    },

    async handleCaptchaResult (captchaResult: CaptchaResult) {
      store.setCaptcha(false, '', '')
      if (!captchaResult.isHuman || !captchaResult.friendlyCaptchaToken) {
        this.pendingResult = false
        // this potentially can fail when we fail captcha for anonym token
        try {
          await di.v13.trackCaptchaFail()
        } catch (e) { }
        store.setMaintanceMode({
          enabled: true,
          message: ''
        })
      } else if (this.pendingResult === true) {
        LoggingService.log(LoggingService.eventTypes.userEvent, 'Captcha succeeded')
        store.setCaptchaToken(captchaResult.friendlyCaptchaToken)
        this.closeDialog()
      }
    }
  },

  created () {
    subscription = store.subscribe(this.observeStore)
  },

  unmounted () {
    if (subscription) {
      subscription.unsubscribe()
    }
  }
}
</script>

<style lang="scss">
.frc-captcha * {
  /* Mostly a CSS reset so existing website styles don't clash */
  margin: 0;
  padding: 0;
  border: 0;
  text-align: initial;
  border-radius: 0;
  filter: none !important;
  transition: none !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  text-decoration: none;
  background-color: initial;
  color: #222;
}

.frc-captcha {
  position: relative;
  min-width: 250px;
  max-width: 100%;
  border: 1px solid #f4f4f4;
  padding-bottom: 12px;
  background-color: #fff;
}

.frc-captcha b {
  font-weight: bold;
}

.frc-container {
  display: flex;
  align-items: center;
}

.frc-icon {
  fill: #222;
  stroke: #222;
  flex-shrink: 0;
  margin: 8px 8px 0 8px;
}

.frc-icon.frc-warning {
  fill: #c00;
}

.frc-success .frc-icon {
  animation: frc-fade-in 1s both ease-in;
}

.frc-content {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  margin: 14px 6px 0 0;
  overflow-y: hidden;
  overflow-x: auto;
  flex-grow: 1;
}

.frc-banner {
  position: absolute;
  bottom: 0px;
  right: 6px;
  line-height: 1;
}

.frc-banner * {
  font-size: 10px;
  opacity: 0.8;
  text-decoration: none;
}

.frc-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 3px 0;
  height: 4px;
  border: none;
  background-color: #eee;
  color: #222;
  width: 100%;
  transition: all 0.5s linear;
}

.frc-progress::-webkit-progress-bar {
  background: #eee;
}

.frc-progress::-webkit-progress-value {
  background: #222;
}

.frc-progress::-moz-progress-bar {
  background: #222;
}

.frc-button {
  display: none;
}

.frc-button:focus {
  border: 1px solid #333;
}

.frc-button:hover {
  background-color: #ddd;
}

.frc-captcha-solution {
  display: none;
}

.frc-err-url {
  text-decoration: underline;
  font-size: 0.9em;
}

@keyframes frc-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
