import * as Model from '../../core/1.3/model'

class AccountResponse extends Model.Account.GetAccount.Response { }

/**
 * Processes helper.
 */
export default class Account extends AccountResponse {
  /**
  * Instantiates a new processes helper.
  */
  public constructor (account: AccountResponse) {
    super()
    Object.assign(this, account)
  }
  /**
 * Creates empty account object.
 */
  public static createEmpty () {
    return new Account({
      Username: '',
      Werte: {},
      Accountdaten: { ANREDE: '' },
      Konfiguration: '',
      AccountId: '',
      Berechtigungen: [],
      Emailadresse: '',
      Gruppen: [],
      Kampagnen: [],
      Tenant: '',
      Mobilnummer: '',
      Nachname: '',
      Vorname: '',
      Status: { Anzeigetext: '', Code: '' }
    })
  }
}
