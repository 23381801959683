<template>
  <div class="cso-header"
       :class="{
         'cso-header-expanded': expanded,
         'cso-header-collapsed': !expanded,
         'cso-header-simple': isSimple,
         'cso-header-closed-area': isClosedArea }">
    <div :class="{ 'container-app': isClosedArea }">
      <div class="topbar" :class="{ 'cso-header-open-area': !isClosedArea }">
        <div class="burger"
             @click="toggleMenu()">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <a class="logo"
           :href="StaticUrls.vtflMain" />
        <ul class="links">
          <li><a :href="StaticUrls.vtflMain">vattenfall.de</a></li>
          <li>
            <a :href="StaticUrls.vtflContact"
               class="with-icon"><span>{{ $t('general.help') }}</span></a>
          </li>
          <li>
            <a v-if="isUserOrDemo"
               @click="logout()"
               class=""><i class="topbar-icon exit" ></i><span>{{ $t('general.logout') }}</span></a>
            <a v-else
               @click.prevent="login()"
               :href="StaticUrls.login"
               class="with-icon"><i class="topbar-icon entry"></i><span>{{ $t('general.login') }}</span></a>
          </li>
        </ul>
        <a v-if="isUserOrDemo"
           @click="logout()"
           class="auth"><i class="topbar-icon exit" ></i></a>
        <a v-else
           @click.prevent="login()"
           :href="StaticUrls.login"
           class="auth"><i class="topbar-icon entry"></i></a>
      </div>
    </div>
    <cso-mobile-navigation @toggle="toggleMenu"/>
  </div>
</template>

<script lang="ts">
import di from '@/api/digital-interface'
import CsoRouter from '@/api/CsoRouter'
import StaticUrls from '@/common/StaticUrls'
import LoggingService from '@/common/LoggingService'
import Authentication from '@/common/Authentication'
import CsoMobileNavigation from './CsoMobileNavigation.vue'

export default {
  name: 'CsoHeader',
  components: {
    CsoMobileNavigation
  },

  props: {
    simple: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      StaticUrls,
      expanded: false
    }
  },

  computed: {
    isUserOrDemo (): boolean {
      return !!di.token?.isUserOrDemo()
    },
    isClosedArea (): boolean {
      return !CsoRouter.isOpenArea
    },
    isSimple (): boolean {
      return this.$route.meta!.simpleHeaderAndFooter as boolean|| this.simple
    }
  },

  methods: {
    toggleMenu () {
      LoggingService.log(LoggingService.eventTypes.userEvent, 'Toggle hamburger menu')
      this.expanded = !this.expanded
    },
    login () {
      LoggingService.log(LoggingService.eventTypes.userEvent, 'Click login link')
      if (this.$route.name !== 'login') {
        this.$router.push({ name: 'login' })
      }
    },
    logout () {
      LoggingService.log(LoggingService.eventTypes.userEvent, 'Click logout link')
      Authentication.logout()
    }
  }
}
</script>

<style lang="scss">
.cso-header {
  background-color: $white;
  color: $gray-darker;
  font-family: $font-family-regular;
  width: 100%;
  position: fixed;
  z-index: 100;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  a:hover:not(.with-icon),
  a:hover.with-icon span {
    text-decoration: underline;
  }

  .topbar {
    @include hidden-print();
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding-left: 10px;
    padding-right: 10px;

    &.cso-header-open-area {
      max-width: 1320px;
      margin: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .topbar-icon {
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    margin: auto 4px;
    background-color: $gray-darker;
    mask-size: cover;

    @include sm {
      width: 28px;
      height: 28px;
      background-color: $black
    }
  }

  .exit {
    mask-image: url('/static/images/exit_header.svg');
  }

  .entry {
    mask-image: url('/static/images/entry_header.svg');
  }

  .burger {
    width: 28px;
    height: 16px;
    display: none;
    cursor: pointer;

    @include sm {
      display: initial;
    }
  }

  .burger div {
    transition-delay: 0s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    transition-property: all;
    background-color: $black;
    display: block;
    width: 100%;
    height: 3px;
  }

  .logo {
    height: 64px;
    width: 192px;
    margin: 0 0 0 -10px;
    background-image: url("/static/images/logo.svg");

    @include sm {
      margin: 0;
    }
  }

  .links {
    display: flex;

    @include sm {
      display: none;
    }
  }

  .links a {
    display: flex;
    font-size: 17px;
    margin: 0 0 0 24px;
  }
  
  .links .vf-icon {
    margin: -2px 0 0 0;
    font-size: 22px;
  }

  .auth {
    display: none;
    margin: 0 -5px 0 0;

    @include sm {
      font-size: 32px;
      display: initial;
    }
  }
}

.cso-header-collapsed {

  .burger div:nth-child(1) {
    margin: 0;
  }

  .burger div:nth-child(2) {
    margin: 5px 0 0 0;
  }

  .burger div:nth-child(3) {
    margin: 5px 0 0 0;
  }

  .menu {
    display: none;
  }
}

.cso-header-expanded {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999;

  .burger div:nth-child(1) {
    transform: rotate(45deg);
    margin: 6px 0 0 0;
  }

  .burger div:nth-child(2) {
    opacity: 0;
  }

  .burger div:nth-child(3) {
    transform: rotate(-45deg);
    margin: -6px 0 0 0;
  }

  .auth,
  .logo {
    display: none;
  }
}

.cso-header-simple {

  .topbar {
    justify-content: center;
  }

  .burger,
  .links,
  .auth {
    display: none;
  }
}

.cso-header-closed-area {
  .container {

    @include right-side-nav {
      padding: 0 56px 0 10px;
    }
  }
}
</style>
