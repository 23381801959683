<template>
  <div class="component-state-container"
       :class="{'has-overlay': hasOverlay}">
    <slot name="ready"
          v-if="this.componentState === ComponentsStates.ready"></slot>
    <slot name="registerProcess"
          v-if="this.componentState === ComponentsStates.registerProcess"></slot>
    <slot name="activationProcess"
          v-if="this.componentState === ComponentsStates.activationProcess"></slot>
    <slot name="activateAccount"
          v-if="this.componentState === ComponentsStates.activateAccount"></slot>
    <slot name="readyNextStep"
          v-if="this.componentState === ComponentsStates.readyNextStep"></slot>
    <slot name="confirm"
          v-else-if="this.componentState === ComponentsStates.confirm"></slot>
    <slot name="login"
          v-else-if="this.componentState === ComponentsStates.login"></slot>
    <slot name="finished"
          v-else-if="this.componentState === ComponentsStates.finished"></slot>
    <slot name="reject"
          v-else-if="this.componentState === ComponentsStates.reject"></slot>
    <slot name="unavailableMessage"
          v-else-if="this.componentState === ComponentsStates.unavailableMessage"></slot>
    <div v-else-if="this.componentState === ComponentsStates.loading"
         :class="[customClass, 'component-state', 'cso-fetching']">
      {{ $t('general.loading') }}
    </div>
    <div v-else-if="this.componentState === ComponentsStates.error"
         :class="[customClass, 'component-state', 'error']">
      <h2>{{$t('statusPage.headers.disabledPage')}}</h2>
      <div v-html="customError ? customError : $t('general.errors.generic')"></div>
      <cso-link :link="StaticUrls.homepage"
                modifier="secondary"
                :label="$t('general.back')"
                :is-button="true">
      </cso-link>
    </div>
    <div v-else-if="this.componentState === ComponentsStates.unavailable"
         :class="[customClass, 'component-state', 'error']">
      <h2>{{$t('statusPage.headers.disabledPage')}}</h2>
      <div v-html="customError ? customError : $t('general.errors.generic')"></div>
      <cso-link :link="StaticUrls.homepage"
                modifier="secondary"
                :label="$t('general.back')"
                :is-button="true">
      </cso-link>
    </div>
    <div v-else-if="this.componentState === ComponentsStates.simpleError"
         :class="[customClass, 'component-state', 'load-error']"></div>
  </div>
</template>
<script>
import ComponentsStates from '@/common/ComponentsStates'
import StaticUrls from '@/common/StaticUrls'

export default {
  name: 'CsoComponentsState',
  props: {
    componentState: {
      type: String,
      required: true
    },
    customError: {
      type: String,
      required: false
    },
    hasOverlay: {
      type: Boolean,
      required: false,
      default: false
    },
    customClass: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      ComponentsStates,
      StaticUrls
    }
  }
}
</script>

<style lang="scss">
// some styles had to go to general.scss
.component-state {
  &.error {
    @include box-shadow-redesign();

    text-align: left;
    background-color: $white;
    padding: 24px 32px;
    color: $black;
    font-family: $font-family-regular;
    font-size: 16px;
    line-height: 24px;

    @include md {
      padding: 24px 20px;
    }

    @include sm {
      margin-right: -10px;
      margin-left: -10px;
    }

    > a {
      margin-top: 20px;
    }

    h2 {
      font-family: $font-family-display;
      font-size: 24px;

      @include md {
        font-size: 22px;
      }
    }
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.no-margin {
    margin: 0;
  }
}
</style>
