import * as Model from '../../core/1.3/model'
import { type Kontaktgrund, type Feld, Anrede, type AnredeProperty, type AnredeCode, type AnredeGender } from './types'

class FelderResponse extends Model.Inhalte.GetFelder.Response { }

/**
 * Fields helper.
 */
export default class Felder extends FelderResponse {
  /**
   * Creates empty fields helper.
   */
  public static createEmpty (): Felder {
    return new Felder({ Prozess: [] })
  }

  /**
   * Instantiates a new fields helper.
   */
  public constructor (felder: FelderResponse) {
    super()
    Object.assign(this, felder)
  }

  /**
   * Salutations.
   */
  public get Anreden (): Anrede[] {
    return this.get<Anrede>('ANREDEN')
  }

  /**
   * Contact reasons.
   */
  public get Kontaktgrunde (): Kontaktgrund[] {
    return this.get<Kontaktgrund>('KONTAKTGRUND')
  }

  /**
   * Contact reasons that are visible, sorted by priority.
   */
  public get KontaktgrundeVisible (): Kontaktgrund[] {
    return this.Kontaktgrunde
      // skip hidden contact reasons
      .filter(kontaktgrund => kontaktgrund.Properties.UNSICHTBAR === '0')
      // sort by priority ascending (lower value = higher priority)
      .sort((a, b) => Number(a.Properties.PRIO) - Number(b.Properties.PRIO))
  }

  /**
   * Returns salutations filtered by property.
   */
  public filterAnreden (property: AnredeProperty): Anrede[] {
    return this.Anreden.filter(anrede => anrede.Properties[property] === '1')
  }

  /**
   * Returns salutation by code or gender.
   */
  public getAnrede (codeOrGender: AnredeCode | AnredeGender): Anrede | undefined {
    const none = 'NOT_POSSIBLE_VALUE'
    return this.Anreden.find(anrede => [
      anrede.Feld,
      anrede.Properties.GESCHLECHT,
      anrede.Properties.GESCHLECHT as any === 'MANN' ? 'HERR' : none
    ].includes(codeOrGender))
  }

  /**
   * Returns contact reason.
   */
  public getKontaktgrund (wert: string): Kontaktgrund | undefined {
    return this.Kontaktgrunde.find(kontaktgrund => kontaktgrund.Wert === wert)
  }

  /**
   * Returns process fields.
   */
  public get<T extends Feld> (prozess: string): T[] {
    const p = this.Prozess.find(p => p.Prozess === prozess)
    return p ? p.Felder as T[] : []
  }
}
