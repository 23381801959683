import { sha256 } from 'js-sha256'

/*
A class to log various events
Install:
- add in particular place in the code:
  LoggingService.log(type, event, additionalData?) or
  LoggingService.error(type, event, additionalData?)
Usage
- to use the service there must be set the following items in the session storage
  * CSOLoggingServiceOn: Enabler of the service. Must be equal to "JA"
  * CSOLoggingServiceWL: Whitelist of the allowed log types. Can be either "all", or one or more types: "App", "App|Store|Token"
  * CSOLoggingServiceBL: Optional blacklist of the disallowed log types. Can be either "all", or one or more types: "App", "App|Store|Token"
- an event will land in the console log if the logger is enabled, the specific type exists in the whitelist (or is set to "all") AND DOES NOT exist in the blacklist (nor is set to "all")
 */
export default class LoggingService {
  static eventTypes = {
    app: 'App',
    navigation: 'Navigation',
    access: 'Access',
    store: 'Store',
    token: 'Token',
    session: 'Session',
    multipleTabs: 'Multiple Tabs',
    cookies: 'Cookies',
    http: 'HTTP',
    di: 'DI',
    userEvent: 'User Event',
    authentication: 'Authentication'
  }

  static _console

  static ai

  static properties

  static shouldLog (type) {
    if (this.isUserTracked()) {
      return true
    }
    return this.isLoggingEnabled() && this.isTypeEnabled(type)
  }

  static isUserTracked () {
    try {
      if (!this.properties.trackEnabled) {
        return false
      }
      const token = JSON.parse(localStorage.getItem('vfdi.token')) || {}
      if (token.role !== 'USER') {
        return false
      }
      const hash = sha256(token.username)
      return this.properties.trackAccounts.includes(hash)
    } catch (error) {
      return false
    }
  }

  static isLoggingEnabled () {
    return window.sessionStorage.getItem('CSOLoggingServiceOn') === 'JA'
  }

  static isTypeEnabled (type) {
    return this.isTypeOnWhiteList(type) && !this.isTypeOnBLackList(type)
  }

  static isTypeOnWhiteList (type) {
    return this.isTypeOnList(type, 'CSOLoggingServiceWL')
  }

  static isTypeOnBLackList (type) {
    return this.isTypeOnList(type, 'CSOLoggingServiceBL')
  }

  static isTypeOnList (type, listType) {
    const list = window.sessionStorage.getItem(listType)
    if (!list) {
      return false
    }
    if (list === 'all' || list === type || list.indexOf(type) !== -1) {
      return true
    }
    return false
  }

  static init (ai, properties) {
    let console = (window.console = window.console || {})
    this._console = {
      log: console.log,
      error: console.error
    }
    this.ai = ai
    this.properties = properties
  }

  static putToLog (type, eventType, event, additionalData) {
    if (this.shouldLog(eventType)) {
      this._console[type]('CSO LoggingService:', eventType + ',', event, additionalData || '')
      this.ai.trackEvent({
        name: `CSO_LOGGING_SERVICE`,
        properties: { type, eventType, event, additionalData }
      })
    }
  }

  static log (eventType, event, additionalData) {
    this.putToLog('log', eventType, event, additionalData)
  }

  static error (eventType, event, additionalData) {
    this.putToLog('error', eventType, event, additionalData)
  }
}
