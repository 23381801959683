import * as Model from '../../core/1.3/model'
import { type MetaTags, type Teaser } from './types'

class InhaltResponse extends Model.Inhalte.GetInhalt.Response { }

/**
 * Content helper.
 */
export default class Inhalt extends InhaltResponse {
  /**
   * Creates empty content helper.
   */
  public static createEmpty () {
    return new Inhalt({ Content: [], Dateien: [], Parameter: [], Texte: [] })
  }

  /**
   * Instantiates a new content helper.
   */
  public constructor (inhalt: InhaltResponse) {
    super()
    Object.assign(this, inhalt)
  }

  /**
   * Returns teaser.
   */
  public getTeaser (name: string) {
    const teaser = this.getContent(name)
    const ueberschrift = this.getContentProperty(name, 'UEBERSCHRIFT')
    const text = this.getContentProperty(name, 'TEXT')
    const linkText = this.getContentProperty(name, 'LINK_TEXT')
    const linkUrl = this.getContentProperty(name, 'LINK')
    const bildId = this.getContentProperty(name, 'BILD_DESKTOP')
    const prio = this.getContentProperty(name, 'PRIO')
    return teaser ? {
      id: teaser.ContentId,
      Prio: prio,
      Name: teaser.Name,
      Ueberschrift: ueberschrift && ueberschrift.Wert,
      Bezeichnung: teaser.Bezeichnung,
      Text: text && text.Wert,
      LinkText: linkText && linkText.Wert,
      LinkUrl: linkUrl && linkUrl.Wert,
      BildIds: bildId && { SystemId: 'APIS', DateiId: bildId.Wert },
      BildUrl: ''
    } as Teaser : undefined
  }

  /**
   * Returns content with given name.
   */
  public getContent (name: string) {
    return this.Content.find(content => content.Name === name)
  }

  /**
   * Returns contents with given category.
   */
  public getContentByCategory (name: string) {
    return this.Content.filter(content => content.Hauptkategorie === name)
  }

  /**
   * Returns contents with given category and subcategory.
   */
  public getContentByCategoryAndSubcategory (kategorie: string) {
    const [hauptkategorie, unterkategorie] = kategorie.split('/')
    return this.Content.find(text =>
      text.Hauptkategorie === (hauptkategorie || null) &&
      text.Unterkategorie === (unterkategorie || null)
    )
  }

  /**
   * Returns content property with given name.
   */
  public getContentProperty (contentName: string, propertyName: string) {
    const content = this.getContent(contentName)
    return content && content.Properties.find(property => property.Name === propertyName)
  }

  /**
   * Returns property from given content.
   */
  public getPropertyFromContent (content: Model.Inhalte.GetInhalt.ResponseContent | undefined, propertyName: string) {
    return content && content.Properties.find(property => property.Name === propertyName)
  }

  /**
   * Returns regex with given name.
   */
  public getRegex (name: string) {
    const value = this.getContentProperty(`REGEX_${name}`, 'REGEX_VALUE')
    const label = this.getContentProperty(`REGEX_${name}`, 'REGEX_ERROR_LABEL')
    let pattern = value && value.Wert
    const message = label && label.Wert
    // Fix for digital interface not returning some regexes in some languages.
    if (!pattern && ['VORNAME', 'NACHNAME'].includes(name)) {
      pattern = '^[À-ǿa-zA-Z .\',-]{1,}$'
    }
    return {
      pattern,
      message
    }
  }

  /**
   * Returns text with given category.
   */
  public getText (kategorie: string) {
    const [hauptkategorie, unterkategorie] = kategorie.split('/')
    return this.Texte.find(text =>
      text.Hauptkategorie === (hauptkategorie || null) &&
      text.Unterkategorie === (unterkategorie || null)
    )
  }

  /**
   * Returns meta tags of given route.
   */
  public getMetaTags (route: string) {
    const seo = this.getContentByCategory('SEO').find(seo => seo.Name === route)
    const title = this.getPropertyFromContent(seo, 'TITLE')
    const keywords = this.getPropertyFromContent(seo, 'KEYWORDS')
    const description = this.getPropertyFromContent(seo, 'DESCRIPTION')
    return seo && {
      title: title?.Wert ?? '',
      description: description?.Wert ?? '',
      keywords: keywords?.Wert ?? ''
    } as MetaTags
  }
}
