<template>
  <button class="button"
          :class="{['button--' + modifier]: modifier, 'button--full-width': fullWidth, 'button--disabled': disabled}"
          :name="name"
          :id="id"
          :type="type"
          :data-cy="cypressName"
          :disabled="disabled">
    <span class="vf-icon"
          :class="{[icon]: !!icon, 'vf-icon-upload': uploadIcon}"
          v-if="icon || uploadIcon">
    </span>
    <span v-html="label"></span>
  </button>
</template>
<script>
export default {
  name: 'CsoButton',
  props: {
    icon: {
      type: String,
      required: false,
      default: ''
    },
    modifier: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    fullWidth: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    uploadIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    cypressName: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss">
.button {
  @include hidden-print();
  display: inline-block;
  font-family: $font-family-regular;
  font-size: 14px;
  line-height: 34px;
  min-height: 34px;
  padding: 0 24px;
  text-align: center;
  cursor: pointer;
  border-width: 3px;
  border-style: solid;
  border-radius: 20px;
  outline: none;
  text-decoration: none;

  &--custom {
    background-color: $white;
    border: 2px solid $blue;
    color: $blue;
  }

  &--custom-light {
    background-color: $white;
    border: 1px solid $blue;
    color: $blue;
    font-size: 12px;
  }

  &--custom-dark {
    background-color: rgba(0,0,0,0);
    border: 1px solid $black;
    color: $black;

    &.button--disabled[disabled] {
      background-color: $gray-f2;
      border: 1px solid $gray-f2;
      color: $gray-dark;
    }

    &:active,
    &.active {
      background-color: $gray-darker;
      border-color: $gray-darker;
      color: $white;
    }

    &:hover {
      background-color: $black;
      border-color: $black;
      color: $white;
    }
  }

  &--primary {
    background-color: $blue;
    border: 2px solid $blue;
    color: $white;

    &:hover {
      background-color: $blue-night;
      border: 2px solid $blue-night;
      color: $white;
    }
  }

  &--secondary {
    background-color: $yellow;
    border: 2px solid $yellow;
    color: $black;
    min-width: 140px;

    &.button--disabled[disabled] {
      background-color: $gray-f2;
      border-color: $gray-f2;
      color: $gray-light;
    }

    &:active {
      background-color: $yellow-dark;
      border-color: $yellow-dark;
    }

    &:hover {
      background-color: $yellow-pale;
      border-color: $yellow-pale;
    }
  }
  &--tertiary {
    background-color: $yellow;
    border: 2px solid $yellow;
    color: $black;
  }

  &--disabled {
    background-color: $gray-light;
    border-color: $gray-light;
    color: $white;

    &:hover {
      cursor: not-allowed;
      background-color: $gray-light;
      border-color: $gray-light;
      color: $white;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--link {
    border: none;
    border-bottom: $white 1px solid;
    background-color: transparent;
    color: $white;
    padding: 4px 25px;
    border-radius: 0;
  }

  &[disabled] {
    background-color: $gray-light;
    border-color: $gray-light;
    color: $gray-lighter;
    cursor: not-allowed;
  }

  &--link-blue {
    border: none;
    background-color: transparent;
    color: $blue;
    padding: 4px 25px;
    border-radius: 0;
    font-size: 16px;
    line-height: 20px;
    min-height: 0;

    &[disabled] {
      background: none;
      border: none;
      color: $gray-light;
      cursor: not-allowed;
    }
  }

  .vf-icon {
    font-size: 20px;
    position: relative;
    top: 5px;
    left: -5px;
  }

  .vf-icon-upload {
    top: 5px;
    position: relative;
    display: inline-block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-image: url('/static/icons/arrow-up.svg');
  }

  &:hover .vf-icon-upload {
    filter: invert(100%);
  }
}
</style>
