import * as Model from '../../core/1.3/model'

class ProzessinformationResponse extends Model.Prozesse.GetProzessinformation.Response { }

/**
 * Process information helper.
 */
export default class Prozessinformation extends ProzessinformationResponse {
  /**
   * Instantiates a new process information helper.
   */
  constructor (prozessinformation: ProzessinformationResponse) {
    super()
    Object.assign(this, prozessinformation)
  }

  /**
 * Creates empty processes.
 */
  public static createEmpty () {
    return new Prozessinformation({ Ergebnisse: [] })
  }
}
