import ErrorResponse from '../error/ErrorResponse'
import Response from '../Response'
import type Strategy from './Strategy'
import { type StrategyType } from './StrategyType'

/**
 * Strategy error.
 *
 * Handles response by throwing a response error.
 */
export default class StrategyError implements Strategy {
  /**
   * @inheritdoc
   */
  public type: StrategyType = 'ERROR'

  /**
   * @inheritdoc
   */
  public async handle (response: Response): Promise<Response> {
    throw new ErrorResponse(response)
  }
}
